import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

function Footer() {
  const [apiData, setApiData] = useState(null);
  const [companyInfo, setCompanyInfo] = useState(null);
  useEffect(() => {
    // fetch(`api/Blog/slug/新商品上市`)
    axios
      .get(`api/Home/FooterNav`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiData(data);
          console.log("Data from FooterNav:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, []);

  useEffect(() => {
    axios
      .get(`api/Home/StoreData`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setCompanyInfo(data);
          console.log("StoreData footer", data);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  }, []);
  const sanitizedHTML = DOMPurify.sanitize(
    companyInfo ? companyInfo.footerInfo : ""
  );
  const sanitizedMobile = DOMPurify.sanitize(
    companyInfo ? companyInfo.footerMInfo : ""
  );


  
  return (
    <>
      <footer className="footer">
        <div className="footer-bg">
          <div className="footer-container">
            <div className="company-info">
              <div className="logo mb-logo">
                {companyInfo && companyInfo.footerLogo && (
                  <img src={companyInfo.footerMLogo} alt="" />
                )}
              </div>
              <div className="logo pc-logo">
                {companyInfo && companyInfo.footerLogo && (
                  <img src={companyInfo.footerLogo} alt="" />
                )}
              </div>

              {companyInfo && (
                <div className="footer-info">
                  <div className="store-name">
                    <h6>{companyInfo.storename}</h6>
                  </div>
                  <div
                    className="mb-info"
                    dangerouslySetInnerHTML={{ __html: sanitizedMobile }}
                  />
                  <div
                    className="pc-info"
                    dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
                  />
                </div>
              )}
            </div>
            <div className="company-link-container ">
              <div className="about">
                {apiData &&
                  apiData.map((item) => (
                    <Link
                      to={`${item.navurl}`}
                      key={item.id}
                      className="footer-link"
                    >
                      <div className="footer-link-icon"></div>
                      <h6>{item.navname}</h6>
                    </Link>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {companyInfo &&
        (companyInfo.socialFb ||
          companyInfo.socialIg ||
          companyInfo.socialLine ||
          companyInfo.socialYt ||
          companyInfo.contactEmail) && (
          <div className="social-icon-container">
            {companyInfo.socialIg && (
              <a href={{ pathname: companyInfo.socialIg }} target="_blank" rel="noopener noreferrer">
                <div className="social-ig footer-icon"></div>
              </a>
            )}
            {companyInfo.socialFb && (
              <a href={{ pathname: companyInfo.socialFb }} target="_blank" rel="noopener noreferrer">
                <div className="social-fb footer-icon"></div>
              </a>
            )}
            {companyInfo.socialLine && (
              <a href={companyInfo.socialLine} target="_blank" rel="noopener noreferrer">
                <div className="social-line footer-icon"></div>
              </a>
            )}
            {companyInfo.socialYt && (
              <a href={{ pathname: companyInfo.socialYt }} target="_blank" rel="noopener noreferrer">
                <div className="social-yt footer-icon"></div>
              </a>
            )}
            {companyInfo.contactEmail && (
              <a href={`mailto:${companyInfo.contactEmail}`}>
                <div className="social-email footer-icon"></div>
              </a>
            )}
          </div>
        )}

        <div className="copyright-container">
          {companyInfo && (
            <div className="text-box">
              © {new Date().getFullYear()} {companyInfo.storename}
              版權所有，並保留所有權利
            </div>
          )}
        </div>
      </footer>
    </>
  );
}

export default Footer;
