import React from "react";
import { Link } from "react-router-dom";
import FilterContent from "./FilterContent";
import Loading from "../components/Loading";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}.${month}.${day}`;
};

const NewsCard = ({
  blogTitle,
  blogDescription,
  blogDate,
  imageUrl,
  blogSlug,
  blogCateSlug,
  buttonContent,
  buttonLink
}) => {
  const formattedDate = blogDate ? formatDate(blogDate) : "Loading...";

  return (
    <Link  to={
      blogCateSlug === ""
        ? `${buttonLink}`
        : `/blogs/${blogCateSlug}/${blogSlug}`
    } className="link">
      <div className="news-card">
        <div className="img-box">
          <img src={`${imageUrl}`} alt="" />
        </div>

        <div className="content">
          {blogDate && <p className="top-date">{formattedDate}</p>}
          {blogTitle && (
            <h6 className="title">
              <FilterContent content={blogTitle} />
            </h6>
          )}
          <p className="descript">
            {blogDescription && <FilterContent content={blogDescription} />}
          </p>

          <button className="primary-btn">
            <h6 className="">{buttonContent || "查看詳細資訊"}</h6>
          </button>
          {blogDate && <p className="date">{formattedDate}</p>}
        </div>
        <div className="news-border"></div>
      </div>
    </Link>
  );
};

export default NewsCard;
