//ProductCard.js
import React, { useState, useContext } from "react";
import AuthContext from "../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import Popup from "./Popup";
import axios from "axios";
function getTagByProdIcon(prodIcon) {
  switch (prodIcon) {
    case 1:
      return (
        // New
        <div className="product-tag product-new"></div>
      );
    case 2:
      // Hot
      return <div className="product-tag product-hot"></div>;
    case 3:
      return (
        // Sale
        <div className="product-tag product-sale"></div>
      );
    default:
      return  null; // 當 prodIcon 不是 1、2、3 時 回傳 null
  }
}


function ProductCard({
  prodId,
  prodImage,
  prodName,
  prodSlug,
  selectedheart,
  prodIcon,
}) {
  const { myAuth, getHeartData, logout } = useContext(AuthContext);
  // console.log("heartData", myAuth.heartData);
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();

  //  console.log("prodIcon",prodIcon);渲染對應的行銷tag

  const parsedProdIcon = parseInt(prodIcon) || 0;
  const tag = getTagByProdIcon(parsedProdIcon);
  const handleHeartClick = (prodId) => {
    if (myAuth.authorized) {
      console.log("AuthContext", "myAuth.sid");
      console.log("prodId", "prodId");

      const mid = parseInt(myAuth.sid);
      const pid = parseInt(prodId);

      const requestData = {
        mid: mid,
        pid: pid,
      };
      const storedToken = myAuth.token;

      if (selectedheart) {
        console.log("已有數據");
        deletefavorite(mid, requestData, storedToken);
      } else {
        // 觸發 addfavorite 函數
        addfavorite(mid, requestData, storedToken);
      }
    } else {
      setIsPopupOpen(true);
    }
  };

  const addfavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/AddFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const deletefavorite = (mid, requestData, storedToken) => {
    axios
      .post(`/api/Member/DeleteFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"notice"}
          title={"登入才可加入收藏"}
          orangebtn={"前往登入"}
          greenbtn={"暫不登入"}
        />
      )}
      <div className="product-card">
        {tag}
        
        <Link to={`/product/${prodSlug}`} >
        <div className="img-box">
        <img src={prodImage} className="w-100" alt={prodName} />
        </div>
          
        </Link>
        <div className="product-title">
          <h6>{prodName}</h6>
          <div className="heart" onClick={() => handleHeartClick(prodId)}>
            <div
              className={`heart-icon ${selectedheart ? "selected" : "default"}`}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(ProductCard);
