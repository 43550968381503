//Cartlist
import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Loading from "../components/Loading";
function Cartlist({
  cartitem,
  content,
  onDelete,
  onDecrease,
  onIncrease,
  DeleteShow,
  onQuantityChange,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [unitPrice, setUnitPrice] = useState(null); // 單價
  const [discountPrice, setDiscountPrice] = useState();

  const [subTotal, setSubTotal] = useState(null);
  const [subProcessingPrice, setSubProcessingPrice] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);

  const [discountsubTotal, setDiscountSubTotal] = useState(null);
  const [discounttotalPrice, setDiscountTotalPrice] = useState(0);

  //數量的更新
  const [newquantity, setNewQuantity] = useState(cartitem.quantity);

  // 引用cartlist的地方
  const [contentType, setContentType] = useState("");

  // const firstStage = cartitem.prodid;

  const unit = cartitem.skuData?.unit || cartitem.unit;
  const unitPieces = cartitem.skuData?.unitPieces || cartitem.unitPieces;
  const processingData = cartitem?.processingData || cartitem.processing;
  const descript = cartitem?.itemdesc || cartitem?.itemDesc;
  const prodImage = cartitem?.prodImg;
  const discountData = cartitem?.discountData || cartitem.discountData || 0;
  const prodSlug = cartitem?.prodSlug || cartitem.prodSlug;

  // console.log("cartitem", cartitem);
  // console.log("prodImage", cartitem.prodImage);
  // console.log("unitPrices", cartitem.skuData);
  // console.log("processingData", cartitem.processingData);

  //數量更新後傳回父元件
  const inputRef = useRef(null);
  const handleNewQuantityChange = (event) => {
    const value = event.target.value;
    // 使用規則運算式檢查輸入是否為數位
    if (/^[0-9]*$/.test(value)) {
      // 只有輸入是數位才更新狀態
      setNewQuantity(value === "" ? "" : parseInt(value, 10));
    }
  };

  const handleBlur = () => {
    // 在输入框失去焦点时触发，将更改发送给父组件
    onQuantityChange(newquantity);
  };

  //根據數量查找適當的金額
  const findUnitPriceByQuantity = (quantity, unitPrices) => {
    if (unitPrices && unitPrices.length > 0) {
      for (let i = unitPrices.length - 1; i >= 0; i--) {
        if (quantity >= unitPrices[i].quantity) {
          return unitPrices[i].unitPrice;
        }
      }
    }

    if (unitPrices) {
      return unitPrices[0].unitPrice; // 預設單價
    }

    return null;
  };
  //根據數量查找適當的折價金額
  const findDiscountPrice = (unitPrice, discountValue) => {
    if (isNaN(unitPrice) || isNaN(discountValue)) {
      // 如果unitPrice或discountValue不是有效数字，则无法继续计算
      return "Invalid Input";
    }

    const discount = parseInt(discountValue);
    const discountedPrice = (1 - discount * 0.01) * unitPrice;

    // 通过toFixed将小数位数限制为两位
    return parseFloat(discountedPrice.toFixed(2));
  };

  //輸入的數字失去焦點後要自動填入數字
  useEffect(() => {
    // 在 input 元素失去焦點時檢查數量
    const checkQuantity = () => {
      if (!inputRef.current || !inputRef.current.matches(":focus")) {
        if (newquantity === "" || parseInt(newquantity) < 1) {
          setNewQuantity(1);
        }
      }
    };

    // 訂閱焦點事件
    if (inputRef.current) {
      inputRef.current.addEventListener("blur", checkQuantity);
    }

    // 在組件卸載時取消訂閱
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("blur", checkQuantity);
      }
    };
  }, [newquantity]);

  useEffect(() => {
    if (cartitem) {
      const unitPrices = cartitem.skuData?.unitPrices || [];
      console.log("cartitem.skuData", "cartitem.skuData");
      const quantity = cartitem.quantity;
      setNewQuantity(quantity);
      //針對訂單的popup特別處理
      const calculatedUnitPrice = cartitem.unitPrice
        ? cartitem.unitPrice
        : findUnitPriceByQuantity(quantity, unitPrices);

      setUnitPrice(calculatedUnitPrice);

      // 檢查 cartitem.quantity 和 unitPrice 是否為 null、undefined 或 0
      let subtotal = null;
      let subProcessingPrice = null;
      if (
        cartitem.quantity == null ||
        calculatedUnitPrice == null ||
        cartitem.quantity === 0 ||
        calculatedUnitPrice === 0
      ) {
        subtotal = "請洽客服人員";
        subProcessingPrice = "請洽客服人員";
      } else {
        subtotal = cartitem.quantity * calculatedUnitPrice;
        if (processingData) {
          // 计算 subProcessingPrice
          subProcessingPrice =
            processingData.reduce((acc, item) => {
              return acc + item.unitPrice;
            }, 0) *
            unitPieces *
            cartitem.quantity;
        }
      }

      setSubTotal(subtotal);
      setSubProcessingPrice(subProcessingPrice);
      setIsLoading(false);
    }
  }, [cartitem]);
  useEffect(() => {
    if (subTotal) {
      const total = subProcessingPrice + subTotal;
      setTotalPrice(total);
    }
  }, [subTotal, subProcessingPrice]);

  //尋找折價金額
  useEffect(() => {
    if (discountData.discountValue) {
      const discountPrice = findDiscountPrice(
        unitPrice,
        discountData.discountValue
      );
      setDiscountPrice(discountPrice);
      console.log("discountPrice", discountPrice);
      // 檢查 cartitem.quantity 和 unitPrice 是否為 null、undefined 或 0
      let discountsubtotal = null;
      let subProcessingPrice = null;
      if (
        cartitem.quantity == null ||
        discountPrice == null ||
        cartitem.quantity === 0 ||
        discountPrice === 0
      ) {
        discountsubtotal = "請洽客服人員";
        subProcessingPrice = "請洽客服人員";
      } else {
        discountsubtotal = cartitem.quantity * discountPrice;
        if (processingData) {
          // 计算 subProcessingPrice
          console.log("processingData", processingData);
          subProcessingPrice =
            processingData.reduce((acc, item) => {
              return acc + item.unitPrice;
            }, 0) *
            unitPieces *
            cartitem.quantity;

          console.log("discountsubtotal", discountsubtotal);
          console.log("subProcessingPrice", subProcessingPrice);
          discountsubtotal =
            cartitem.quantity * discountPrice + subProcessingPrice;
        }
        console.log("discountsubtotal", discountsubtotal);
      }

      setDiscountSubTotal(discountsubtotal);
      setIsLoading(false);
    }
  }, [discountData, unitPrice]);
  //設定折價的總金額
  useEffect(() => {
    if (discountsubTotal) {
      const total = subProcessingPrice + discountsubTotal;
      setDiscountTotalPrice(total);
    }
  }, [discountsubTotal, subProcessingPrice]);
  // 父層傳入的呈現cartlisttype
  useEffect(() => {
    if (content) {
      setContentType(content);
    }
  }, [content]);

  // setRenderOption(unitPrices);
  // //TODO:
  // console.log("processid", cartitem.processid);
  // console.log("prodid", cartitem.prodid);

  // console.log("skuid", cartitem.skuid);
  if (!cartitem) {
    return <Loading />;
  }
  return (
    <>
      {contentType === "page" && (
        <div className=" cart-list-container">
          <div className="cart-data cart-list">
            <div className="product-content">
              <div className="product-img">
                <Link to={`/product/${prodSlug}`} className="">
                  <img src={prodImage} alt="" />
                </Link>
              </div>
              <div className="product-descript-box">
                <Link className="product-link" to={`/product/${prodSlug}`}>
                  <h6 className="product-descript">
                    {descript}
                    {unitPieces && unit && (
                      <span className="product-unit">
                        /{unit}({unitPieces}個)
                      </span>
                    )}
                  </h6>


                </Link>
                <div
                    className={`product-delete cursor-pointer ${
                      DeleteShow ? DeleteShow : ""
                    }`}
                    onClick={(e) => {
                      e.stopPropagation(); 
                      onDelete();
                    }}
                  >
                    <div className="delete-icon icon"></div>
                  </div>
                {processingData && (
                  <div className="processing-data">
                    <h6 className="processing-title ">加購</h6>
                    {processingData.map((item, index) => (
                      <div className="processing-descript" key={index}>
                        <span >
                          {index + 1}.
                        </span>
                        <span >
                          {item.processingName}
                        </span>
                        <span >
                          價格：NT$
                          {item.unitPrice * unitPieces * cartitem.quantity}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className="subtotal">
              <h6 className="product-price ">
                NT${unitPrice && unitPrice.toLocaleString()}
              </h6>
            </div>
            <div className="end-processing">
              {processingData && (
                    <div className="processing-data">
                      <h6 className="processing-title ">加購</h6>
                      {processingData.map((item, index) => (
                        <div className="processing-descript" key={index}>
                          <span >
                            {index + 1}.
                          </span>
                          <h6 className="processing-name">
                            {item.processingName}
                          </h6>
                          <h6 className="processing-price">
                            NT${item.unitPrice * unitPieces * cartitem.quantity}
                          </h6>
                        </div>
                      ))}
                    </div>
                  )}
            </div>
            <div className="product-amount">
              <div className="amount-box counter-box">
                {onDecrease && (
                  <div className="block minus" onClick={onDecrease}>
                    <div className="minus-icon icon"></div>
                  </div>
                )}
                {onDecrease && onIncrease && (
                  <input
                    type="text"
                    value={newquantity}
                    ref={inputRef}
                    className=" block number editable-unit"
                    onChange={handleNewQuantityChange}
                    onBlur={handleBlur}
                  />
                )}

                {!onDecrease && !onIncrease && !cartitem.unit && (
                  <div className="number">
                    <h6>
                      {cartitem.quantity}
                      {unit}
                    </h6>
                  </div>
                )}
                {/* 不可更動的數字顯示 */}
                {!onDecrease && !onIncrease && cartitem.unit && (
                  <div className="uneditable-unit">
                    <h6>
                      {cartitem.quantity}
                      {unit}({unitPieces}個)
                    </h6>
                  </div>
                )}
                {onIncrease && (
                  <div className="block plus" onClick={onIncrease}>
                    <div className="plus-icon icon"></div>
                  </div>
                )}
              </div>
              <div className="subtotal">
                <h6 className="product-price ">
                  NT${(unitPrice && unitPrice * newquantity).toLocaleString()}
                </h6>
              </div>
              <div
                className={`total ${
                  discountsubTotal ? "discount-total" : "total-price"
                }`}
              >
                <h6>NT${totalPrice && totalPrice.toLocaleString()}</h6>
                {discountsubTotal && (
                  <h6 className=" discount-price">
                    NT${discountsubTotal && discountsubTotal.toLocaleString()}
                  </h6>
                )}

                
              </div>
            </div>

            {/* <div className="product cart-list">
              <div className="list-detail main-descript">
                <Link to={`/product/${prodSlug}`} className="product-img">
                  <img src={prodImage} alt="" />
                </Link>
                <Link to={`/product/${prodSlug}`}>
                  <h6 className="p text-wrap ">{descript}</h6>
                </Link>
              </div>

              <div className="list-detail ">
                <Link
                  className=""
                  to={`/product/${prodSlug}`}
                >
                  <h6 className="product-descript">{descript}</h6>
                  <div
                    className={`product-delete cursor-pointer ${
                      DeleteShow ? DeleteShow : ""
                    }`}
                    onClick={onDelete}
                  >
                    <div className="delete-icon icon"></div>
                  </div>
                </Link>
                {discountPrice && (
                  <div className=" d-flex flex-xxl-column ">
                    <p className="disable text-decoration-line-through">
                     NT${unitPrice}
                    </p>
                    <h6 className="info ps-1 ps-xxl-0">${discountPrice}</h6>
                  </div>
                )}
                {!discountPrice && (
                  <div className="d-flex flex-xxl-column ">
                    <h6 className="">${unitPrice}</h6>
                  </div>
                )}
              </div>
              
              <div className="list-detail d-none  d-xl-block">
                {processingData
                  ? processingData.map((item, index) => (
                      <div
                        className="d-flex flex-wrap flex-column-reverse flex-xxl-column"
                        key={index}
                      >
                        <h6 className="d-block p">
                          價格：NT$
                          {item.unitPrice * unitPieces * cartitem.quantity}
                        </h6>

                        <h6 className="d-block  p">
                          {index + 1}.{item.processingName}
                        </h6>
                      </div>
                    ))
                  : ""}
              </div>
              <div className="list-detail d-none d-xl-block">
                {discountsubTotal && (
                  <h6 className="product-price ">
                   NT${discountsubTotal && discountsubTotal.toLocaleString()}
                  </h6>
                )}
                {!discountsubTotal && (
                  <h6 className="product-price ">
                   NT${totalPrice && totalPrice.toLocaleString()}
                  </h6>
                )}
              </div>

              <div
                className={` list-detail d-none d-xl-flex justify-content-center cursor-pointer product-delete ${
                  DeleteShow ? DeleteShow : ""
                }`}
                onClick={onDelete}
              >
                <div className="delete-icon"></div>
              </div>
            </div> */}
          </div>
          <div className="amount-data"></div>
        </div>
      )}
      {/* popup cart */}
      {contentType === null ||
        (contentType === "" && (
          <div className="product cart-list">
            <div className="d-block d-xxl-flex">
              <div className="product-content">
                <Link to={`/product/${prodSlug}`} className="product-img">
                  <img src={prodImage} alt="" />
                </Link>
                <div className="product-descript">
                  {/* <h6 className="product-name">{cartitem.itemdesc}</h6> */}
                  <div className="product-name">
                    <Link to={`/product/${prodSlug}`}>
                      <h6>{descript}</h6>
                    </Link>
                  </div>
                  {discountPrice && (
                    <div className="product-price">
                      <div className=" d-flex flex-xxl-column ">
                        <p className="disable text-decoration-line-through">
                          NT${unitPrice}
                        </p>
                        <h6 className="info ps-1 ps-xxl-0">${discountPrice}</h6>
                      </div>
                    </div>
                  )}
                  {!discountPrice && (
                    <div className="product-price">
                      <div className="d-flex flex-xxl-column ">
                        <h6 className="">${unitPrice}</h6>
                      </div>
                    </div>
                  )}
                  <div className="product-quantity d-block d-md-flex gap-10px d-xxl-block">
                    <div className="d-flex">
                      {onDecrease && (
                        <div className="block minus" onClick={onDecrease}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="4"
                            viewBox="0 0 20 4"
                            fill="none"
                          >
                            <path
                              d="M20 1.99268C20 2.3905 19.9122 2.77203 19.7559 3.05334C19.5996 3.33464 19.3877 3.49268 19.1667 3.49268H0.833333C0.61232 3.49268 0.400358 3.33464 0.244078 3.05334C0.0877975 2.77203 0 2.3905 0 1.99268C0 1.59485 0.0877975 1.21332 0.244078 0.932016C0.400358 0.650711 0.61232 0.492676 0.833333 0.492676H19.1667C19.3877 0.492676 19.5996 0.650711 19.7559 0.932016C19.9122 1.21332 20 1.59485 20 1.99268Z"
                              fill="#364F38"
                            />
                          </svg>
                        </div>
                      )}

                      {onDecrease && onIncrease && (
                        // <div className="block number">
                        //   <h6> {cartitem.quantity}</h6>
                        // </div>
                        <input
                          type="text"
                          value={newquantity}
                          ref={inputRef}
                          className="block number text-center w-50"
                          onChange={handleNewQuantityChange}
                          onBlur={handleBlur}
                        />
                      )}
                      {!onDecrease && !onIncrease && !cartitem.unit && (
                        <div className="number">
                          <h6>
                            {cartitem.quantity}
                            {unit}
                          </h6>
                        </div>
                      )}
                      {!onDecrease && !onIncrease && cartitem.unit && (
                        <>
                          <p className="d-block ps-0 d-xxl-none">
                            {cartitem.quantity}
                            {unit}({unitPieces}個)
                          </p>
                          <h6 className="d-none ps-0 d-xxl-block">
                            {cartitem.quantity}
                            {unit}({unitPieces}個)
                          </h6>
                        </>
                      )}
                      {onIncrease && (
                        <div className="block plus" onClick={onIncrease}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                          >
                            <path
                              d="M18.5714 12.4212H11.4286V19.5641C11.4286 19.943 11.2781 20.3063 11.0102 20.5743C10.7422 20.8422 10.3789 20.9927 10 20.9927C9.62112 20.9927 9.25776 20.8422 8.98985 20.5743C8.72194 20.3063 8.57143 19.943 8.57143 19.5641V12.4212H1.42857C1.04969 12.4212 0.686328 12.2707 0.418419 12.0028C0.15051 11.7349 0 11.3716 0 10.9927C0 10.6138 0.15051 10.2504 0.418419 9.98252C0.686328 9.71461 1.04969 9.56411 1.42857 9.56411H8.57143V2.42125C8.57143 2.04237 8.72194 1.679 8.98985 1.41109C9.25776 1.14319 9.62112 0.992676 10 0.992676C10.3789 0.992676 10.7422 1.14319 11.0102 1.41109C11.2781 1.679 11.4286 2.04237 11.4286 2.42125V9.56411H18.5714C18.9503 9.56411 19.3137 9.71461 19.5816 9.98252C19.8495 10.2504 20 10.6138 20 10.9927C20 11.3716 19.8495 11.7349 19.5816 12.0028C19.3137 12.2707 18.9503 12.4212 18.5714 12.4212Z"
                              fill="#364F38"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                    {onDecrease && onIncrease && (
                      <>
                        <p className="d-block ps-0 d-xxl-none">
                          {unit}({unitPieces}個)
                        </p>
                        <h6 className="d-none ps-0 d-xxl-block">
                          {unit}({unitPieces}個)
                        </h6>
                      </>
                    )}
                  </div>
                  {discountsubTotal ? (
                    <h6 className="product-price d-none d-xxl-block info">
                      NT${discountsubTotal}
                    </h6>
                  ) : (
                    <h6 className="product-price d-none d-xxl-block">
                      NT${subTotal}
                    </h6>
                  )}
                </div>
              </div>
              <div className="d-xxl-flex">
                <div className="product-add d-flex flex-wrap">
                  <h6 className="primary-deep2 d-block d-xxl-none">加購</h6>
                  {processingData
                    ? processingData.map((item, index) => (
                        <div
                          className="d-flex flex-wrap  flex-xxl-column"
                          key={index}
                        >
                          <h6 className="d-block ">
                            {index + 1}.{item.processingName}
                          </h6>

                          <h6 className="d-block">
                            價格：NT$
                            {item.unitPrice * unitPieces * cartitem.quantity}
                          </h6>
                        </div>
                      ))
                    : ""}
                </div>
                <div
                  className={`product-delete ${DeleteShow ? DeleteShow : ""}`}
                  onClick={(e) => {
                    e.stopPropagation(); 
                    onDelete();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="20"
                    viewBox="0 0 18 20"
                    fill="none"
                  >
                    <path
                      d="M17.25 3.07692H13.5V2.30769C13.5 1.69565 13.2629 1.10868 12.841 0.675907C12.419 0.243131 11.8467 0 11.25 0H6.75C6.15326 0 5.58097 0.243131 5.15901 0.675907C4.73705 1.10868 4.5 1.69565 4.5 2.30769V3.07692H0.75C0.551088 3.07692 0.360322 3.15797 0.21967 3.30223C0.0790178 3.44648 0 3.64214 0 3.84615C0 4.05017 0.0790178 4.24582 0.21967 4.39008C0.360322 4.53434 0.551088 4.61538 0.75 4.61538H1.5V18.4615C1.5 18.8696 1.65804 19.2609 1.93934 19.5494C2.22064 19.8379 2.60218 20 3 20H15C15.3978 20 15.7794 19.8379 16.0607 19.5494C16.342 19.2609 16.5 18.8696 16.5 18.4615V4.61538H17.25C17.4489 4.61538 17.6397 4.53434 17.7803 4.39008C17.921 4.24582 18 4.05017 18 3.84615C18 3.64214 17.921 3.44648 17.7803 3.30223C17.6397 3.15797 17.4489 3.07692 17.25 3.07692ZM6 2.30769C6 2.10368 6.07902 1.90802 6.21967 1.76376C6.36032 1.61951 6.55109 1.53846 6.75 1.53846H11.25C11.4489 1.53846 11.6397 1.61951 11.7803 1.76376C11.921 1.90802 12 2.10368 12 2.30769V3.07692H6V2.30769ZM15 18.4615H3V4.61538H15V18.4615ZM7.5 8.46154V14.6154C7.5 14.8194 7.42098 15.0151 7.28033 15.1593C7.13968 15.3036 6.94891 15.3846 6.75 15.3846C6.55109 15.3846 6.36032 15.3036 6.21967 15.1593C6.07902 15.0151 6 14.8194 6 14.6154V8.46154C6 8.25753 6.07902 8.06187 6.21967 7.91761C6.36032 7.77335 6.55109 7.69231 6.75 7.69231C6.94891 7.69231 7.13968 7.77335 7.28033 7.91761C7.42098 8.06187 7.5 8.25753 7.5 8.46154ZM12 8.46154V14.6154C12 14.8194 11.921 15.0151 11.7803 15.1593C11.6397 15.3036 11.4489 15.3846 11.25 15.3846C11.0511 15.3846 10.8603 15.3036 10.7197 15.1593C10.579 15.0151 10.5 14.8194 10.5 14.6154V8.46154C10.5 8.25753 10.579 8.06187 10.7197 7.91761C10.8603 7.77335 11.0511 7.69231 11.25 7.69231C11.4489 7.69231 11.6397 7.77335 11.7803 7.91761C11.921 8.06187 12 8.25753 12 8.46154Z"
                      fill="#DC3545"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="product-subtotal">
              <h6 className="pe-2">合計</h6>
              {discountsubTotal && (
                <div className="d-flex align-items-center">
                  <h6 className="ps-2 info">
                    NT$
                    {discounttotalPrice && discounttotalPrice.toLocaleString()}
                  </h6>
                </div>
              )}
              {!discountsubTotal && (
                <h6>${totalPrice && totalPrice.toLocaleString()}</h6>
              )}
            </div>
          </div>
        ))}
    </>
  );
}

export default Cartlist;
