import React, { useState, useContext } from "react";
import Nodata from "../../sharedComponents/Nodata";
import axios from "axios";



function MemberArt() {
  return (
    <div className="member-art">
      <div className=" member-page-title">
        <h5>我的作品</h5>
        <div className="member-like-count">
          <h6>作品數量：</h6>
          <h6 className="heartamount">0</h6>
          <h6>/ 50（最多50筆）</h6>
        </div>
      </div>
      <div className="member-art-container">
      <Nodata
          descript={"目前沒有收藏資料"}
          link={"../../collections"}
          linkDescript={"去逛逛"}
        />
      </div>
    </div>
  )
}

export default MemberArt