import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";
import Loading from "./Loading";

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}.${month}.${day}`;
};
const filterContent = (content) => {
  if (!content) return "";
  return content.replace(/&nbsp;/g, " ");
};

const NewsDetail = () => {
  // const location = useLocation();
  // const state = location.state;
  const { blogCateSlug, blogSlug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`api/Blog/slug/${blogSlug}`)
      .then((response) => {
        setApiData(response.data);
        sessionStorage.setItem("categoryId", response.data.blogCateSlug);
      })
      .catch((error) => {
        console.error("Error fetching data from API:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [blogSlug]);

  const sanitizedHTML = DOMPurify.sanitize(apiData ? apiData.blog_Content : "");

  const formattedDate = apiData
    ? formatDate(apiData.blog_PublishTime)
    : "Loading...";

  if (!apiData || isLoading) {
    return <Loading />;
  }
  return (
    <div className="news-detail">
      {apiData.blog_MainImg && (
        <div className="top-hero">
          <img src={apiData.blog_MainImg} alt="" />
        </div>
      )}

      <div className="article-content">
        <h4 className="title">{apiData.blog_Title}</h4>
        {formattedDate &&  <p className="top-time time">{formattedDate} (文章時間)</p>}

        {apiData.blog_MainImg && (
          <div className="center-hero">
            <img src={apiData.blog_MainImg} alt="" />
          </div>
        )}

       {formattedDate &&  <p className="center-time time">{formattedDate} (文章時間)</p>}
        <div
          dangerouslySetInnerHTML={{ __html: filterContent(sanitizedHTML) }}
        />
      </div>
    </div>
  );
};

export default NewsDetail;
