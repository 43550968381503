import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResetPwdSection from "../sharedComponents/ResetPwdSection";
import Breadcrumbs from "./Breadcrumbs";

function ForgotPwd({ membertype }) {
  console.log("state", "membertype");

  const location = useLocation();

  const hasState = location.state || membertype;

  const navigate = useNavigate();
  useEffect(() => {
    if (!hasState) {
      navigate("/login");
    }
    console.log("hasState", "location.state");
  }, []);

  return (
    <div className="container forgotpwd">
      <Breadcrumbs />
      <div className=" row px-xxl-0 d-flex justify-content-center">
        <div className="col col-lg-6 col-xxl-5 section-card p-4 d-flex flex-column gap-4">
          <div className="section-title text-center black border-bottom-light pb-2">
            {location.state === "L" && <h5>重設密碼（國內會員）</h5>}
            {location.state === "F" && <h5>重設密碼（國外會員）</h5>}
          </div>
          <div>
            <ResetPwdSection state={location.state} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPwd;
