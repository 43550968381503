import React from "react";
import { Link } from "react-router-dom";

function Nodata({
  icon,
  descript,
  dangerdescript,
  descriptsecond,
  dangerclass,
  link,
  linkDescript,
  memo,
  blockdescript,
  blockmemo,
  bgclass,
}) {
  return (
    <div
      className={`nodata-section ${bgclass ? bgclass : "nodata"}`}
    >
      {!icon || icon === "small-notice-icon" && (
        <div className="small-notice-icon icon"></div>
      )}
      {icon === "check" && (
        <div className="check-icon icon"></div>
      )}
      {icon === "dangernotice" && (
        <div className="danger-notice-icon icon"></div>
      )}
      {icon === "noicon" && <></>}
      {(blockdescript || blockmemo) && (
          <div className="d-flex flex-wrap gap-1">
            {blockdescript && (<p className="primary-deep2 ">{blockdescript}</p>)}
            {blockmemo && (
              <p className="primary-deep2 text-decoration-underline">
                {blockmemo}
              </p>
            )}
          </div>
        )}

      {descript && <p className="primary-deep2 ">{descript}</p>}

      {dangerdescript && (
        <p className={`black-light ${dangerclass ? dangerclass : ""}`}>
          {dangerdescript}
        </p>
      )}
      {descriptsecond && <p className="primary-deep2 ">{descriptsecond}</p>}

      {link && (
        <Link to={link} className="primary-deep2 link">
          {linkDescript}
        </Link>
      )}
      {memo && (
        <p className="primary-deep2 text-decoration-underline">{memo}</p>
      )}
    </div>
  );
}

export default Nodata;
