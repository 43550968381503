import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import ResetPwdSection from "../sharedComponents/ResetPwdSection";
import Loading from "./Loading";
import Breadcrumbs from "./Breadcrumbs";
//登入的狀況下才看的到這隻

function ManualPwd() {
  const [memberType, setMemberType] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  //取得會員資料
  const { myAuth } = useContext(AuthContext);
  console.log("myAuth", "myAuth");
  const navigate = useNavigate();
  //密碼變更
  useEffect(() => {
    setMemberType(myAuth.memberType);
    if (myAuth.mobile) {
      setPhone(myAuth.mobile);
    }
    if (myAuth.email) {
      setEmail(myAuth.email);
    }
  }, [myAuth.memberType, myAuth.mobile, myAuth.email]);
  useEffect(() => {
    console.log("editpwd", "myAuth.editpwd");
    // 要通過不然會轉只
    if (myAuth.editpwd !== true) {
      navigate("/member");
    }
  }, [myAuth.editpwd]);
  return (
    <div className="container manual-pwd">
      <Breadcrumbs />
      <div className=" row px-xxl-0 d-flex justify-content-center">
        <div className="col col-lg-6 col-xxl-5 section-card p-4 d-flex flex-column gap-4">
          <div className="section-title text-center black border-bottom-light pb-2">
            {memberType === "L" && <h5>修改密碼（國內會員）</h5>}
            {memberType === "F" && <h5>修改密碼（國外會員）</h5>}
          </div>

          <ResetPwdSection
            state={memberType}
            MemberPhone={phone}
            MemberEmail={email}
            pass={true}
          />
        </div>
      </div>
    </div>
  );
}

export default ManualPwd;
