//Checkout.js
import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import axios from "axios";
import AuthContext from "../../Context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import CheckoutStep1 from "./CheckoutStep1";
import CheckoutStep2 from "./CheckoutStep2";
import CheckoutStep3 from "./CheckoutStep3";
import CheckoutStep4 from "./CheckoutStep4";
import Loading from "../Loading";
import Popup from "../../sharedComponents/Popup";
import Breadcrumbs from "../Breadcrumbs";

function Checkout() {
  const { myAuth } = useContext(AuthContext);
  const [step, setStep] = useState(2);
  // const [orderConfirmed, setOrderConfirmed] = useState(false);
  const [cartlength, setCartLength] = useState(null); // 设置初始值为 null
  const navigate = useNavigate();
  const [noPage, setNoPage] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [storePickUp, setStorePickUp] = useState("");
  const [orderItems, setOrderItems] = useState(null);
  const location = useLocation;
  console.log("checkout");
  const productId = useSelector((state) => state.product.productId);
  // useEffect(() => {
  //   if (myAuth.authorized) {
  //     setStep(2); // 已登入
  //   } else {
  //     setStep(1); // 未登入
  //   }
  // }, [myAuth.authorized]);
  const getStoreData = () => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setStorePickUp(data.storePickup);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  };

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleOrderData = (data) => {
    setOrderData(data);
  };
  const saveOrderItems = (items) => {
    setOrderItems(items);
  };

  useEffect(() => {
    getStoreData();
  }, []);
  const cartData = localStorage.getItem("cart");
  useEffect(() => {
    if (myAuth.authorized !== null) {
      if (myAuth.authorized === true) {
        if (myAuth.cartamount > 0) {
          console.log("myAuth.cartamount", myAuth.cartamount);
          setCartLength(myAuth.cartamount);
        } else {
          setCartLength(0);
          console.log("myAuth.cartamount", myAuth.cartamount);
        }
      }
      if (myAuth.authorized === false) {
        if (cartData) {
          setStep(2);
          const cartArray = cartData ? JSON.parse(cartData) : [];
          setCartLength(cartArray.length);
          console.log("cartlength", cartlength);
        } else {
          navigate("/checkoutstep1");
        }
      }
    }
  }, [myAuth.authorized, cartData, myAuth.cartData, myAuth.cartamount]);

  if (cartlength === null) {
    return <Loading />;
  }
  if (cartlength === 0) {
    return (
      <div className="container-fluid px-0">
        <Popup
          onNevigate={() => {
            setNoPage(false);
            navigate("/collections");
          }}
          onClose={() => {
            setNoPage(false);
          }}
          icon={"notice"}
          title={"購物車中沒有商品"}
          orangebtn={"回到商品頁"}
          greenbtn={""}
        />
      </div>
    );
  }
  if (myAuth.authorized === false) {
    return (
      <div className="container-fluid px-0">
        <Popup
          onNevigate={() => {
            setNoPage(false);
            navigate("/checkoutstep1");
          }}
          onClose={() => {
            setNoPage(false);
          }}
          icon={"notice"}
          title={"未登入"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      </div>
    );
  }
  return (
    <div className=" page-background checkout-background">
      <div className="share-page checkout-page">
        <Breadcrumbs />
        <div className="checkout-step">
          <Step stepNumber={1} stepName="填寫資料" />
          <Step stepNumber={2} stepName="付款和運送方式" />
          <Step stepNumber={3} stepName="最後確認" />
          <Step stepNumber={4} stepName="購物完成" />
        </div>
        <CheckoutContent />
      </div>
    </div>
  );

  function Step({ stepNumber, stepName }) {
    return (
      <div
        className={`step step${stepNumber} ${
          step === stepNumber ? "selected" : ""
        }`}
      >
        <div className="circle">
          <p>{stepNumber}</p>
        </div>
        <div className="step-descript">
          <p>{stepName}</p>
        </div>
      </div>
    );
  }

  function CheckoutContent() {
    return (
      <div className="container checkout-container px-0 pb-3">
        {step === 2 && (
          <CheckoutStep2
            storePickUp={storePickUp}
            handleNextStep={handleNextStep}
            saveOrderItems={saveOrderItems}
          />
        )}
        {step === 3 && (
          <CheckoutStep3
            storePickUp={storePickUp}
            handlePrevStep={handlePrevStep}
            handleOrderData={handleOrderData}
            orderItems={orderItems}
          />
        )}
        {/* {step === 4 && <CheckoutStep4 orderData={orderData}/>} */}
      </div>
    );
  }
}

export default Checkout;
