import React from 'react'
function ProductTitle({ title }) {
    return (
      <div className="product-title">
        <h4 className='title'>{title}</h4>
      </div>
    );
  }
  

export default ProductTitle