import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Popup from "../../sharedComponents/Popup";
import AuthContext from "../../Context/AuthContext";
import Loading from "../Loading";
function MemberDataInfo() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [hasNameChanged, setHasNameChanged] = useState(false); // 追蹤名稱是否已經修改
  const [hasEmailChanged, setHasEmailChanged] = useState(false);
  const { myAuth, updateMemberData, logout } = useContext(AuthContext);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    console.log("changeEmail");

    // 檢查名稱是否與myAuth.memberEmail不同
    if (newEmail !== myAuth.email) {
      console.log("已經修改姓名請記得儲存");
      setHasEmailChanged(true); // 名稱已經修改
    } else {
      setHasEmailChanged(false); // 名稱還原成原始值
    }
  };
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    console.log("changename");

    // 檢查名稱是否與myAuth.memberName不同
    if (newName !== myAuth.memberName) {
      console.log("已經修改姓名請記得儲存");
      setHasNameChanged(true); // 名稱已經修改
    } else {
      setHasNameChanged(false); // 名稱還原成原始值
    }
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    console.log("check");
    let firstInvalidInputRef = null;

    if (!isValidEmail(email)) {
      firstInvalidInputRef = document.getElementById("email");
    } else if (!isValidName(name)) {
      firstInvalidInputRef = document.getElementById("username");
    }

    if (firstInvalidInputRef) {
      firstInvalidInputRef.focus();
      return;
    }
    const mid = parseInt(myAuth.sid);
    const storedToken = myAuth.token;
    const requestData = {
      mid: mid,
      name: name,
      email: email,
    };
    axios
      .post(`/api/Member/EditMemberData`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          setIsPopupOpen(true);
          updateMemberData();
          // setPointData(response.data);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });

    // setIsPopupOpen(true);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const handleQuickFill = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasNameChanged) {
      setName(myAuth.memberName || "");
    }
    if (!hasEmailChanged) {
      setEmail(myAuth.email || "");
    }
  };
  // const handleQuickFillEmail = () => {
  //   if (!hasEmailChanged) {
  //     setEmail(myAuth.email || "請輸入常用信箱");
  //   }
  // };

  return (
    <div className="member-data-info">
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            setHasEmailChanged(false);
            setHasNameChanged(false);
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"已修改成功"}
          orangebtn={"確認"}
          greenbtn={""}
        />
      )}
      <div className="member-page-title">
        <h5>會員資料</h5>
      </div>
      <form className="member-form" onSubmit={handleFormSubmit}>
        <label htmlFor="username" className="form-label d-flex ">
          <h6 className="primary-deep2">姓名</h6>
          <div className="required-icon"></div>
        </label>
        <input
          type="text"
          id="username"
          name="username"
          className={`form-input ${myAuth.memberName ? "prev-input-data" : ""}`}
          onChange={handleNameChange}
          placeholder={myAuth.memberName || "請輸入姓名"}
          value={name}
          onClick={handleQuickFill} // 點擊時填充姓名
          maxLength={50}
        />
        <div
          className={`d-flex pb-0 ${hasNameChanged ? "d-bock" : "d-none"}`}
          id="error-username"
        >
          <p className="ps-1 secondary-deep d-none d-xxl-block">
            請記得點擊右側的按鈕儲存變更
          </p>
          <p className="ps-1 secondary-deep d-block d-xxl-none">
            請記得點擊下方的按鈕儲存變更
          </p>
        </div>
        <div
          className={`error-message d-flex pb-0 ${
            hasNameChanged && !isValidName(name) ? "d-block " : " d-none"
          }`}
          id="error-username"
        >
          <div className="icon danger-notice-icon"></div>
          <p className="danger">使用者名稱不得為空</p>
        </div>
        <label htmlFor="email" className="form-label d-flex">
          <h6 className="primary-deep2 pt-1">信箱</h6>
          <div className="required-icon"></div>
        </label>
        <input
          type="email"
          id="email"
          name="email"
          className={`form-input ${myAuth.email ? "prev-input-data" : ""}`}
          onChange={handleEmailChange}
          placeholder={myAuth.email || "請輸入常用信箱"}
          value={email}
          onClick={handleQuickFill}
          minLength={5}
          maxLength={100}
        />
        <div
          className={`d-flex pb-0 ${hasEmailChanged ? "d-bock" : "d-none"}`}
          id="error-useremail"
        >
          <p className="ps-1 secondary-deep d-none d-xxl-block">
            請記得點擊右側的按鈕儲存變更
          </p>
          <p className="ps-1 secondary-deep d-block d-xxl-none">
            請記得點擊下方的按鈕儲存變更
          </p>
        </div>
        <div
          className={`error-message d-flex pb-0 ${
            hasEmailChanged && !isValidEmail(email) ? "d-bock" : "d-none"
          }`}
          id="error-email"
        >
          <div className="icon danger-notice-icon"></div>
          <p className=" danger">請輸入正確的信箱</p>
        </div>

        <div className="member-form-btn">
          <button
            className="btn member-save-btn"
            disabled={
              !isValidEmail(email) ||
              !isValidName(name) ||
              (!hasNameChanged && !hasEmailChanged)
            }
          >
            <h6 className="py-1">儲存變更</h6>
          </button>
        </div>
      </form>
    </div>
  );
}

export default MemberDataInfo;
