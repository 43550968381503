import React, { useState, useContext, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import RegisterLocalSection from "../sharedComponents/RegisterLocalSection";
import RegisterForeignSection from "../sharedComponents/RegisterForeignSection";
import Breadcrumbs from "./Breadcrumbs";
import Loading from "./Loading";

function Register() {
  const [loginImg, setLoginImg] = useState("");
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const location = useLocation();
  console.log("location", "location");
  //圖片
  useEffect(() => {
    axios
      .get("/api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .then((data) => {
        if (data) {
          setLoginImg(data.loginImage);
          console.log("contactImg", "data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  }, []);

  // 根据路由路径决定按钮和登录区域
  let registerButton = null;
  let registerSection = null;

  if (location.pathname === "/foreignregister") {
    registerButton = (
      <>
        <Link to="/register" className="btn news-cate-btn">
          國內會員註冊
        </Link>
        <div className="btn news-cate-btn selected">國外會員註冊</div>
      </>
    );
    registerSection = <RegisterForeignSection />;
  } else if (location.pathname === "/register") {
    registerButton = (
      <>
        {/* <div className="btn news-cate-btn selected">國內會員註冊</div>
        <Link to="/foreignregister" className="btn news-cate-btn ">
          國外會員註冊
        </Link> */}
      </>
    );
    registerSection = <RegisterLocalSection title={"註冊"}/>;
  }
  return (
    <div className="page-background register-background">
      <div className="top-img-container">
        <div className="share-page">
          <div className="login-img">
            <img src={loginImg} className="w-100" alt="" />
          </div>
        </div>
      </div>
      <div className="share-page register-page">
        <Breadcrumbs />
        <div className="register-detail">
          <div className="center-img-container">
            <div className="login-img">
              <img src={loginImg} className="w-100" alt="" />
            </div>
          </div>
          <div className="register-content register-container">
            {/* <div className="container px-0">
              <div className="news-cate pt-0"> {registerButton}</div>
            </div> */}
            {registerSection}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
