//Popup.js
import React, { useEffect } from "react";

function Popup({ onNevigate, onClose, closePop, option, icon, title, message, orangebtn, greenbtn }) {
  let iconElement = null;
  // useEffect(()=>{
  //   console.log("option",option)
  // },[])
  // useEffect(()=>{
  //   console.log("icon",icon)
  // },[icon])

  if (icon === "notice") {
    iconElement = (
      <div className="notice-icon"></div>
    );
  } else if (icon === "check") {
    iconElement = (
      <div className="icon success-icon"></div>
    );
  }
  return (
    <div className="popup-full-bg" onClick={closePop}>
      <div className="popup-checkbox">
        <div className="popup-content">
          <div className="popup-icon text-center">{iconElement}</div>
          <div className="popup-title text-center mx-3 mx-xxl-0">
            <h6>{title}</h6>
            
          </div>
          {message}
        </div>
        <div className={`container d-flex justify-content-center justify-content-center ${orangebtn !== "" && greenbtn !== "" ? 'btn-group' : ''}`}>
          {orangebtn !== "" && (
            <button className="btn search-btn2  " onClick={onNevigate}>
              <p>{orangebtn}</p>
            </button>
          )}
          {greenbtn !== "" && (
            <button className="btn primary-btn  " onClick={onClose}>
              <p>{greenbtn}</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Popup;
