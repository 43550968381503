import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import heroImage from "../image/newshero.JPG";
import NewsCard from "../sharedComponents/NewsCard";
import NewsDetail from "./NewsDetail";
import Pagination from "../sharedComponents/Pagination";
import Nodata from "../sharedComponents/Nodata";
import axios from "axios";
import Breadcrumbs from "./Breadcrumbs";
import Loading from "./Loading";

const NewsCate = () => {
  const [selectedCate, setSelectedCate] = useState("全部");
  const [currentPage, setCurrentPage] = useState(1);
  const [cateData, setCateData] = useState(null);
  const [filteredBlogData, setFilteredBlogData] = useState(null);
  const [isCategoryValid, setIsCategoryValid] = useState(true); // New state for category validity

  const [isLoading, setIsLoading] = useState(true);

  const [blogImg, setBlogImg] = useState("");
  const { blogCateSlug, blogSlug } = useParams();
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get current location


  const getBlogHeroImg = () => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setBlogImg(data.blogImage);
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  };
  const getFilterData = () => {
    setIsLoading(true);
    axios
      .get("api/Blog/cate/slug/")
      .then((response) => {
        const data = response.data;
        const filteredData =
          selectedCate === "全部"
            ? data
            : data.filter(
                (blogData) => blogData.blogCate_Slug === selectedCate
              );
        setFilteredBlogData(filteredData);
      })
      .catch((error) => {
        // console.error("Error fetching data from API:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleCategoryClick = (cateId) => {
    sessionStorage.setItem("categoryId", cateId);
    setSelectedCate(cateId);

    setCurrentPage(1);
    const newUrl = `/blogs/${cateId}`;
    // window.history.replaceState(null, "", newUrl);
    // if (blogSlug) {
    //   navigate(newUrl);
    // }
    navigate(newUrl, { replace: true }); 
    // navigate(newUrl);
  };

  // 先取得部落格分類資料
  useEffect(() => {
    getBlogHeroImg();
    axios
      .get("api/Blog/cate/")
      .then((response) => {
        setCateData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data from API:");
      });
  }, []);

  // 判斷網址是否有對應的分類
  useEffect(() => {
    if (!cateData) return;
    console.log("blogSlug", blogSlug);
    console.log("blogCateSlug", blogCateSlug);
    if (location.pathname === "/blogs") {
      setSelectedCate("全部");
      console.log("setSelectedCate, 全部");
    } else if (location.pathname.includes("blogs")) {
      const categoryExists = cateData.some(
        (item) => item.slug === blogCateSlug
      );
      // console.log("setSelectedCate", blogCateSlug);

      if (categoryExists) {
        setIsCategoryValid(categoryExists);
        setSelectedCate(blogCateSlug)
        // console.log("categoryExists");
      } else {
        setIsCategoryValid(false);
        navigate("/blogs");
        // console.log("navigate(blogs)");
      }
    }
  }, [cateData, location.pathname]);

  // useEffect(() => {
  //   const storedCategoryId = sessionStorage.getItem("categoryId");
  //   if (storedCategoryId) {
  //     setSelectedCate(storedCategoryId);
  //   } else if (!blogCateSlug) {
  //     setSelectedCate("全部");
  //   } else {
  //     setSelectedCate(blogCateSlug);
  //   }
  // }, [blogCateSlug]);

  useEffect(() => {
    getFilterData();
  }, [selectedCate]);

  const itemsPerPage = 12;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentBlogData =
    filteredBlogData && filteredBlogData.slice(startIndex, endIndex);

  const maxPage =
    filteredBlogData && Math.ceil(filteredBlogData.length / itemsPerPage);
  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled =
    currentPage === maxPage ||
    !currentBlogData ||
    currentBlogData.length < itemsPerPage;
  // if (isLoading) {
  //   return <Loading />;
  // }
  return (
    <div
      className={`page-background  ${
        blogSlug ? "news-detail-page-background" : "news-category-background"
      }`}
    >
      <div className="top-hero">
        {blogImg ? <img src={blogImg} alt="" /> : <Loading />}
      </div>
      <div
        className={`share-page ${
          blogSlug ? "news-category news-detail-page" : "news-category"
        }`}
      >
        <div className="top-bread">
          <Breadcrumbs />
        </div>

        <div className="top-category-hero">
          {blogImg ? <img src={blogImg} alt="" /> : <Loading />}
          <h3 className="title">{selectedCate}</h3>
        </div>
        <div className="news-category-page">
          <div className="center-hero">
            {blogImg ? <img src={blogImg} alt="" /> : <Loading />}
          </div>
          <div className="center-bread">
            <Breadcrumbs />
          </div>
          {cateData ? (
            <div className="news-category-filter">
              <div className="news-filter-box">
                <div className="news-filter">
                  <h3 className="title">分類</h3>
                  <button
                    className={`btn news-cate-btn ${
                      selectedCate === "全部" ? "selected" : ""
                    }`}
                    onClick={() => handleCategoryClick("全部")}
                  >
                    <h6>全部</h6>
                  </button>
                  {cateData.map((item) => (
                    <button
                      key={item.slug}
                      className={`btn news-cate-btn ${
                        selectedCate.trim() === item.slug.trim()
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleCategoryClick(item.slug)}
                    >
                      <h6>{item.catename}</h6>
                    </button>
                  ))}
                </div>
              </div>
              {!blogSlug && (
                <div className="news-category-filtercontent">
                  <div className="news-cate-content">
                    {filteredBlogData && (
                      <div className="news-content">
                        <div className="news-row">
                          {filteredBlogData.length > 0 &&
                            currentBlogData.map((blogData, index) => (
                              <NewsCard
                                key={index}
                                blogTitle={blogData.blog_Title}
                                blogDescription={blogData.blog_Content}
                                blogDate={blogData.blog_PublishTime}
                                imageUrl={blogData.blog_MainImg}
                                blogSlug={blogData.blog_Slug}
                                blogCateSlug={blogData.blogCate_Slug}
                              />
                            ))}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="news-page-content">
                    <div className="pagination justify-content-center primary-deep">
                      <Pagination
                        currentPage={currentPage}
                        totalPages={maxPage}
                        onPageChange={setCurrentPage}
                      />
                    </div>
                  </div>
                </div>
              )}
              {blogSlug && <NewsDetail />}
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsCate;
