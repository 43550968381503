//InvoiceSelect
import React, { useState, useEffect, useContext } from "react";

import AuthContext from "../Context/AuthContext";
function InvoiceSelect({
  onInvoiceChange,
  isInvoiceValid,
  noChangeOk,
  errornone,
  initInvoiceType,
}) {
  // const [invoiceOption, setInvoiceOption] = useState(null);

  const [selectedInvoiceOption, setSelectedInvoiceOption] = useState("");

  //三聯式的資料
  const [businessNumber, setBusinessNumber] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [bussinessBlur, setBussinessBlur] = useState(false);
  const [invoiceTitleBlur, setinvoiceTitleBlur] = useState(false);
  //是否有改變發票資料
  const [hasTypeChange, setHasTypeChange] = useState(false);
  const [hasTitleChange, setHasTitleChange] = useState(false);
  const [hasNumberChange, setHasNumberChange] = useState(false);

  const { myAuth } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;

  const invoiceoptions = [
    { label: "二聯式發票", value: "2" },
    { label: "三聯式發票", value: "3" },
  ];

  //初始值
  useEffect(()=>{
isInvoiceValid(false)
if(initInvoiceType){
  setSelectedInvoiceOption(initInvoiceType)
}
  },[])
  // useEffect(() => {
  //   console.log("myAuth", "myAuth");
  //   setSelectedInvoiceOption(myAuth.invoiceType);
  //   if (myAuth.invoiceType === "3") {
  //     setInvoiceTitle(myAuth.invoiceTitle);
  //     setBusinessNumber(myAuth.businessNo);
  //   }
  // }, []);

  // 若選擇了3聯式
  useEffect(() => {
    // console.log("myAuth.invoiceType", myAuth.invoiceType);
    if (myAuth && selectedInvoiceOption) {
      const selectedInvoiceType = selectedInvoiceOption.toString();
      const authInvoiceType = myAuth.invoiceType ? myAuth.invoiceType.toString() : "";
  
      if (selectedInvoiceType === authInvoiceType && authInvoiceType === "3") {
        setInvoiceTitle(myAuth.invoiceTitle);
        setBusinessNumber(myAuth.businessNo);
      }
    }
   
  }, [selectedInvoiceOption]);
  //是否修改type
  const handleTypeChange = (e) => {
    const inputValue = e.target.value;
    setSelectedInvoiceOption(inputValue);
    if (inputValue !== myAuth.invoiceType) {
      setHasTypeChange(true);
      console.log("已修改", " e.target.value");
    } else {
      setHasTypeChange(false);
    }
  };

  //是否修改發票抬頭
  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(
      /[\s~`!@#$%^&*()_+=[{\]};:'"<>,./?\\|]/g,
      ""
    );
    setInvoiceTitle(sanitizedValue);
    if (sanitizedValue !== myAuth.invoiceTitle) {
      setHasTitleChange(true);
      console.log("已修改");
    } else {
      setHasTitleChange(false);
    }
  };

  //是否修改統一編號
  const handleBusinessNumberChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/\D/g, ""); // \D 表示非数字字符
    setBusinessNumber(sanitizedValue);
    console.log("hasNumberChange");
    //TODO:檢查統一編號是否與會員資料不同
    if (sanitizedValue !== myAuth.businessNo) {
      setHasNumberChange(true);
      console.log("已修改");
    } else {
      setHasNumberChange(false);
    }
  };
  //編輯狀態
  const handleQuickFill = () => {
    if (!hasTitleChange) {
      setInvoiceTitle(myAuth.invoiceTitle || "");
    }
    if (!hasNumberChange) {
      setBusinessNumber(myAuth.businessNo || "");
    }
  };

  //格式檢查
  const isValidNumber = (businessNumber) => {
    const isValid = /^\d{8}$/.test(businessNumber);
    return isValid;
  };
  const isValidInvoiceTitle = (invoiceTitle) => {
    return invoiceTitle !== null && invoiceTitle !== "";
  };
  //回傳給父層格式檢查
  useEffect(() => {
    console.log("selectedInvoiceOption", "selectedInvoiceOption");
    console.log("hasTypeChange", "hasTypeChange");
    console.log("businessNumber", "businessNumber");
    console.log("invoiceTitle", "invoiceTitle");
    console.log("noChangeOk", "noChangeOk");
    if (selectedInvoiceOption === "2" && (hasTypeChange || noChangeOk)) {
      isInvoiceValid(true);
      console.log("hasTypeChange", "hasTypeChange");
    }
    if (
      selectedInvoiceOption === "3" &&
      (hasTitleChange || hasNumberChange || noChangeOk)
    ) {
      if (isValidNumber(businessNumber) && isValidInvoiceTitle(invoiceTitle)) {
        isInvoiceValid(true);
      } else {
        isInvoiceValid(false);
      }
    }
  }, [
    selectedInvoiceOption,
    businessNumber,
    invoiceTitle,
    hasTypeChange,
    hasTitleChange,
    hasNumberChange,
    noChangeOk,
  ]);

  //當選擇的不是原本的才回傳統一編號、invoicetitle等等
  useEffect(() => {
    onInvoiceChange(selectedInvoiceOption, businessNumber, invoiceTitle);
     console.log("onInvoiceChange")
    //  console.log("selectedInvoiceOption", selectedInvoiceOption)
    //  console.log("businessNumber", businessNumber)
    //  console.log("invoiceTitle", invoiceTitle)
  }, [selectedInvoiceOption, businessNumber, invoiceTitle]);

  // useEffect(() => {
  //   console.log("selected invoice", "selectedInvoiceOption");
  // }, [selectedInvoiceOption]);

  //重複的表單

  const renderInputField = (
    id,
    name,
    placeholder,
    prevalue,
    value,
    onChange,
    onClick = null,
    onBlur = null,
    minLength,
    maxLength
  ) => (
    <input
      type="text"
      id={id}
      name={name}
      className={`form-input ${prevalue ? "prev-input-data" : ""} `}
      placeholder={placeholder || ""}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasChanged, isValid, id, errormessage) =>
    errornone === true ? (
      <div
        className={`error-message ${
          hasChanged && !isValid ? "d-block" : "d-none"
        } d-flex pb-0`}
        id={id}
      >
        <div className="icon danger-notice-icon"></div>
        <p className={` danger`}>{errormessage}</p>
      </div>
    ) : (
      <div
        className={`error-message ${
          hasChanged && !isValid ? "visible" : "invisible"
        } d-flex pb-0`}
        id={id}
      >
        <div className="icon danger-notice-icon"></div>
        <p className={` danger`}>{errormessage}</p>
      </div>
    );

  return (
    <>
      <div className="content-section">
        <label htmlFor="invoiveType" className="form-label d-block">
          <h6 className="primary-deep2">發票格式</h6>
        </label>
        <select
          id="invoiveType"
          className="form-select"
          value={selectedInvoiceOption}
          onChange={handleTypeChange}
        >
          <option className="form-select-option" value="" disabled>
            --選擇發票格式--
          </option>
          {invoiceoptions.map((item, index) => (
            <option
              key={index}
              className="form-select-option"
              value={item.value}
            >
              {item.label}
            </option>
          ))}
        </select>
      </div>
      {selectedInvoiceOption === "3" ? (
        <>
          <label
            htmlFor="businessnumber"
            className={`form-label`}
          >
            <h6 className="primary-deep2">統一編號</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "businessnumber",
            "businessnumber",
            myAuth.businessNo || "請輸入統一編號",
            myAuth.businessNo,
            businessNumber,
            handleBusinessNumberChange,

            () => {
              handleQuickFill();
              setBussinessBlur(false);
            },
            () => {
              setBussinessBlur(true);
            },
            8,
            8
          )}

          {errorInput(
            (hasNumberChange || !businessNumber) && bussinessBlur,
            isValidNumber(businessNumber),
            "error-businessnumber",
            "請輸入正確的格式(例如：01234567)"
          )}

          <label
            htmlFor="invoicetitle"
            className={`form-label`}
          >
            <h6 className="primary-deep2">發票抬頭</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "invoicetitle",
            "invoicetitle",
            myAuth.invoiceTitle || "請輸入統一編號",
            myAuth.invoiceTitle,
            invoiceTitle,
            handleTitleChange,

            () => {
              handleQuickFill();
              setinvoiceTitleBlur(false);
            },
            () => {
              setinvoiceTitleBlur(true);
            },
            1,
            100
          )}

          {errorInput(
            (hasTitleChange || !invoiceTitle) && invoiceTitleBlur,
            isValidInvoiceTitle(invoiceTitle),
            "error-invoicetitle",
            "發票抬頭不得為空"
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default InvoiceSelect;
