import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Controller,
  Autoplay 
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Loading from "./Loading";

const HeroSlider = ({ img }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const [mainSwiper, setMainSwiper] = useState(null);
  // console.log("activeIndex",images)
  //第一張及最後一張禁用
  const isPrevDisabled = !img || img.length === 0 || activeIndex === 0;
  const isNextDisabled = !img || img.length === 0 || activeIndex === img.length - 1;


  const handleLinkClick = (tag) => {
    // 清空 sessionStorage 中的值
    if (tag) {
      sessionStorage.removeItem("collectiontag");

      // 設置新的值
      const updatedTags = tag || ""; // 如果 collections 不存在，設置為空字符串
      sessionStorage.setItem("collectiontag", updatedTags);
    }
  };
  if (!img || img.length === 0) {
    return <Loading />;
  }
  return (
    <>
      <div className="hero-section">
        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Zoom, Controller, Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          // loop={true}
          navigation={{
            prevEl: ".prev",
            nextEl: ".next",
          }}
          pagination={{ clickable: true, el: ".swiper-pagination" }}
          autoplay={{ delay: 5000 }} 
          onSwiper={(swiper) => setMainSwiper(swiper)}
          zoom={true}
          // onSlideChange={(swiper) => console.log(swiper.activeIndex)}
          onActiveIndexChange={(swiper) => {
            setActiveIndex(swiper.activeIndex);
            // console.log("swiper.activeIndex", swiper.activeIndex);
          }}
        >
          {img.map((image, index) => (
            <div key={index}>
              <SwiperSlide key={`slide-${index}`}>
                {/* {index} */}
                <Link
                  to={image.linkUrl}
                  onClick={() => handleLinkClick(image.collections)}
                >
                  <figure className="cursor-pointer ">
                    <img src={image.mainImage} alt={`Slide ${index + 1}`} className="pc-image"/>
                    <img src={image.mainMImage} alt={`Slide ${index + 1}`} className="phone-image"/>
                  </figure>
                </Link>
              </SwiperSlide>
            </div>
          ))}

          <div className="hero-section-control">
            <div
              className={`control-left ${
                isPrevDisabled ? "disabled" : ""
              }`}
              onClick={() => {
                mainSwiper.slidePrev();
              }}
            >
             
            </div>
            <div className="img-number">
              {img.map((_, index) => (
                <div
                  key={index}
                  className="  d-flex align-items-center"
                  onClick={() => mainSwiper.slideTo(index)}
                >
                  <div
                    className={`point ${activeIndex === index ? "select" : ""}`}
                  ></div>
                </div>
              ))}
            </div>
            <div
              className={`control-right ${
                isNextDisabled ? "disabled" : ""
              }`}
              onClick={() => {
                mainSwiper.slideNext();
              }}
            >
              
            </div>
          </div>
        </Swiper>
      </div>
      
    </>
  );
};

export default HeroSlider;
