import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../Loading";

function OnePageImg({ pcImg, cpImg, bannerImg }) {

  if (!bannerImg) {
    return <Loading />;
  }
  return (
    <>
      <div className="onepage-banner-container ">
        {/* {pcImg ? (<img src={pcImg} className="d-none d-xxl-block onepage-banner " alt="" />) : (<Loading />)}
     {cpImg ? (<img src={cpImg} className="d-block d-xxl-none onepage-banner " alt="" />) : (<Loading />)} */}
        {bannerImg && (
          <img src={bannerImg} className=" onepage-banner " alt="" />
        )}
      </div>
    </>
  );
}

export default OnePageImg;
