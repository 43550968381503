//RegisterLocalSection.js
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import PwdLink from "./PwdLink";
import Loading from "../components/Loading";
import Nodata from "./Nodata";
import TextPopup from "./TextPopup";
import CheckOutNa from "./CheckOutNa";

function RegisterLocalSection({ title, buttondescript }) {
  const { login } = useContext(AuthContext);
  const location = useLocation();

  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);

  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);

  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setphoneBlur] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [captchaData, setCaptchaData] = useState(null);
  const [isSameCaptcha, setIsSameCaptcha] = useState(true);
  //寄送SendVerificationCode出現的錯誤訊息
  const [captchaError, setCaptchaError] = useState(null);
  //查看密碼的password
  const [showPassword, setShowPassword] = useState(false);

  const [formCheck, setFormCheck] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  //手機是否為唯一值
  const [phoneuniq, setPhoneuniq] = useState(false);
  //是否發送過檢查手機號碼
  const [phonesent, setPhonesent] = useState(false);

  const [isRegisterPopupOpen, setIsRegisterPopupOpen] = useState(false);

  //已經寄送過驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);

  // 檢查錯誤
  const [sendCaptchaError, setSendCaptchaError] = useState(false);
  const [checkError, setCheckError] = useState(false);

  const [clickSubmit, setClickSubmit] = useState(false);

  const navigate = useNavigate();

  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    setIsSubmitted(false);
    setPhonesent(false);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };
  //檢查手機是否為 唯一值
  const sendPhoneNumber = () => {
    const requestData = { Mobile: phone, MemberType: "L" };
    axios
      .post("api/User/Register/Check", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setPhoneuniq(true);
        setPhonesent(true);
        handleCaptchaSend();
        console.log("Form Register Check submitted:", "response.data");
      })
      .catch((error) => {
        console.log("Error checking availability");
        if (error) {
          console.log("Mobile number or Email is already registered.");
          setPhoneuniq(false);
          setPhonesent(true);
          console.log(phonesent, !phoneuniq, isSubmitted);
          window.scrollTo(0, 0);
        }
      });
  };

  // 檢查格式，若格式正確就檢查手機是否為唯一值，
  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    //如果狀態是verifysent(代表已經寄過)就不寄信
    if (verifysent) {
      return;
    }

    setIsSubmitted(true);

    if (
      !isValidPhoneNumber(phone) ||
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError
    ) {
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phone) || !phone) {
        firstInvalidInputRef = document.getElementById("phone-number");
        console.log("firstInvalidInputRef");
      } else if (!isValidEmail(email) || !email) {
        firstInvalidInputRef = document.getElementById("email");
      } else if (!isValidName(name) || !name) {
        firstInvalidInputRef = document.getElementById("username");
      } else if (!isValidPassword(password) || !password) {
        firstInvalidInputRef = document.getElementById("register-pwd");
      } else if (confirmPasswordError || !confirmPassword) {
        firstInvalidInputRef = document.getElementById("confirm-pwd");
      } else if (!isSameCaptcha || !captcha) {
        firstInvalidInputRef = document.getElementById("captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef", firstInvalidInputRef);
        firstInvalidInputRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setSendCaptchaError(true);
      return;
    } else {
      setSendCaptchaError(false);
    }

    console.log("格式合法:", "phone, password, email, name");

    sendPhoneNumber();
  };
  //如果手機沒有被註冊過才發送驗證碼
  const handleCaptchaSend = () => {
    const requestData = { mobile: phone.toString() };
    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    setNextDisabled(true);
    axios
      .post("/api/User/SendVerificationCode", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SendVerificationCode", "responseData");
        console.log("傳過去的資料SendVerificationCode", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        console.error(
          "寄送SendVerificationCode出現的錯誤訊息",
          "error.response.data.message"
        );
        setCaptchaError(error.response.data.message);
      })
      .finally(() => {
        setNextDisabled(false);
      });
  };

  const handleFormSubmit = async (e) => {
    //如果phonesent為false就不驗證。
    //如果手機號碼不是唯一值就不寄信
    e.preventDefault();
    setClickSubmit(true);
    setphoneBlur(true);
    setEmailonBlur(true);
    setNameBlur(true);
    setPasswordBlur(true);
    setConfirmPasswordBlur(true);
    setCaptchaBlur(true);
    if (
      !isValidPhoneNumber(phone) ||
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidPassword(password) ||
      confirmPasswordError
    ) {
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phone) || !phone || !phoneuniq) {
        firstInvalidInputRef = document.getElementById("phone-number");
        console.log("firstInvalidInputRef");
      } else if (!isValidEmail(email) || !email) {
        firstInvalidInputRef = document.getElementById("email");
      } else if (!isValidName(name) || !name) {
        firstInvalidInputRef = document.getElementById("username");
      } else if (!isValidPassword(password) || !password) {
        firstInvalidInputRef = document.getElementById("register-pwd");
      } else if (confirmPasswordError || !confirmPassword) {
        firstInvalidInputRef = document.getElementById("confirm-pwd");
      } else if (!isSameCaptcha || !captcha) {
        firstInvalidInputRef = document.getElementById("captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef", firstInvalidInputRef);
        firstInvalidInputRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setCheckError(true);
      return;
    } else {
      setCheckError(false);
    }

    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    } else {
      setNextDisabled(true);
    }

    //驗證碼是否一樣?
    if (captcha !== captchaData.toString()) {
      console.log("captcha", "captcha");
      console.log("captchaData", "captchaData");
      setIsSameCaptcha(false);
      return;
    }

    setIsSubmitted(true);
    console.log("check");
    if (phoneuniq) {
      try {
        const registerResponse = await axios.post("api/User/Register", {
          Mobile: escapeHTML(phone),
          Email: escapeHTML(email),
          Password: escapeHTML(password),
          MemberName: escapeHTML(name),
          MemberType: "L",
        });
        if (location.pathname !== "/checkoutstep1") {
          setIsRegisterPopupOpen(true);
        }
        if (location.pathname === "/checkoutstep1") {
          try {
            const loginResult = await login(phone, password, "L"); // 等待登录完成

            if (!loginResult) {
              // 登录失败，打开弹出窗口
              console.log("出現意外錯誤");
            }
          } catch (error) {
            console.error("Error during login:", "error");
            // 处理登录时的错误情况
          }
          console.log("登入完成");
        }
        // Handle registration response (registerResponse.data)
        console.log("Form submitted:", "registerResponse.data");
      } catch (registerError) {
        // Handle registration error
        console.error("Error submitting registration:", "registerError");
      } finally {
        setNextDisabled(false);
        window.scrollTo(0, 0);
      }
      console.log("phoneuniq", phoneuniq);
    }
  };

  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  const isValidName = (name) => {
    if (typeof name === "string" || name instanceof String) {
      const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      // 确保字符串不为空
      return name.trim() !== "" && nameRegex.test(name);
    }
    return false; // 如果 name 不是字符串，返回 false
  };
  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  const isValidCaptcha = (captcha) => {
    return captcha === captchaData;
  };
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const handleQuickFill = () => {
    // const quickFillData = {
    //   phone: "1234567890",
    //   email: "aa1@gmail.com",
    //   password: "Password123",
    //   name: "Nick",
    //   confirmPassword: "Password123",
    //   captcha: "123456789",
    // };

    // setPhone(quickFillData.phone);
    // setEmail(quickFillData.email);
    // setPassword(quickFillData.password);
    // setName(quickFillData.name);
    // setConfirmPassword(quickFillData.confirmPassword);
    // setConfirmPasswordError(false); // Reset the confirmPassword error
    // setCaptcha(quickFillData.captcha);
    console.log("click", "handleQuickFill");
  };
  //檢驗再次輸入密碼和輸入密碼是否相同
  useEffect(() => {
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      setConfirmPasswordError(false);
      console.log("密碼沒錯");
    } else {
      setConfirmPasswordError(true);
      console.log("密碼不相同");
    }
  }, [password, confirmPassword]);

  useEffect(() => {
    if (!phoneuniq) {
      window.scrollTo(0, 0);
    }
  }, [phoneuniq]);

  //應該在檢查phoneuniq之後才可以觸發倒數計時
  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒數計時結束
            return 60; // 重置為60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);
  //當發送完驗證碼之後，如果手機號碼更動，uniq要變為false，都要再重新檢查過
  useEffect(() => {
    setPhoneuniq(false);
    setPhonesent(false);
    setCaptchaError(null);
    console.log("檢查");
  }, [phone, email]);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    pattern,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      pattern={pattern}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <div className="icon danger-notice-icon"></div>
      <p className={` danger`}>{errormessage}</p>
    </div>
  );

  return (
    <>
      {isRegisterPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsRegisterPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsRegisterPopupOpen(false);
          }}
          icon={"check"}
          title={"已註冊成功"}
          orangebtn={"前往登入"}
          greenbtn={""}
        />
      )}
      <div className="register-title">
        {title && (
          <h4 className="title" onClick={handleQuickFill}>
            {title}
          </h4>
        )}
        {!title && (
          <h4 className="title" onClick={handleQuickFill}>
            首次購物請填寫下方資料：
          </h4>
        )}
      </div>
      <form className="register-form" onSubmit={handleFormSubmit}>
        <div className="title-descript">
          <div className="d-flex">
            <p>「</p>
            <p>*</p>
            <p>」為必填欄位。</p>
          </div>
        </div>
        <div>
          <label htmlFor="phone-number" className="form-label d-flex">
            <h6 className="primary-deep2">手機號碼</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "phone-number",
            "phone-number",
            "phone-number",
            "form-input",
            "輸入手機號碼(0912345678)",
            phone,
            handlePhoneNumberChange,
            "[0-9]{10}",
            () => {
              setphoneBlur(true);
            },
            () => {
              setphoneBlur(false);
            },
            "10",
            "10",
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phonesent,
            phoneuniq,
            "error-phone-uniq",
            "該手機號碼已被註冊，無法發送驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            phoneOnBlur || sendCaptchaError || checkError,
            isValidPhoneNumber(phone),
            "error-phone-number",
            "請輸入正確的格式(例如：0912345678)",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="email" className="form-label d-flex">
            <h6 className="primary-deep2">信箱</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "email",
            "email",
            "email",
            "form-input",
            "請輸入常用信箱",
            email,
            handleEmailChange,
            "",
            () => {
              setEmailonBlur(true);
            },
            () => {
              setEmailonBlur(false);
              setIsSubmitted(false);
            },
            3,
            254
          )}

          {errorInput(
            emailOnblur || email || sendCaptchaError || checkError,
            isValidEmail(email),
            "same-email",
            "請輸入正確的信箱",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="username" className="form-label d-flex">
            <h6 className="primary-deep2">姓名</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "username",
            "text",
            "username",
            "form-input",
            "輸入姓名",
            name,
            handleNameChange,
            "",
            () => {
              setNameBlur(true);
            },
            () => {
              setNameBlur(false);
            },
            1,
            50
          )}
          {errorInput(
            nameOnBlur || name || sendCaptchaError || checkError,
            isValidName(name),
            "error-username",
            "請輸入正確的名稱，不得為空且不得包含特殊符號",
            "d-block",
            "d-none"
          )}
        </div>

        <div>
          <label htmlFor="register-pwd" id="user-pwd" className="form-label d-flex">
            <h6 className="primary-deep2">密碼</h6>
            <div className="required-icon"></div>
          </label>
          <div className="d-flex">
            {renderInputField(
              "register-pwd",
              showPassword ? "text" : "password",
              "register-pwd",
              "form-pwd",
              "請輸入密碼(格式為英文字母大小寫加上數字,共8~20位英數字)",
              password,
              handlePasswordChange,
              "",
              () => {
                setPasswordBlur(true);
              },
              () => {
                setPasswordBlur(false);
              },
              "8",
              "20"
            )}
            <div className="btn see-pwd ">
              <div
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <div className="icon eyeclose-icon "></div>
                ) : (
                  <div className="icon eyeopen-icon "></div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-1 secondary-deep">
            <p>英文字母大小寫加上數字,共8~20位英數字</p>
          </div>
          {errorInput(
            passwordOnblur || password || sendCaptchaError || checkError,
            isValidPassword(password),
            "error-pwd",
            "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字)",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="confirm-pwd" className="form-label d-flex">
            <h6 className="primary-deep2">再次輸入密碼</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "confirm-pwd",
            "password",
            "confirm-pwd",
            "form-input",
            "請再次輸入密碼",
            confirmPassword,
            handleConfirmPasswordChange,
            "",
            () => {
              setConfirmPasswordBlur(true);
            },
            () => {
              setConfirmPasswordBlur(true);
            }
          )}
          {errorInput(
            confirmPasswordOnBlur || sendCaptchaError || checkError,
            !confirmPasswordError,
            "error-confirm-pwd",
            "再次輸入密碼和密碼不相同",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <div className="capcha-box">
            <div className="capcha-foriput">
              <label htmlFor="captcha" className="form-label d-flex">
                <h6 className="primary-deep2">驗證碼</h6>
                <div className="required-icon"></div>
              </label>
              {renderInputField(
                "captcha",
                "text",
                "captcha",
                "form-input",
                "輸入驗證碼",
                captcha,
                handleCaptchaChange,
                "",
                () => {
                  setCaptchaBlur(true);
                },
                () => {
                  setCaptchaBlur(false);
                  setFormCheck(false);
                  setIsSameCaptcha(true);
                }
              )}
            </div>
            <div className="capcha-photo pt-2">
              <button
                onClick={handlePhoneCheck}
                className="btn primary-btn"
                disabled={verifysent}
              >
                <p className="p2">
                  {verifysent ? `發送驗證碼(${countdown})` : "發送驗證碼"}
                </p>
              </button>
            </div>
          </div>
          {/* <Nodata bgclass={"primary-deep3 "} icon={"noicon"} link={"nodescript"} descript={"收不到驗證碼?"}linkDescript={"點此不登入也可繼續結帳"}/> */}
          <TextPopup temp={"sms"} textstyle={"danger"} />
          {errorInput(
            captchaOnblur || phonesent || sendCaptchaError || checkError,
            isSameCaptcha,
            "error-capcha",
            "請輸入正確的手機驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            captchaOnblur || phonesent || sendCaptchaError || checkError,
            captcha,
            "error-capcha",
            "請輸入正確的手機驗證碼",
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phoneOnBlur,
            !captchaError,
            "error-capcha-limit",
            captchaError,
            "d-block",
            "d-none"
          )}
          {errorInput(
            isValidPhoneNumber(phone) && phonesent,
            phoneuniq,
            "error-phone-uniq",
            "該手機號碼已被註冊，無法發送驗證碼",
            "d-block",
            "d-none"
          )}
          {sendCaptchaError && (
            <div
              className={`pt-0 ${
                !isValidPhoneNumber(phone) ||
                !isValidEmail(email) ||
                !isValidName(name) ||
                !isValidPassword(password) ||
                confirmPasswordError
                  ? "d-block w-100"
                  : "d-none"
              }`}
            >
              <Nodata
                icon={"dangernotice"}
                dangerclass={"danger"}
                dangerdescript={`未填寫正確的${
                  isValidPhoneNumber(phone)
                    ? ""
                    : !isValidPhoneNumber(phone) &&
                      isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError
                    ? "手機"
                    : "手機、"
                }${
                  isValidEmail(email)
                    ? ""
                    : !isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError
                    ? "信箱"
                    : "信箱、"
                }
                  ${
                    isValidName(name)
                      ? ""
                      : !isValidName(name) &&
                        isValidPassword(password) &&
                        !confirmPasswordError
                      ? "姓名"
                      : "姓名、"
                  }
                  ${
                    isValidPassword(password)
                      ? ""
                      : !isValidPassword(password) && !confirmPasswordError
                      ? "密碼"
                      : "密碼、"
                  }
                  ${
                    !confirmPasswordError
                      ? ""
                      : !!confirmPasswordError
                      ? "再次輸入密碼"
                      : ""
                  }
                  ，無法發送驗證碼`}
                bgclass={"bg-white"}
              />
            </div>
          )}
        </div>

        <div className="register-btn-section ">
          <button
            className={`${
              isRegisterPopupOpen ? "disabled" : ""
            } btn primary-btn-big2  `}
            type="submit"
            // disabled={
            //   !phoneuniq ||
            //   isRegisterPopupOpen ||
            //   !captcha ||
            //   !isValidPhoneNumber(phone) ||
            //   !isValidEmail(email) ||
            //   !isValidName(name) ||
            //   !isValidPassword(password) ||
            //   confirmPasswordError ||
            //   !phonesent ||
            //   !verifysent ||
            //   !isSameCaptcha
            // }
          >
            {buttondescript && <h6>{buttondescript}</h6>}
            {!buttondescript && <h6>註冊</h6>}
          </button>
          {/* <div
              className={`pt-0 ${
                !isValidPhoneNumber(phone) ||
                !isValidEmail(email) ||
                !isValidName(name) ||
                !isValidPassword(password) ||
                !!confirmPasswordError ||
                !captcha
                  ? "d-block w-100"
                  : "d-none"
              }`}
            >
              <Nodata
                icon={"dangernotice"}
                dangerclass={"ps-1"}
                dangerdescript={`請填寫正確的${
                  isValidPhoneNumber(phone)
                    ? ""
                    : !isValidPhoneNumber(phone) &&
                      isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !isSameCaptcha
                    ? "手機"
                    : "手機、"
                }${
                  isValidEmail(email)
                    ? ""
                    : !isValidEmail(email) &&
                      isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "信箱"
                    : "信箱、"
                }
                ${
                  isValidName(name)
                    ? ""
                    : !isValidName(name) &&
                      isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "姓名"
                    : "姓名、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) &&
                      !confirmPasswordError &&
                      !captcha
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  !confirmPasswordError
                    ? ""
                    : !!confirmPasswordError && !captcha
                    ? "再次輸入密碼"
                    : "再次輸入密碼、"
                }
                ${captcha ? "" : !captcha ? "驗證碼" : ""}`}
                bgclass={"bg-white"} 
              />
            </div> */}

          {/* <PwdLink state={"L"} /> */}
          {/* {title && <CheckOutNa title={title} />} */}
        </div>
      </form>
      {location.pathname !== "/checkoutstep1" && (
        <div className="login-section">
          <h6 className="title">已經有帳號？</h6>
          <Link to="/login">
            <div className="btn primary-btn ">
              <h6>登入</h6>
            </div>
          </Link>
        </div>
      )}
    </>
  );
}

export default RegisterLocalSection;
