import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import Popup from "../../sharedComponents/Popup";

const ProductDiy = ({ newdoc }) => {
  const { myAuth } = useContext(AuthContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  

  const openNewTabWithState = () => {
    if (myAuth.authorized) {
      const mid = parseInt(myAuth.sid);
      const storedToken = myAuth.token;
      window.open(`/hgeditor/?newdoc=${newdoc}&token=${storedToken}`, "_blank");
    }else {
      setIsPopupOpen(true);
    }

   
    // ?newdoc=&token=
  };

  return (
    <>
    {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            navigate("/login");
          }}
          onClose={() => {
            setIsPopupOpen(false);
            window.open(`/hgeditor/?newdoc=${newdoc}`, "_blank");
          }}
          closePop={() => {
            setIsPopupOpen(false);
            
          }}
          icon={"notice"}
          title={"登入才可儲存線上編輯作品"}
          orangebtn={"前往登入"}
          greenbtn={"暫不登入"}

        />
      )}
     <div onClick={openNewTabWithState} className='btn diy-btn' >
      線上編輯
    </div>
    </>
   
  );
};

export default ProductDiy;