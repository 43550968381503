import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import NamePhoneLabel from "../sharedComponents/NamePhoneLabel";
import TWZipCode from "../sharedComponents/TWZipCode";
import Nodata from "../sharedComponents/Nodata";
import Breadcrumbs from "./Breadcrumbs";
function Sample() {
  const [contacttitle, setContacttilte] = useState("");
  const [titleOnblur, setTitleonBlur] = useState(false);

  const [contacttext, setContacttext] = useState("");
  const [textOnblur, setTextonBlur] = useState(false);
  //地址
  const [postcode, setPostcode] = useState();
  //父層接收到的地址
  const [reciptePostcode, setReciptePostcode] = useState("");
  const [recipteCountry, setRecipteCountry] = useState("");
  const [recipteTownship, setRecipteTownship] = useState("");
  const [recipteAddress, setRecipteAddress] = useState("");

  //captcha
  const [captcha, setCaptcha] = useState("");
  const [captchaData, setCaptchaData] = useState({});
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  //發送表單的檢查和相關欄位
  const [namePhoneValid, setNamePhoneValid] = useState(false);
  const [reciptName, setReciptName] = useState("");
  const [reciptTele, setReciptTele] = useState("");
  const [addressValid, setAddressValid] = useState(false);
  const [formCheck, setFormCheck] = useState(false);
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [contactImg, setContactImg] = useState("");

  const navigate = useNavigate();
  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const handleContactTitleChange = (e) => {
    const inputValue = e.target.value;

    // 使用正则表达式移除特殊符號
    // const sanitizedValue = inputValue.replace(
    //   /[^A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\-_]/g,
    //   ""
    // );
    setContacttilte(inputValue);
  };
  const handleContactTextChange = (e) => {
    const inputValue = e.target.value;
    // 使用正規表達式驗證輸入，只允許中文、字母、數位、空格和常見標點符號
    // const sanitizedValue = inputValue.replace(
    //   /[^A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\-_]/g,
    //   ""
    // );

    setContacttext(inputValue);
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  // useEffect(() => {
  //   setIsSubmitted(false); // Reset isSubmitted when inputs change
  // }, [captcha, email]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormCheck(true);
    if (!isValidCaptcha(captcha)) {
      getCaptcha();
      return;
    }
    if (
      !isValidContactTitle(contacttitle) ||
      !isValidContactText(contacttext) ||
      !isValidCaptcha(captcha)
    ) {
      console.log("Form data is not valid.");
      getCaptcha();
      return;
    }
    //發送的資料
    const requestData = {
      SampleName: escapeHTML(contacttitle),
      Content: escapeHTML(contacttext),
      RecipientTel: escapeHTML(reciptTele),
      RecipientAddr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
      RecipientName: escapeHTML(reciptName),
    };

    try {
      //  POST
      const response = await axios.post("/api/Home/Sample", requestData);

      // 根据服务器响应进行处理
      if (response.status === 200) {
        console.log("回傳的Sample", "response.data");
        console.log("Sample傳過去的資料", "requestData");
        setFormCheck(true);
        setIsPopupOpen(true);
        // 成功之後的邏輯
      } else {
        console.error("Form submission failed.");

        // 失敗的結果
      }
    } catch (error) {
      console.error("回傳的Sample：", "error");
      console.log("回傳的Sample有錯誤", "requestData");
      //額外錯誤
    }
  };

  const isValidContactTitle = (contacttitle) => {
    if (typeof contacttitle === "string" || contacttitle instanceof String) {
      // Allow only Chinese, English, and numeric characters
      const titleRegex = /^[A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\s\p{P}]+$/u;
      return contacttitle.trim() !== "" && titleRegex.test(contacttitle);
    }
    return false; // 如果 contacttitle 不是字符串，返回 false
  };

  const isValidContactText = (contacttext) => {
    if (typeof contacttext === "string" || contacttext instanceof String) {
      // Allow any characters for content
      return contacttext.trim() !== "";
    }
    return false; // 如果 contacttext 不是字符串，返回 false
  };

  const isValidCaptcha = (captcha) => {
    return captcha === captchaData.code;
  };

  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const handleQuickFill = () => {
    // const quickFillData = {
    //   contacttitle: "測試標題",
    //   contacttext: "測試輸入的內容",
    // };
console.log("click handleQuickFill")
    // setContacttilte(quickFillData.contacttitle);
    // setContacttext(quickFillData.contacttext);
  };

  //初始載入畫面取得驗證碼
  useEffect(() => {
    getCaptcha();
  }, []);

  //驗證

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "d-block" : notshow || "d-none"
      } d-flex  pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );

  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            window.location.reload();
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"訊息已發送。"}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}
      <div className="container sample px-0 ">
        <Breadcrumbs/>
        <div className="login-detail row d-flex justify-content-center flex-colum">
          <div className="container login-content p-xxl-0 col col-xxl-7">
            <div className="login-title pt-3 pb-2 pt-xxl-0 pb-xxl-2">
              <h4 onClick={handleQuickFill}>樣品索取</h4>
              <div className="pt-2 pb-3">
                <h6>您好:</h6>
                <h6>
                  若您有樣品試裝及尺寸測量需求，可填寫以下資料索取3-4個樣品袋。
                  本公司收到資料後，會以平信方式寄出，請耐心等候。
                </h6>
                <h6>*紙盒、紙袋怒不提供樣品</h6>
              </div>
            </div>
            <form className="login-form" onSubmit={handleFormSubmit}>
              <NamePhoneLabel
                phonestyle={"d-none"}
                nametitle={"姓名(必填)"}
                telephonetitle={"連絡電話(必填)"}
                itemTelePhone={"輸入常用聯絡電話"}
                musthavephone={true}
                onNamePhoneChange={(name, phone, telephone) => {
                  console.log("改變的子層", "name, phone, telephone");
                  setReciptName(name);
                  setReciptTele(telephone);
                }}
                errornone={true}
                isNamePhoneValid={(isValid) => {
                  console.log("父層接受到的格式", "isValid");
                  setNamePhoneValid(isValid);
                  //TODO:if isvalid won't send form data
                }}
                noChangeOk={true}
              />
              <label htmlFor="contacttitle" className="form-label d-block pt-3">
                <h6 className="primary-deep2">索取樣袋之名稱(必填)</h6>
              </label>
              {renderInputField(
                "contacttitle",
                "text",
                "contacttitle",
                "form-input",
                "請輸入標題",
                contacttitle,
                handleContactTitleChange,
                () => {
                  setTitleonBlur(true);
                },
                () => {
                  setTitleonBlur(false);
                },
                1,
                50
              )}

              <p className="p1 secondary-deep">
                *若不清楚適用何種袋型或材質，請洽03-3751700有專人服務。
              </p>
              {errorInput(
                titleOnblur,
                isValidContactTitle(contacttitle),
                "error-contacttitle",
                "請輸入索取樣袋之名稱，此欄位不得為空"
              )}

              <label htmlFor="contacttext" className="form-label d-block pt-3">
                <h6 className="primary-deep2">包裝用途(必填)</h6>
              </label>
              <textarea
                type="text"
                id="contacttext"
                name="contacttext"
                value={contacttext}
                className="form-input"
                onChange={handleContactTextChange}
                onBlur={() => {
                  setTextonBlur(true);
                }}
                onClick={() => {
                  setTextonBlur(false);
                }}
                placeholder="輸入包裝用途"
                rows="5"
                cols="33"
                minLength="1"
                maxLength="250"
              />
              {errorInput(
                textOnblur,
                isValidContactText(contacttext),
                "error-contacttext",
                "請輸入包裝用途，此欄位不得為空"
              )}
              <label htmlFor="address" className="form-label d-block pt-3">
                <h6 className="primary-deep2">收件地址(必填)</h6>
              </label>
              <TWZipCode
                initPostcode={postcode}
                onPostcodeChange={(country, township, postcode) => {
                  setReciptePostcode(postcode);
                  setRecipteCountry(country);
                  setRecipteTownship(township);
                }}
                onAddressChange={(address) => {
                  console.log("address", "address");
                  setRecipteAddress(address);
                }}
                itemAddress=""
                isTWZipCodeValid={(isValid) => {
                  console.log("父層接受到的地址格式", "isValid");
                  setAddressValid(isValid);
                }}
              />
              <div className="capcha-box">
                <div className="capcha-foriput">
                  <label htmlFor="captcha" className="form-label d-block">
                    <h6 className="primary-deep2">圖形驗證碼(必填)</h6>
                  </label>
                  {renderInputField(
                    "captcha",
                    "text",
                    "captcha",
                    "form-input",
                    "輸入圖形驗證碼",
                    captcha,
                    handleCaptchaChange,
                    () => {
                      setCaptchaBlur(true);
                    },
                    () => {
                      setCaptchaBlur(false);
                      setFormCheck(false);
                    }
                  )}
                </div>

                <div className="capcha-photo pt-2">
                  <img src={captchaData.imageData} alt="圖形驗證碼" />
                </div>
              </div>
              {errorInput(
                captchaOnblur && formCheck,
                isValidCaptcha(captcha),
                "error-capcha",
                "請輸入正確的圖形驗證碼"
              )}
              <div className="login-btn pt-2">
                <button
                  className="btn primary-btn2 w-100"
                  type="submit"
                  disabled={!namePhoneValid || !addressValid || !captcha}
                >
                  送出
                </button>
              </div>
              <div
                className={`pt-2 ${
                  !namePhoneValid || !addressValid || !captcha
                    ? "d-block"
                    : "d-none"
                }`}
              >
                <Nodata
                icon={"dangernotice"}
                dangerclass={"ps-1"}
                dangerdescript={`尚未填寫${
                  reciptName ? "": (!reciptName &&
                    reciptTele &&
                    contacttitle &&
                    contacttext &&
                    addressValid &&
                    captcha
                      ? "姓名"
                      : "姓名、") 
                }${
                  reciptTele ? "": (!reciptTele &&
                  contacttitle &&
                  contacttext &&
                  addressValid &&
                  captcha
                    ? "電話"
                    : "電話、")
                }
                ${contacttitle ? "":  ( !contacttitle &&
                  contacttext &&
                  addressValid &&
                  captcha
                    ? "樣品名稱"
                    : "樣品名稱、"
                    )
                }
                ${
                  contacttext ? "": ( !contacttext &&
                  addressValid &&
                  captcha
                    ? "包裝用途"
                    : "包裝用途、")
                }
                ${
                  addressValid ? "": (!addressValid &&
                  captcha
                    ? "收件地址"
                    : "收件地址、")
                  
                }
                ${
                  captcha ? "": (!captcha
                    ? "圖形驗證碼"
                    : "")
                }`}
                bgclass={"bg-white"}
              />
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default Sample;
