//LoginLocalSection.js
import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "../Context/AuthContext";
import Popup from "../sharedComponents/Popup";
import ThirdPartyLogin from "./ThirdPartyLogin";
import PwdLink from "./PwdLink";
import axios from "axios";
import Loading from "../components/Loading";
import Nodata from "./Nodata";

function LoginLocalSection({ title, NoregistLink }) {
  const { login } = useContext(AuthContext);
  const { myAuth } = useContext(AuthContext);
  const location = useLocation();

  const [isloading, setIsLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneOnBlur, setPhoneBlur] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [captchaData, setCaptchaData] = useState({});

  const [loginFail, setLoginFail] = useState("");
  // 檢查錯誤
  const [checkError, setCheckError] = useState(false);
  const navigate = useNavigate();

  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };

  const handlePhoneNumberChange = (e) => {
    setPhoneNumber(e.target.value);
    setLoginFail("");
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setLoginFail("");
  };

  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true); // Mark the form as submitted
    setPhoneBlur(true);
    setPasswordBlur(true);
    setCaptchaBlur(true);
    // Perform format validation only if the form is submitted
    // if (!isValidCaptcha(captcha)) {
    //   // getCaptcha();
    //   return;
    // }

    if (
      !isValidPhoneNumber(phoneNumber) ||
      !isValidPassword(password) ||
      !isValidCaptcha(captcha)
    ) {
      // getCaptcha();
      console.log("不合法格式");
      let firstInvalidInputRef = null;
      if (!isValidPhoneNumber(phoneNumber) || !phoneNumber) {
        firstInvalidInputRef = document.getElementById("local-phone-number");
      } else if (!isValidPassword(password) || !password) {
        firstInvalidInputRef = document.getElementById("member-pwd");
      } else if (!isValidCaptcha(captcha) || !captcha) {
        firstInvalidInputRef = document.getElementById("local-captcha");
      }

      if (firstInvalidInputRef) {
        console.log("firstInvalidInputRef", "firstInvalidInputRef");
        firstInvalidInputRef.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
      setCheckError(true);
      return;
    } else {
      setCheckError(false);
    }
    // console.log("登入")
    try {
      const loginResult = await login(phoneNumber, password, "L"); // 等待登录完成

      if (!loginResult.success) {
        console.log("false test", "loginResult");
        setIsPopupOpen(true);
        getCaptcha();
        setCaptcha("");
        setLoginFail(loginResult.message);
        setIsSubmitted(false);
        console.log("Login failed: " + "loginResult.message");
        //登入失敗才打開popup
      } else {
        //登入成功回傳的loginResult會是undefined
        //登入成功不做處理
        console.log("loginResult", "loginResult");
      }
    } catch (error) {
      console.error("在local的Error during login:", "error");
      // 登入例外的錯誤狀況
    }
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phoneNumber);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;

    return passwordRegex.test(password);
  };

  const isValidCaptcha = (captcha) => {
    return captcha === captchaData.code;
  };
  const handleQuickFill = () => {
    console.log("handleQuickFill click");
    // const quickFillData = {
    //   phoneNumber: "0912345678",
    //   password: "Password123",
    //   captcha: "123456789",
    // };
    // setPhoneNumber(quickFillData.phoneNumber);
    // setPassword(quickFillData.password);
    // setCaptcha(quickFillData.captcha);
  };
  useEffect(() => {
    getCaptcha();
  }, []);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <div className="icon danger-notice-icon"></div>
      <p className={` danger`}>{errormessage}</p>
    </div>
  );
  if (!captchaData || !captchaData.imageData) {
    <Loading />;
  }
  return (
    <>
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            setCaptchaBlur(false);
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"notice"}
          title={loginFail}
          orangebtn={"重新登入"}
          greenbtn={""}
        />
      )}
      {/* <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2 ">
        {title && <h5 onClick={handleQuickFill}>轉移舊會員資料(紅利點數)</h5>}
        {!title && <h4 onClick={handleQuickFill}>轉移舊會員資料(紅利點數)</h4>}

        <div className="pt-2 pb-3">
          <h6 className="d-inline">
            若已轉移舊會員資料請直接使用下方的會員登入
          </h6>
          <div className="pt-3">
            <Link
              to="/oldmemeberlogin"
              className={`btn ${title ? "primary-btn-big":"primary-btn6"} w-100 d-flex justify-content-center align-items-center gap-10px`}
            >
              
              <h6 className={` ${title ? "font-weight-bold ":"p1 py-1"}`}>轉移舊會員資料</h6>
            </Link>
          </div>
        </div>
      </div> */}
      <div className="thirdparty-container">
        {title && (
          <>
            <h4 className="title">{title}</h4>
            <h6 className="fast-login">使用快速登入</h6>
          </>
        )}
        {!title && <h4>使用快速登入</h4>}
        <ThirdPartyLogin />
      </div>
      <div className="login-title">
        {title && <h5 onClick={handleQuickFill}>帳號密碼登入</h5>}
        {!title && <h5 onClick={handleQuickFill}>帳號密碼登入</h5>}
      </div>

      <form className="login-form" onSubmit={handleFormSubmit}>
        <div className="title-descript">
          <div className="d-flex">
            <p>「</p>
            <p>*</p>
            <p>」為必填欄位。</p>
          </div>
        </div>
        <div>
          <label htmlFor="local-phone-number" className="form-label d-flex">
            <h6 className="primary-deep2">帳號(手機號碼)</h6>
            <div className="required-icon"></div>
          </label>
          {renderInputField(
            "local-phone-number",
            "phone-number",
            "tel",
            "form-input",
            "輸入手機號碼(0912345678)",
            phoneNumber,
            handlePhoneNumberChange,
            () => {
              setPhoneBlur(true);
            },
            () => {
              setPhoneBlur(false);
            },
            10,
            10
          )}
          {errorInput(
            phoneOnBlur || phoneNumber,
            !loginFail,
            "error-username",
            loginFail,
            "d-block",
            "d-none"
          )}
          {errorInput(
            phoneOnBlur || phoneNumber,
            isValidPhoneNumber(phoneNumber),
            "error-username",
            "請輸入正確的格式(例如：0912345678)",
            "d-block",
            "d-none"
          )}
        </div>
        <div>
          <label htmlFor="member-pwd" id="user-pwd" className="form-label">
            <h6 className="primary-deep2">密碼</h6>
            <div className="required-icon"></div>
          </label>

          <div className="d-flex">
            {renderInputField(
              "member-pwd",
              showPassword ? "text" : "password",
              "phone-number",
              "form-input",
              "輸入密碼",
              password,
              handlePasswordChange,
              () => {
                setPasswordBlur(true);
              },
              () => {
                setPasswordBlur(false);
              },
              8,
              20
            )}

            <div className="btn see-pwd ">
              <div
                className="btn checkbox-icon check showpwd"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <div className="icon eyeclose-icon "></div>
                ) : (
                  <div className="icon eyeopen-icon "></div>
                )}
              </div>
            </div>
          </div>
          <div className="pt-1 secondary-deep2 ">
            <p>格式為英文字母大小寫加上數字,共8~20位英數字</p>
          </div>
          {errorInput(
            passwordOnblur || password,
            !loginFail,
            "error-username",
            loginFail,
            "d-block",
            "d-none"
          )}
          {errorInput(
            passwordOnblur || password,
            isValidPassword(password),
            "error-pwd",
            "請輸入正確的密碼(英文字母大小寫加上數字,共8~20位英數字 )",
            "d-block",
            "d-none"
          )}
        </div>

        <div>
          <div className="captcha-box">
            <div className="captcha-foriput">
              <label htmlFor="local-captcha" className="form-label d-flex">
                <h6 className="primary-deep2">圖形驗證碼</h6>
                <div className="required-icon"></div>
              </label>
              {renderInputField(
                "local-captcha",
                "text",
                "captcha",
                "form-input",
                "輸入圖形驗證碼",
                captcha,
                handleCaptchaChange,
                () => {
                  setCaptchaBlur(true);
                },
                () => {
                  setCaptchaBlur(false);
                },
                5,
                5
              )}
            </div>
            {captchaData.imageData && (
              <div
                className="captcha-photo pt-2 cursor-pointer"
                onClick={() => getCaptcha()}
              >
                <img src={captchaData.imageData} alt="圖形驗證碼" />
              </div>
            )}
          </div>
          <div className="pt-1 secondary-deep2 ">
            <p>點擊圖片可刷新驗證碼</p>
          </div>
          {errorInput(
            (captcha || captchaOnblur) && !isPopupOpen,
            isValidCaptcha(captcha),
            "error-captcha",
            "請輸入正確的圖形驗證碼",
            "d-block",
            "d-none"
          )}
        </div>
        <div className="login-btn-section">
          <button
            className={` primary-btn-big2`}
            type="submit"
            // disabled={!isValidPhoneNumber(phoneNumber) || !isValidPassword(password) || !captcha}
          >
            <h6>登入</h6>

          </button>
          <div className="w-100">
            <div className={`pt-0 ${loginFail ? "d-block w-100" : "d-none"}`}>
              <Nodata
                icon={"dangernotice"}
                dangerclass={"danger"}
                dangerdescript={loginFail}
                bgclass={"bg-white"}
              />
            </div>
            {checkError && (
              <div
                className={`pt-0 ${
                  !isValidPhoneNumber(phoneNumber) ||
                  !isValidPassword(password) ||
                  !isValidCaptcha(captcha)
                    ? "d-block w-100"
                    : "d-none"
                }`}
              >
                <Nodata
                  icon={"dangernotice"}
                  dangerclass={"danger"}
                  dangerdescript={`請填寫正確的
                ${
                  isValidPhoneNumber(phoneNumber)
                    ? ""
                    : !isValidPhoneNumber(phoneNumber) &&
                      isValidPassword(password) &&
                      isValidCaptcha(captcha)
                    ? "帳號"
                    : "帳號、"
                }
                ${
                  isValidPassword(password)
                    ? ""
                    : !isValidPassword(password) && isValidCaptcha(captcha)
                    ? "密碼"
                    : "密碼、"
                }
                ${
                  isValidCaptcha(captcha)
                    ? ""
                    : !isValidCaptcha(captcha)
                    ? "圖形驗證碼"
                    : ""
                }`}
                  bgclass={"bg-white"}
                />
              </div>
            )}
          </div>

          <PwdLink state={"L"} />
        </div>
      </form>
      {location.pathname !== "/checkoutstep1" && (
        <div className="register-section">
          <h6 className="title">還不是會員？</h6>
          <Link to="/register">
          <div  className="btn primary-btn ">
          <h6>免費加入會員</h6>
          </div>
           
          </Link>
        </div>
      )}
    </>
  );
}

export default LoginLocalSection;
