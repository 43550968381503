//Contactus.js
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Popup from "../sharedComponents/Popup";
import Loading from "./Loading";
import Nodata from "../sharedComponents/Nodata";
import Breadcrumbs from "./Breadcrumbs";
function Contactus() {
  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);

  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);

  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);

  const [contacttitle, setContacttilte] = useState("");
  const [titleOnblur, setTitleonBlur] = useState(false);

  const [contacttext, setContacttext] = useState("");
  const [textOnblur, setTextonBlur] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false); // State to track if the form is submitted
  const [captchaData, setCaptchaData] = useState({});

  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [contactImg, setContactImg] = useState("");

  const navigate = useNavigate();
  const getCaptcha = () => {
    axios
      .get(`/api/User/Captcha`, {
        headers: {},
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Captcha Data:", "response.data");
          setCaptchaData(response.data);
        } else {
          console.log("Captcha failed:", "response.data");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  const getStoreData = () => {
    axios
      .get("/api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return null;
        }
      })
      .then((data) => {
        if (data) {
          setContactImg(data.contactImage);
          console.log("contactImg", "response.data");
        }
      })
      .catch((error) => {
        console.error("Error fetching data from API:", "error");
      });
  };
  //圖片
  useEffect(() => {
    getStoreData();
    getCaptcha();
  }, []);
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
  // useEffect(() => {
  //   setIsSubmitted(false); // Reset isSubmitted when inputs change
  // }, [captcha, email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(
      /[^A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\-_]/g,
      ""
    );
    setName(sanitizedValue);
  };

  const handleCaptchaChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/[^A-Za-z0-9\s.,!?()-]/g, "");
    setCaptcha(sanitizedValue);
  };
  const handleContactTitleChange = (e) => {
    const inputValue = e.target.value;
    // Use regular expression to allow Chinese, English, numeric characters, spaces, and common punctuation marks
    // const sanitizedValue = inputValue.replace(/[^\w\s\u4e00-\u9fa5\u3105-\u3129\p{P}]/gu, '');

    setContacttilte(inputValue);
  };
  const handleContactTextChange = (e) => {
    const inputValue = e.target.value;
    // Use regular expression to allow Chinese, English, numeric characters, spaces, and common punctuation marks
    // const sanitizedValue = inputValue.replace(/[^\w\s\u4e00-\u9fa5\u3105-\u3129\p{P}]/gu, '');

    setContacttext(inputValue);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!isValidCaptcha(captcha)) {
      getCaptcha();
      return;
    }
    if (
      !isValidEmail(email) ||
      !isValidName(name) ||
      !isValidContactTitle(contacttitle) ||
      !isValidContactText(contacttext) ||
      !isValidCaptcha(captcha)
    ) {
      console.log("Form data is not valid.");
      getCaptcha();
      return;
    }
    //發送的資料
    const formData = {
      Name: escapeHTML(name),
      Email: escapeHTML(email),
      Title: escapeHTML(contacttitle),
      Message: escapeHTML(contacttext),
    };

    try {
      //  POST
      const response = await axios.post("/api/Home/Contactus", formData);

      // 根据服务器响应进行处理
      if (response.status === 200) {
        console.log("Form submitted successfully!");
        setIsPopupOpen(true);
        // 成功之後的邏輯
      } else {
        console.error("Form submission failed.");

        // 失敗的結果
      }
    } catch (error) {
      console.error("An error occurred while submitting the form:", "error");
      console.log("formData", "response");
      //額外錯誤
    }
  };

  //格式檢查
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidName = (name) => {
    if (typeof name === "string" || name instanceof String) {
      const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      // 确保字符串不为空且至少包含两个字符
      return name.trim().length >= 2 && nameRegex.test(name);
    }
    return false; // 如果 name 不是字符串，返回 false
  };

  const isValidContactTitle = (contacttitle) => {
    if (typeof contacttitle === "string" || contacttitle instanceof String) {
      // Allow Chinese, English, numeric characters, spaces, and common punctuation marks
      const titleRegex = /^[A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\s\p{P}]+$/u;
      return contacttitle.trim() !== "" && titleRegex.test(contacttitle);
    }
    return false; // If contacttitle is not a string, return false
  };

  const isValidContactText = (contacttext) => {
    if (typeof contacttext === "string" || contacttext instanceof String) {
      // Allow any characters for content
      return contacttext.trim() !== "";
    }
    return false; // 如果 contacttext 不是字符串，返回 false
  };

  const isValidCaptcha = (captcha) => {
    return captcha === captchaData.code;
  };
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  const handleQuickFill = () => {
    console.log("click", "handleQuickFill");
    // const quickFillData = {
    //   name: "Nick",
    //   email: "aa1@gmail.com",
    //   captcha: "123456789",
    //   contacttitle: "測試標題",
    //   contacttext: "測試輸入的內容",
    // };
    // setName(quickFillData.name);
    // setEmail(quickFillData.email);
    // setCaptcha(quickFillData.captcha);
    // setContacttilte(quickFillData.contacttitle);
    // setContacttext(quickFillData.contacttext);
  };
  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "d-block" : notshow || "d-none"
      } d-flex pb-0`}
      id={id}
    >
      <div className="icon danger-notice-icon"></div>
      <p className={` danger`}>{errormessage}</p>
    </div>
  );
  if (!captchaData || !captchaData.imageData) {
    return <Loading />;
  }
  return (
    <div className="page-background contactus-background">
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            window.location.reload();
          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"訊息已發送。"}
          // title={'我們已收到您的訊息，我們會盡快與您聯絡。'}
          orangebtn={"好的"}
          greenbtn={""}
        />
      )}
      <div className="top-img-container">
        <div className="contactus-img-container">
          <div className="contactus-img-box">
            <img src={contactImg} alt="" />
          </div>
        </div>
      </div>

      <div className="share-page contactus-page">
        <Breadcrumbs />
        <div className="contactus">
          <div className="login-detail">
            <div className="center-img-container">
              <div className="contactus-img-box">
                <img src={contactImg} alt="" />
              </div>
            </div>
            <div className=" login-content contactus-container">
              <div className="login-title">
                <h4 className="title" onClick={handleQuickFill}>
                  聯絡我們
                </h4>
              </div>

              <form className="login-form" onSubmit={handleFormSubmit}>
                <div className="title-descript">
                  <h6>
                    我們的客服團隊收到訊息後將盡快回覆，如遇大量訊息回覆速率較慢時，敬請見諒。
                  </h6>
                  <div className="d-flex">
                    <p>「</p>
                    <p>*</p>
                    <p>」為必填欄位。</p>
                  </div>
                </div>
                <label htmlFor="username" className="form-label">
                  <h6 className="primary-deep2">姓名</h6>
                  <div className="required-icon"></div>
                </label>
                {renderInputField(
                  "username",
                  "text",
                  "username",
                  "form-input",
                  "輸入姓名",
                  name,
                  handleNameChange,
                  () => {
                    setNameBlur(true);
                  },
                  () => {
                    setNameBlur(false);
                  },
                  1,
                  50
                )}
                {errorInput(
                  name || nameOnBlur,
                  isValidName(name),
                  "error-username",
                  "請輸入正確的名稱，不得為空且不得包含特殊符號"
                )}
                <label htmlFor="email" className="form-label ">
                  <h6 className="primary-deep2">信箱</h6>
                  <div className="required-icon"></div>
                </label>
                {renderInputField(
                  "email",
                  "email",
                  "email",
                  "form-input",
                  "請輸入常用信箱",
                  email,
                  handleEmailChange,
                  () => {
                    setEmailonBlur(true);
                  },
                  () => {
                    setEmailonBlur(false);
                    setIsSubmitted(false);
                  },
                  5,
                  254
                )}
                {errorInput(
                  email || emailOnblur,
                  isValidEmail(email),
                  "same-email",
                  "請輸入正確的信箱"
                )}
                <label htmlFor="contacttitle" className="form-label ">
                  <h6 className="primary-deep2">標題</h6>
                  <div className="required-icon"></div>
                </label>
                {renderInputField(
                  "contacttitle",
                  "text",
                  "contacttitle",
                  "form-input",
                  "請輸入標題",
                  contacttitle,
                  handleContactTitleChange,
                  () => {
                    setTitleonBlur(true);
                  },
                  () => {
                    setTitleonBlur(false);
                  },
                  1,
                  50
                )}
                {errorInput(
                  titleOnblur,
                  isValidContactTitle(contacttitle),
                  "error-contacttitle",
                  "請輸入標題，此欄位不得為空"
                )}
                <label htmlFor="contacttext" className="form-label ">
                  <h6 className="primary-deep2">內容</h6>
                  <div className="required-icon"></div>
                </label>
                <textarea
                  type="text"
                  id="contacttext"
                  name="contacttext"
                  value={contacttext}
                  className="form-input"
                  onChange={handleContactTextChange}
                  onBlur={() => {
                    setTextonBlur(true);
                  }}
                  onClick={() => {
                    setTextonBlur(false);
                  }}
                  placeholder="輸入內容"
                  rows="5"
                  cols="33"
                  minLength="1"
                  maxLength="250"
                />
                {errorInput(
                  contacttext || textOnblur,
                  isValidContactText(contacttext),
                  "error-contacttext",
                  "請輸入內容，此欄位不得為空"
                )}
                <div className="captcha-box">
                  <div className="captcha-foriput">
                    <label htmlFor="captcha" className="form-label">
                      <h6 className="primary-deep2">圖形驗證碼</h6>
                      <div className="required-icon"></div>
                    </label>
                    {renderInputField(
                      "captcha",
                      "text",
                      "captcha",
                      "form-input",
                      "輸入圖形驗證碼",
                      captcha,
                      handleCaptchaChange,
                      () => {
                        setCaptchaBlur(true);
                      },
                      () => {
                        setCaptchaBlur(false);
                        setIsSubmitted(false);
                      },
                      5,
                      5
                    )}
                  </div>
                  {captchaData.imageData && (
                    <div
                      className="captcha-photo pt-2 cursor-pointer"
                      onClick={() => getCaptcha()}
                    >
                      <img src={captchaData.imageData} alt="圖形驗證碼" />
                    </div>
                  )}
                </div>
                <div className="pt-1 secondary-deep2 ">
                  <p>點擊圖片可刷新驗證碼</p>
                </div>
                {errorInput(
                  captcha || captchaOnblur,
                  isValidCaptcha(captcha),
                  "error-captcha",
                  "請輸入正確的圖形驗證碼"
                )}
                <div className="login-btn">
                  <button
                    className="btn primary-btn2 w-100"
                    type="submit"
                  >
                    發送
                  </button>
                </div>
                <div
                  className={`pt-2 ${
                    isSubmitted && (!name || !captcha || !email || !contacttitle || !contacttext)
                      ? "d-block"
                      : "d-none"
                  }`}
                >
                  <Nodata
                    icon={"dangernotice"}
                    dangerclass={"danger"}
                    dangerdescript={`尚未填寫${
                      name
                        ? ""
                        : !name &&
                          email &&
                          contacttitle &&
                          contacttext &&
                          captcha
                        ? "姓名"
                        : "姓名、"
                    }${
                      email
                        ? ""
                        : !email && contacttitle && contacttext && captcha
                        ? "信箱"
                        : "信箱、"
                    }
                  ${
                    contacttitle
                      ? ""
                      : !contacttitle && contacttext && captcha
                      ? "標題"
                      : "標題、"
                  }
                  ${
                    contacttext
                      ? ""
                      : !contacttext && captcha
                      ? "內容"
                      : "內容、"
                  }
          
                  ${captcha ? "" : !captcha ? "圖形驗證碼" : ""}`}
                    bgclass={"bg-white"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Contactus;
