import React, { useState, useContext, useEffect } from "react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Zoom,
  Controller,
} from "swiper/modules";
// Direct React component imports

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

// 使用必要的Swiper模組
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

function ImageSlider({ img }) {
  const [swiper, setSwiper] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [activePopIndex, setActivePopIndex] = useState(0);

  const [mainSwiper, setMainSwiper] = useState(null);
  const [popupSwiper, setPopupSwiper] = useState(null);

  // 放大功能
  const [state, setState] = useState({
    backgroundImage: "",
    backgroundPosition: "0% 0%",
  });
  const [showPopup, setShowPopup] = useState(false);

  const handleMouseMove = (e, img) => {
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const x = ((e.pageX - left) / width) * 100;
    const y = ((e.pageY - top) / height) * 100;
    setState({
      backgroundImage: `url(${img})`,
      backgroundPosition: `${x}% ${y}%`,
    });
  };

  //第一張及最後一張禁用
  const isPrevDisabled = activeIndex === 0;
  const isNextDisabled = activeIndex === img.length - 1;

  const isPopPrevDisabled = activePopIndex === 0;
  const isPopNextDisabled = activePopIndex === img.length - 1;
  const openPopup = () => {
    setShowPopup(true);
    console.log("activeIndex", "activeIndex");
    setActivePopIndex(activeIndex);
  };

  const closePopup = (e) => {
    // Check if the click event originated from the overlapping area
    const isClickInsidePopup = e.target.closest(".product-img-popup");
    console.log("popupSwiper", "popupSwiper");
    console.log("mainSwiper", "mainSwiper");
    // setMainSwiper(popupSwiper);
    // Close the popup only if the click is outside the overlapping area
    if (!isClickInsidePopup) {
      setShowPopup(false);
    }
  };
  // useEffect(() => {
  //   console.log("swiper", swiper);
  // }, [swiper]);
  return (
    <>
      <div className="slider-container product-img-container ">
        <div className="slider-box">
          <Swiper
            // install Swiper modules
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Zoom,
              Controller,
            ]}
            spaceBetween={50}
            slidesPerView={1}
            navigation={{
              prevEl: ".prev",
              nextEl: ".next",
            }}
            pagination={{ clickable: true, el: ".swiper-pagination" }}
            onSwiper={(swiper) => setMainSwiper(swiper)}
            controller={{ control: popupSwiper }}
            zoom={true}
            onSlideChange={() => console.log("slide change")}
            onActiveIndexChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
          >
            <div className="magnify-box" onClick={openPopup}>
              <div className="swiper-magnify">
                <div className="magnify-icon icon"></div>
              </div>
            </div>
            {img.map((image, index) => (
              <div key={index}>
                <SwiperSlide key={`slide-${index}`}>
                  {/* {index} */}

                  <figure
                    onMouseMove={(e) => handleMouseMove(e, image)}
                    style={state}
                    onClick={openPopup}
                    className="top-img "
                  >
                    <img className="product-img " src={image} alt="Zoomed" />
                  </figure>
                </SwiperSlide>
              </div>
            ))}

            <div className="img-control">
              <div
                className={` cursor-pointer ${
                  isPrevDisabled ? "disabled" : ""
                }`}
                onClick={() => {
                  mainSwiper.slidePrev();
                }}
              >
                <div className="prev-icon"></div>
              </div>
              <div className="img-number-section">
                <div className="img-number-container">
                  {img.map((_, index) => (
                    <div
                      key={index}
                      className={` ${
                        activeIndex === index ? "point-selected" : "point"
                      }`}
                      onClick={() => mainSwiper.slideTo(index)}
                    ></div>
                  ))}
                </div>
                <div className="number-container">
                  <div className="now">
                    <h6>{activeIndex + 1}</h6>
                  </div>
                  <div className="spilt">
                    <h6>/</h6>
                  </div>
                  <div className="total">
                    <h6>{img.length}</h6>
                  </div>
                </div>
              </div>
              <div className="img-select-section">
                <div className="img-select-container">
                  {img.map((image, index) => (
                    <div
                      key={index}
                      className={`img-select-box ${
                        activeIndex === index ? "img-selected" : "img-unselect"
                      }`}
                      onClick={() => mainSwiper.slideTo(index)}
                    >
                      <div className="img-box">
                        <img className="small-img" src={image} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="icon-select-section">
                <div className="icon-select-container">
                  {img.map((image, index) => (
                    <div
                      key={index}
                      className={`icon-select-box ${
                        activeIndex === index ? "icon-selected" : "icon-unselect"
                      }`}
                      onClick={() => mainSwiper.slideTo(index)}
                    >
                      <div className="icon-box">
                        <div className="icon-index"></div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                className={`cursor-pointer ${isNextDisabled ? "disabled" : ""}`}
                onClick={() => {
                  mainSwiper.slideNext();
                }}
              >
                <div className="next-icon"></div>
              </div>
            </div>
          </Swiper>
        </div>
        {showPopup && (
          <div className="product-img-popupbg" onClick={closePopup}>
            <div className="d-sticky">
              <div className="swiper-close-container" onClick={closePopup}>
                <div className="swiper-close ">
                  <div className="cross-icon icon"></div>
                </div>
              </div>
              <div className="product-img-popup">
                <div className="container h-100percent d-flex gap-1 gap-xxl-3  justify-content-center align-items-center">
                  <div
                    className={`d-block cursor-pointer ${
                      isPopPrevDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      popupSwiper.slidePrev();
                      // mainSwiper.slidePrev();
                    }}
                  >
                    <div className="prev-icon icon"></div>
                  </div>

                  <Swiper
                    // install Swiper modules
                    modules={[Navigation, Pagination, Scrollbar, A11y, Zoom]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation={{
                      prevEl: ".prev",
                      nextEl: ".next",
                    }}
                    pagination={{ clickable: true, el: ".swiper-pagination" }}
                    onSwiper={(swiper) => setPopupSwiper(swiper)}
                    controller={{ control: mainSwiper }}
                    zoom={true}
                    onSlideChange={() => console.log("slide change")}
                    onActiveIndexChange={(swiper) => {
                      setActivePopIndex(swiper.activeIndex);
                    }}
                  >
                    <div className="popup-img-box">
                      {img.map((image, index) => (
                        <div key={index}>
                          <SwiperSlide key={`slide-${index}`}>
                            {/* {index} */}

                            <img className="popup-img" src={image} />
                          </SwiperSlide>
                        </div>
                      ))}
                    </div>

                    <div className="img-number-section">
                      <div className="img-number-container ">
                        {img.map((_, index) => (
                          <div
                            key={index}
                            className={` ${
                              activePopIndex === index
                                ? "point-selected"
                                : "point"
                            }`}
                            onClick={() => mainSwiper.slideTo(index)}
                          ></div>
                        ))}
                      </div>
                    </div>
                  </Swiper>
                  <div
                    className={`d-block cursor-pointer ${
                      isPopNextDisabled ? "disabled" : ""
                    }`}
                    onClick={() => {
                      popupSwiper.slideNext();
                      // mainSwiper.slidePrev();
                    }}
                  >
                    <div className="next-icon icon"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ImageSlider;
