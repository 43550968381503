//MemberCollection
import React, { useState, useContext } from "react";
import Pagination from "../../sharedComponents/Pagination";
import AuthContext from "../../Context/AuthContext";
import { Link } from "react-router-dom";
import Nodata from "../../sharedComponents/Nodata";
import axios from "axios";

function MemberCollection() {
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 12; //一頁有幾個

  const { myAuth, getHeartData, logout } = useContext(AuthContext);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const deletefavorite = async (prodId) => {
    const mid = parseInt(myAuth.sid);
    const pid = parseInt(prodId);
    const requestData = {
      mid: mid,
      pid: pid,
    };

    const storedToken = myAuth.token;

    axios
      .post(`/api/Member/DeleteFavorite`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          getHeartData(storedToken, mid);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  console.log("heartData", "myAuth.heartData");
  const displayedData =
    myAuth.heartData && myAuth.heartData.slice(startIndex, endIndex);
  console.log("displayedData", displayedData);
  return (
    <div className="member-like">
      <div className=" member-page-title">
        <h5>我的收藏</h5>
        <div className="member-like-count">
          <h6>收藏數量：</h6>
          <h6 className="heartamount">{myAuth.heartamount}</h6>
          <h6>/ 50（最多50筆）</h6>
        </div>
      </div>

      <div className="video-responsive"></div>

      {myAuth.heartamount > 0 ? (
        <>
          <div className="member-like-container">
            {displayedData &&
              displayedData.length > 0 &&
              displayedData.map((value, index) => (
                <div className="product-card" key={index}>
                  <Link to={`/product/${value.prodSlug}`}>
                    <div className="img-box">
                      <img
                        src={value.prodImage}
                        className="w-100"
                        alt={value.prodName}
                      />
                    </div>
                  </Link>
                  <div className="product-title">
                    <h6>{value.prodName}</h6>
                    <div
                      className="heart"
                      onClick={() => deletefavorite(value.pid)}
                    >
                      <div className={`heart-icon selected `}></div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          {displayedData && displayedData.length > 0 && (
            <div className="container px-md-0">
              <div className="pagination justify-content-center primary-deep">
                <Pagination
                  currentPage={currentPage} // 傳遞 currentPage
                  totalPages={Math.ceil(myAuth.heartamount / itemsPerPage)} // 計算 totalPages
                  onPageChange={setCurrentPage} // 傳遞頁數變化處理函式
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Nodata
          descript={"目前沒有收藏資料"}
          link={"../../collections"}
          linkDescript={"去逛逛"}
        />
      )}
    </div>
  );
}

export default MemberCollection;
