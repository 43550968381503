//MemberDataShipping
import { useContext, useState, useEffect } from "react";
import Loading from "../Loading";
import NamePhoneLabel from "../../sharedComponents/NamePhoneLabel";
import TWZipCode from "../../sharedComponents/TWZipCode";
import AuthContext from "../../Context/AuthContext";
import Nodata from "../../sharedComponents/Nodata";
import axios from "axios";
function MemberDataShipping() {
  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddressData] = useState([]);
  // const [addressData, setAddressData] = useState([
  //   {
  //     name: "顏儀靖",
  //     phone: "0920204896",
  //     telephone: "0245896341",
  //     postcode: "251",
  //     country: "新北市",
  //     township: "淡水區",
  //     address: "新興街96號",
  //   },
  //   {
  //     name: "宋靖",
  //     phone: "0925491396",
  //     telephone: "0224516341",
  //     postcode: "830",
  //     country: "高雄市",
  //     township: "鳳山區",
  //     address: "新興街96號",
  //   },
  // ]);
  const { myAuth, updateMemberData, logout } = useContext(AuthContext); //登入狀態
  const [postcode, setPostcode] = useState();

  const [editingAddressIndex, setEditingAddressIndex] = useState(-1); // 編輯狀態
  const [isAddingAddress, setIsAddingAddress] = useState(false); // 新增模式狀態
  //父層接收到的姓名電話手機
  const [reciptName, setReciptName] = useState("");
  const [reciptPhone, setReciptPhone] = useState("");
  const [reciptTele, setReciptTele] = useState("");
  //父層接收到的地址
  const [reciptePostcode, setReciptePostcode] = useState("");
  const [recipteCountry, setRecipteCountry] = useState("");
  const [recipteTownship, setRecipteTownship] = useState("");
  const [recipteAddress, setRecipteAddress] = useState("");
  //是否將地址設定為預設?
  const [isPreset, setIsPreset] = useState(false);
  const [hasPresetChange, setHasPresetChange] = useState(false);
  //發送表單的檢查欄位
  const [namePhoneValid, setNamePhoneValid] = useState(false);
  const [addressValid, setAddressValid] = useState(false);
  //會員資訊
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  const memberAddress = myAuth.recipientData;
  // const handleItemDataChange = (key, value) => {
  //   const updatedAddressData = [...addressData];
  //   updatedAddressData[selectedAddressIndex][key] = value;
  //   setAddressData(updatedAddressData);
  // };

  //還原回預設值
  const toOrigin = () => {
    setEditingAddressIndex(-1); // 編輯狀態
    setIsAddingAddress(false); // 新增模式狀態
    setIsPreset(false); //是否將地址設定為預設?
    setNamePhoneValid(false);
    setAddressValid(false);
  };
  //設定預設地址的值
  const handlePrevset = (datapreset, preset) => {
    const newPresetValue = !preset;
    setIsPreset(newPresetValue);

    if (datapreset !== newPresetValue) {
      setHasPresetChange(true);
    } else {
      setHasPresetChange(false);
    }
  };

  const handleEditClick = (index, preset) => {
    if (editingAddressIndex === index) {
      //原本為編輯狀態就切換回檢視狀態，將資料還原回預設值
      toOrigin();
    } else {
      //進入編輯狀態，要將新增狀態設定為false
      setEditingAddressIndex(index);
      setIsPreset(preset);
      setIsAddingAddress(false);
    }
  };
  const handleAddAddressClick = () => {
    // 切換新增模式狀態，並將Preset設定成預設值
    //如果原本的新增狀態為true就要將新增模式關閉，並將Preset設定成預設值
    if (isAddingAddress) {
      setIsPreset(false);
    } else {
      setEditingAddressIndex(-1);
    }
    //如果原本的新增狀態為false就要將新增模式開啟，並將EditingAddressIndex設定成預設值-1
    setIsAddingAddress(!isAddingAddress);
  };
  const handleEditData = (e, id) => {
    e.preventDefault();
    const presetno = isPreset ? 1 : 0;
    const requestData = {
      rid: id,
      mid: mid,
      preset: presetno, // 使用当前 isPreset 的值
      name: reciptName,
      mobile: reciptPhone,
      tel: reciptTele,
      addr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
    };

    axios
      .post("/api/Member/EditMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的EditMemberRecipients", "response.data");
        console.log("EditMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("EditMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤EditMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤EditMemberRecipients", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  const handleAddNewData = (e) => {
    e.preventDefault();
    const presetno = isPreset ? 1 : 0;
    const requestData = {
      mid: mid,
      preset: presetno, // 將目前的 isPreset 設定為true或是false
      name: reciptName,
      mobile: reciptPhone,
      tel: reciptTele,
      addr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
    };
    axios
      .post("/api/Member/AddMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的AddMemberRecipients", "response.data");
        console.log("AddMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("AddMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤AddMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤storedToken", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //刪除收件資料
  const handleDeleteData = (e, id) => {
    e.preventDefault();
    const requestData = {
      rid: id,
      mid: mid,
    };

    axios
      .post("/api/Member/DeleteMemberRecipients", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("回傳的DeleteMemberRecipients", "response.data");
        console.log("DeleteMemberRecipients傳過去的資料", "requestData");
        toOrigin();
        updateMemberData();
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("DeleteMemberRecipients有錯誤：", "error");
        console.log("傳過去的資料有錯誤DeleteMemberRecipients", "requestData");
        console.log("傳過去的資料有錯誤DeleteMemberRecipients", "storedToken");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //初次載入時取得會員地址資料
  useEffect(() => {
    console.log("memberAddress", "memberAddress");
    if (memberAddress) {
      const formattedAddresses = memberAddress.map((data) => {
        // Split the 'addr' string into parts
        const [postcode, country, township, addr] = data.addr.split("///");
        return {
          id: data.id,
          name: data.name,
          phone: data.mobile,
          telephone: data.tel,
          preset: data.preset,
          postcode,
          country,
          township,
          address: addr,
        };
      });
      console.log("formattedAddresses", "formattedAddresses");
      setAddressData(formattedAddresses);
    }
  }, []);
  //有改變時再次取得會員地址資料
  useEffect(() => {
    console.log("memberAddress", "memberAddress");
    if (memberAddress) {
      const formattedAddresses = memberAddress.map((data) => {
        // Split the 'addr' string into parts
        const [postcode, country, township, addr] = data.addr.split("///");
        return {
          id: data.id,
          name: data.name,
          phone: data.mobile,
          telephone: data.tel,
          preset: data.preset,
          postcode,
          country,
          township,
          address: addr,
        };
      });
      console.log("有改變時再次取得會員地址資料", "formattedAddresses");
      setAddressData(formattedAddresses);
      setIsLoading(false);
    }
  }, [myAuth]);
  //編輯狀態時先設定名稱等條件
  useEffect(() => {
    //預設值
    if (editingAddressIndex !== -1) {
      console.log("選擇的addressData", "addressData[editingAddressIndex].name");
      setReciptName(addressData[editingAddressIndex].name);
      setReciptPhone(addressData[editingAddressIndex].phone);
      setReciptTele(addressData[editingAddressIndex].telephone);
      setReciptePostcode(addressData[editingAddressIndex].postcode);
      setRecipteCountry(addressData[editingAddressIndex].country);
      setRecipteTownship(addressData[editingAddressIndex].township);
      setRecipteAddress(addressData[editingAddressIndex].address);
    }
  }, [editingAddressIndex]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [memberAddress]);

  if (isLoading) {
    <Loading />;
    return;
  }
  return (
    <div className="member-data-shipping">
      <div className="member-page-title">
        <h5>收件地址</h5>
      </div>

      <div className="history-shipping-section">
        {addressData && addressData.length === 0 && (
          <Nodata
            blockdescript={"目前沒有歷史收件地址"}
            blockmemo={"點擊下方按鈕新增地址"}
            icon={"dangernotice"}
            bgclass={"nobg"}
          />
        )}

        {addressData &&
          addressData.map((item, index) => (
            <div className="address-origin-data" key={index}>
              <div className="address-data ">
                <div key={index} className="data">
                  <div className="title-box">
                    <div className="adddress-first-info">
                      <h6 className="adddress-index">收件地址{index + 1}</h6>
                      {item.preset && (
                        <p className="preset-address">預設地址</p>
                      )}
                    </div>
                    <div className="address-edit-box">
                      <div
                        className={`btn ${
                          editingAddressIndex === index
                            ? "editing-btn"
                            : "edit-btn"
                        }`}
                        onClick={() => handleEditClick(index, item.preset)}
                      >
                        <h6>
                          {editingAddressIndex === index ? "取消編輯" : "編輯"}
                        </h6>
                      </div>
                      <div
                        className="btn delete-btn"
                        onClick={(e) => handleDeleteData(e, item.id)}
                      >
                        <h6>刪除</h6>
                      </div>
                    </div>
                  </div>

                  <div className="d-flex">
                    <p>
                      {item.name} {item.phone} {item.postcode}
                      {item.country}
                      {item.township}
                      {item.address}
                    </p>
                  </div>
                </div>
              </div>
              {editingAddressIndex === index && (
                <form
                  className="member-form"
                  onSubmit={(e) => handleEditData(e, item.id)}
                >
                  <NamePhoneLabel
                    itemName={item.name}
                    itemPhone={item.phone}
                    itemTelephone={item.telephone}
                    onNamePhoneChange={(name, phone, telephone) => {
                      setReciptName(name);
                      setReciptPhone(phone);
                      setReciptTele(telephone);
                    }}
                    isNamePhoneValid={(isValid) => {
                      setNamePhoneValid(isValid);
                    }}
                  />
                  <label htmlFor="username" className="form-label d-flex">
                    <h6 className="primary-deep2">收件地址</h6>
                    <div className="required-icon"></div>
                  </label>
                  <TWZipCode
                    initPostcode={item.postcode}
                    onPostcodeChange={(country, township, postcode) => {
                      setReciptePostcode(postcode);
                      setRecipteCountry(country);
                      setRecipteTownship(township);
                    }}
                    onAddressChange={(address) => {
                      setRecipteAddress(address);
                    }}
                    itemAddress={item.address}
                    isTWZipCodeValid={(isValid) => {
                      setAddressValid(isValid);
                    }}
                  />
                  <div className="form-label d-block">
                    <h6 className="primary-deep2">設定</h6>
                  </div>
                  <div
                    className="checkbox-button preset-section"
                    onClick={() => handlePrevset(item.preset, isPreset)}
                  >
                    <div
                      className={`checkbox-icon  ${
                        isPreset === true ? "checked" : "default"
                      }`}
                    ></div>
                    <h6 className="primary-deep2">設定為預設地址</h6>
                  </div>
                  <div className="pt-5 pb-3 border-bottom-light">
                    <button
                      className="btn primary-btn2 w-100"
                      disabled={
                        !namePhoneValid && !addressValid && !hasPresetChange
                      }
                    >
                      <h6 className="py-1">儲存變更</h6>
                    </button>
                  </div>
                </form>
              )}
            </div>
          ))}
      </div>
      <div className="d-flex flex-column gap-3">
        <div className=" add-address-data add-address-section w-100">
          <button
            className="btn address-add-btn"
            onClick={handleAddAddressClick}
          >
            {isAddingAddress ? (
              // cancel
              <div className="icon cancel-address-icon"></div>
            ) : (
              // plus
              <div className="icon add-address-icon"></div>
            )}
            <h6 className="py-1">
              {isAddingAddress ? "取消新增收件地址" : "新增收件地址"}
            </h6>
          </button>
        </div>
        {isAddingAddress && (
          <form className="member-form" onSubmit={handleAddNewData}>
            <NamePhoneLabel
              itemName=""
              itemPhone=""
              itemTelephone=""
              onNamePhoneChange={(name, phone, telephone) => {
                console.log("改變的子層", " name, phone, telephone");
                setReciptName(name);
                setReciptPhone(phone);
                setReciptTele(telephone);
              }}
              isNamePhoneValid={(isValid) => {
                console.log("父層接受到的格式", "isValid");
                setNamePhoneValid(isValid);
              }}
            />
            <label htmlFor="username" className="form-label d-flex">
              <h6 className="primary-deep2">收件地址</h6>
              <div className="required-icon"></div>
            </label>
            <TWZipCode
              initPostcode={postcode}
              onPostcodeChange={(country, township, postcode) => {
                setReciptePostcode(postcode);
                setRecipteCountry(country);
                setRecipteTownship(township);
              }}
              onAddressChange={(address) => {
                console.log("address", "address");
                setRecipteAddress(address);
              }}
              itemAddress=""
              isTWZipCodeValid={(isValid) => {
                console.log("父層接受到的地址格式", "isValid");
                setAddressValid(isValid);
              }}
            />
            <div className="form-label d-block">
              <h6 className="primary-deep2">設定</h6>
            </div>

            <div
              className="checkbox-button preset-section"
              onClick={() => handlePrevset(false, isPreset)}
            >
              <div
                className={`checkbox-icon  ${
                  isPreset === true ? "checked" : "default"
                }`}
              ></div>
              <h6 className="primary-deep2">設定為預設地址</h6>
            </div>

            <div className=" confirm-address-section ">
              <button
                className="btn primary-btn2 w-100"
                disabled={!namePhoneValid || !addressValid}
              >
                <h6 className="py-1">新增地址</h6>
              </button>
            </div>
            {(!namePhoneValid || !addressValid) && (
              <div
                className={`pt-2 ${
                  !namePhoneValid || !addressValid ? "d-block" : "d-none"
                }`}
              >
                <Nodata
                  icon={"dangernotice"}
                  dangerclass={"ps-1 p1 "}
                  dangerdescript={`尚未填寫${
                    reciptName
                      ? ""
                      : !reciptName && reciptPhone && addressValid
                      ? "姓名"
                      : "姓名、"
                  }
${reciptPhone ? "" : !reciptPhone && addressValid ? "手機" : "手機、"}
${addressValid ? "" : !addressValid ? "地址" : "地址、"}


`}
                  bgclass={"bg-white"}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </div>
  );
}

export default MemberDataShipping;
