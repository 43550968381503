import React, { useEffect, useState } from "react";
import Loading from "./Loading";
import HeroSlider from "./HeroSlider";
import RenderHtml from "../sharedComponents/RenderHtml";
import CollectionCard from "../sharedComponents/CollectionCard";
import NewsCard from "../sharedComponents/NewsCard";
import axios from "axios";
import Breadcrumbs from "./Breadcrumbs";

function Home() {
  const [apiBanner, setApiBanner] = useState(null);
  const [apiSection, setApiSection] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    axios
      .get(`api/Home/Banner`)
      .then((response) => {
        if (response.status === 200) {
          console.log("Banner"," response.data");
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiBanner(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching banner data from API:", "error");
      });
  }, []);

  useEffect(() => {
    axios
      .get(`api/Home/Section`)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        }
        return null;
      })
      .then((data) => {
        if (data) {
          setApiSection(data);
          console.log("setApiSection", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching section data from API:", "error");
      });
  }, []);

  
  // if (!apiBanner || !apiSection) {
  //   return (
  //     <div className="first-section">
  //       <Loading />
  //     </div>
  //   );
  // }

  return (
    <div className="index">
      <Breadcrumbs/>
      <HeroSlider img={apiBanner} currentIndex={currentIndex} />
      {/* 嵌入的影片加上iframe，注意要是embed才不會有安全策略問題 */}

      {apiSection && apiSection.map((item, index) => {
        if (item.sectionType === "a") {
          return (
            <div  key={index}>
              <RenderHtml content={item.content} />
            </div>
          );
        } else if (item.code === "collection") {
          return (
            <div className="index-category" key={`collection-${index}`}>
              <div className="category-content">

                <div className="category-row">
                  {item.content &&
                    item.content.map((value, valueIndex) => (
                      <div
                        key={`collection-${index}-${valueIndex}`}
                        className="collection-card "
                      >
                        <CollectionCard content={value} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          );
        } else if (item.code === "blog") {
          return (
            <div className="index-news " key={`blog-${index}`}>
              <div className="news-content">
                <div className="news-row">
                {item.content &&
                  item.content.map((blogData, index) => (
                    
                    <NewsCard
                    key={index}
                    blogTitle={blogData.title}
                    blogDescription={blogData.content}
                    buttonContent={blogData.buttonContent}
                    blogDate={blogData.blogDate && blogData.blogDate}
                    imageUrl={blogData.image}
                    blogSlug={blogData.blogSlug}
                    blogCateSlug={blogData.blogcateSlug}
                    buttonLink={blogData.buttonLink}
                  />
                  ))}
                </div>
                
              </div>
            </div>
          );
        } else {
          return <Loading/>;
        }
      
      })}
    </div>
  );
}

export default Home;
