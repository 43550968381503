import React, { useEffect, useRef, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import OnePageNavMenu from "./OnePageNavMenu";
import OnePageImg from "./OnePageImg";
import OnePageCollection from "./OnePageCollection";
import OnePageCart from "./OnePageCart";
import OnePageCheckout from "./OnePageCheckout";
import OnePageSideButton from "./OnePageSideButton";
import NavMenu from "../NavMenu";
import Footer from "../Footer";
import Popup from "../../sharedComponents/Popup";
import Nodata from "../../sharedComponents/Nodata";

function OnePage() {
  const [apiData, setApiData] = useState(null);
  const [prodData, setProdData] = useState(null);
  const [noPage, setNoPage] = useState(false);
  //購物車資料
  const [cartData, setCartData] = useState([]);
  const navigate = useNavigate();
  //來自URL的slug
  const { onepageSlug, onepagePreviewSlug } = useParams();
  // console.log("onepageSlug", onepageSlug);
  //更改metadata
  const [metaData, setMetaData] = useState({
    description: "",
    storeName: "",
    ogUrl: "",
    urltitle: "",
    favicon: "",
  });

  //取得過濾後的資料
  const fetchData = () => {
    let requestData = {};
    if (onepagePreviewSlug) {
      requestData = { OnepageSlug: onepagePreviewSlug, preview: true };
    } else if (onepageSlug) {
      requestData = { OnepageSlug: onepageSlug, preview: false };
    } else {
      console.error("Neither onepageSlug nor onepagePreviewSlug is available");
      return;
    }
    console.log("onepageSlug", onepageSlug);
    console.log("onepagePreviewSlug", onepagePreviewSlug);
    axios
      .post("api/Onepage/OnepageData", requestData)
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setApiData(response.data);
        // setProducts(response.data.prodData);
        //  setPcCoverImg(response.data.activityImg);
        // setPcCoverImg(null);
        // setMbCoverImg(response.data.activityImg_m);
        // setIsLoading(false);
        setMetaData({
          description: response.data.metadesc || "",
        });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        // Log a message for when products are not found
        setNoPage(true);
        console.log("requestData", "requestData");
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      });
  };
  const getProdData = (slug) => {
    const requestData = { OnepageSlug: slug };
    axios
      .post("api/Onepage/GetOnepageProd", requestData)
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setProdData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        console.log("requestData", "requestData");
        setNoPage(true);
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      });
  };
  const cartRef = useRef(null);
  const scrollToCart = () => {
    cartRef.current.scrollIntoView();
  };

  useEffect(() => {
    fetchData();
  }, []);
  //metadata
  useEffect(() => {
    if(metaData && metaData.description){
      const metaDescript = document.querySelector('meta[name="description"]');
      if (metaDescript) {
        // 確認找到 meta 標籤後再設定
        metaDescript.setAttribute("content", metaData.description);
      }
      
    }

  }, [metaData]);
  //使用回傳的apiData.slug搜尋商品清單
  useEffect(() => {
    if (apiData && apiData.slug) {
      getProdData(apiData.slug);
    }
  }, [apiData]);

  return (
    <>
      {noPage && !apiData && (
        <div className="onepage">
          <div className="container-fluid px-0">
            <Popup
              onNevigate={() => {
                setNoPage(false);
                navigate("/");
              }}
              onClose={() => {
                setNoPage(false);
              }}
              icon={"notice"}
              title={"活動不存在或已結束"}
              orangebtn={"回到首頁"}
              greenbtn={""}
            />
          </div>
        </div>
      )}

      {apiData && (
        <div className="onepage">
          <NavMenu />
          {onepageSlug && <OnePageNavMenu endTime={apiData.endTime} />}
          {onepagePreviewSlug && (
            <div className="timer-container d-flex justify-content-center align-items-center gap-2 py-2 px-2 info-light-bg">
              <h5 className="time-title danger">一頁式商店預覽</h5>
            </div>
          )}
           
          <OnePageSideButton handleCartPopup={scrollToCart} />
          <OnePageImg bannerImg={apiData.banner} />
          <div className="h-100vh">
            <div className="container px-0">
              

              <OnePageCollection title={apiData.title} prodData={prodData} />
              <div ref={cartRef} className="container-fluid checkout-container">
                <div>
                  <div className="section-title">
                    <div className="title">
                      <h5>選購商品清單</h5>
                    </div>
                  </div>
                  <OnePageCart
                    onCartChange={(cartData) => {
                      console.log("父層的cartData", cartData);
                      setCartData(cartData);
                    }}
                  />
                </div>
                <div>
                  <div className="section-title">
                    <div className="title">
                      <h5>訂購資料填寫</h5>
                    </div>
                  </div>
                  {onepageSlug && (
                    <OnePageCheckout
                      cartDataUpdate={cartData}
                      onepageSlug={onepageSlug}
                    />
                  )}
                   {onepagePreviewSlug && (
                    <OnePageCheckout
                      cartDataUpdate={cartData}
                      onepagePreviewSlug={onepagePreviewSlug}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
<Footer/>
        </div>
      )}
    </>
  );
}

export default OnePage;
