import { useContext, useState, useEffect } from "react";
import axios from "axios";

import Nodata from "./Nodata";
import AuthContext from "../Context/AuthContext";

function CouponPopup({
  closePopup,
  cartPrice,
  initCouponselect,
  onCouponChange,
  discountAvalible,
}) {
  const { myAuth, logout } = useContext(AuthContext); //登入狀態

  const [ecouponData, setEcouponData] = useState();
  const [pointData, setPointData] = useState("");

  //避免選項共用狀態
  const [selectedPromoCode, setSelectedPromoCode] = useState(""); // For promo code
  const [selectedEcoupon, setSelectedEcoupon] = useState(""); // For ecoupon
  const [selectedPoint, setSelectedPoint] = useState(""); // For member points
  //選擇的pointdata
  const [selectedPointData, setSelectedPointData] = useState({});

  //選擇的ecouponData
  const [Ecouponinfo, setEcouponinfo] = useState("");
  const [selectedEcouponData, setSelectedEcouponData] = useState({});
  //選擇的promocode(如果可以才會有資料)
  const [selectedPromoData, setSelectedPromoData] = useState({});
  //輸入的promocode
  const [inputpromocode, setInputPromoCode] = useState("");

  const [promoinfo, setPromoinfo] = useState("");
  const [promostate, setPromostate] = useState(false);
  const [promoerrorInfo, setPromoerrorInfo] = useState("");
  // 選擇優惠的名稱
  const [discountName, setDiscountName] = useState(null);
  // 選擇優惠的金額
  const [discountPrice, setDiscountPrice] = useState(0);
  const [giftData, setGiftData] = useState({});
  //選擇使用優惠
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  // 下拉選單
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDrop = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleEcouponSelect = (selectedOption) => {
    setSelectedEcoupon(selectedOption.ecouponNo);
    setSelectedEcouponData({
      ecouponNo: selectedOption.ecouponNo,
    });
    const discountName = `${selectedOption.title}(可折抵${
      selectedOption.ecouponPrice
    }元，到期時間: ${new Date(selectedOption.newedate).toLocaleDateString()})`;
    setDiscountName(discountName);
    setDiscountPrice(selectedOption.ecouponPrice);
    setIsDropdownOpen(false); // 选择后关闭下拉菜单
  };

  const couponoptions = [
    { label: "優惠代碼", value: "discountcode" },
    { label: "折價券", value: "ecoupon" },
    { label: "紅利點數", value: "memberpoint" },
  ];
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  //選擇的折價

  const handleOptionChange = (index) => {
    if (index === selectedOptionIndex) {
      return;
    }

    setSelectedOptionIndex(index);
    setInputPromoCode("");
    setPromoinfo("");
    setPromostate(false);
    setPromoerrorInfo("");
    setSelectedEcouponData({});
    setSelectedPointData({});
    setSelectedPromoData({});
    setSelectedPromoCode(""); // Reset the selected promo code
    setSelectedEcoupon(""); // Reset the selected ecoupon
    setSelectedPoint(""); // Reset the selected member points
    console.log("改變了selectedOptionIndex", "index");
  };
  //使用者輸入完，觸發promoBlur再去檢查是否可使用
  const promoBlur = () => {
    if (selectedPromoCode) {
      promoteCodeCheck(selectedPromoCode);
    }
  };
  //判斷優惠代碼是否可以使用
  const promoteCodeCheck = (input) => {
    const requestData = { mid: mid, promocode: input };
    // console.log("storedToken", storedToken);
    axios
      .post("/api/Order/CkPromoCode", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得折扣碼資訊", "response.data");
        console.log("發送取得折扣碼資訊的請求", "requestData");
        setPromostate(true);
        setPromoinfo(response.data);
        setDiscountName(response.data.promoTitle);
        setPromoerrorInfo("");
        setSelectedPromoData({ promoCode: response.data.promoCode });
        if (response.data.promoPrice) {
          const discountAmount = response.data.promoPrice;
          setDiscountPrice(discountAmount);
          console.log("setDiscountPrice");
        } else if (response.data.promoDiscount) {
          const promoDiscount = parseFloat(response.data.promoDiscount);
          const totalPrice = parseFloat(response.data.totalItemsPrice);

          // Calculate the discounted price
          const discountedPrice = totalPrice * (1 - promoDiscount / 100);

          // Calculate the discount amount
          const discountAmount = Math.round(totalPrice - discountedPrice);

          setDiscountPrice(discountAmount);
        }
      })
      .catch((error) => {
        console.error("取得折扣碼資訊回傳有錯誤：", "error");
        console.log("發送取得折扣碼資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }

        setPromostate(false);
        setPromoerrorInfo("此為無效代碼");
      });
  };
  //取得折價券資料
  const getEcouponData = () => {
    const requestData = { mid: mid };
    axios
      .post("/api/Order/GetEcoupon", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得折價券資訊", "response.data");
        console.log("發送取得折價券資訊的請求", "requestData");
        setEcouponData(response.data);

        // setDiscountAvalible(response.data);
      })
      .catch((error) => {
        console.error("取得折價券資訊回傳有錯誤：", "error");
        console.log("發送取得折價券資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //取得紅利點數
  const getMemberPoint = () => {
    const requestData = { mid: mid };
    axios
      .post("/api/Member/GetMemberPoint", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("取得紅利點數資訊", "response.data");
        console.log("發送取得紅利點數資訊的請求", "requestData");

        // setPointData(response.data.bonusData)
        // setEcouponData(response.data[0]);
        // setDiscountAvalible(response.data);
        const realBonus = response.data.realBonus;
        const monthLimitBonus = response.data.monthLimitBonus;

        // Calculate avaliblePoint based on conditions
        let avaliblePoint;

        if (realBonus > monthLimitBonus) {
          avaliblePoint = monthLimitBonus;
        } else if (realBonus < monthLimitBonus) {
          avaliblePoint = realBonus;
        } else {
          // Handle the case when they are equal, choose one or set a default value
          avaliblePoint = realBonus; // You can set it to monthLimitBonus if you prefer
        }
        const avaliblePointData = Array.from(
          { length: avaliblePoint / 100 },
          (_, index) => (index + 1) * 100
        );
        setPointData(avaliblePointData);
      })
      .catch((error) => {
        console.error("取得紅利點數資訊回傳有錯誤：", "error");
        console.log("發送取得紅利點數資訊的請求有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  // 確定優惠選擇
  const confirmSelect = () => {
    if (discountName) {
      if (selectedOptionIndex === 0 && selectedPromoData) {
        onCouponChange(selectedOptionIndex, [
          selectedPromoData,
          "優惠代碼",
          discountName,
          discountPrice,
        ]);
        console.log("要傳回父層的優惠代碼promoCode", "selectedPromoData");
      }
      if (selectedOptionIndex === 1 && selectedEcouponData) {
        onCouponChange(selectedOptionIndex, [
          selectedEcouponData,
          "折價券",
          discountName,
          discountPrice,
        ]);
        console.log("要傳回父層的折價券", "selectedEcouponData");
      }
      if (selectedOptionIndex === 2 && selectedPointData) {
        console.log("要傳回父層的紅利點數", "selectedPointData");
        onCouponChange(selectedOptionIndex, [
          selectedPointData,
          "紅利點數",
          discountName,
          discountPrice,
        ]);
      }
      closePopup();
    } else {
      closePopup();
    }
  };

  //如果可以使用折價，再去取得優惠資訊
  useEffect(() => {
    if (discountAvalible) {
      getEcouponData();
      console.log("getEcouponData");
      console.log("cartPrice", "cartPrice");
      if (cartPrice > 2000) {
        getMemberPoint();
      } else {
        console.log("沒有大於2000");
        // setEcouponinfo("")
      }
    }
  }, [myAuth, discountAvalible, cartPrice]);

  // useEffect(() => {
  //   //將選擇的index和資訊回傳給父層
  //   if (initCouponselect === null || selectedOptionIndex !== initCouponselect) {
  //     if (selectedOptionIndex === 0) {
  //       onCouponChange(selectedOptionIndex, selectedPromoData);
  //       console.log("要傳回父層的優惠代碼promoCode", "selectedPromoData");
  //     }
  //     if (selectedOptionIndex === 1) {
  //       onCouponChange(selectedOptionIndex, selectedEcouponData);
  //       console.log("要傳回父層的折價券", "selectedEcouponData");
  //     }
  //     if (selectedOptionIndex === 2) {
  //       console.log("要傳回父層的紅利點數", "selectedPointData");
  //       onCouponChange(selectedOptionIndex, selectedPointData);
  //     }
  //   }
  // }, [
  //   selectedOptionIndex,
  //   selectedEcouponData,
  //   selectedPointData,
  //   selectedPromoData,
  // ]);

  return (
    <div className="coupon-pop-bg" onClick={closePopup}>
      <div className="pop-continer " onClick={(e) => e.stopPropagation()}>
        <div className="cart-title">
          <h4>優惠方式</h4>
        </div>

        <div className="content-section">
          <div className="pop-content">
            {couponoptions &&
              couponoptions.map((item, index) => (
                <div
                  className={`${
                    (index === 1 &&
                      (!ecouponData || ecouponData.length === 0)) ||
                    (index === 2 && pointData.length === 0)
                      ? "disabled"
                      : "cursor-pointer"
                  } coupon-select-title coupon-index-${index}`}
                  onClick={() => {
                    if (
                      !(index === 1 && (!ecouponData || ecouponData.length === 0)) &&
                      !(index === 2 && pointData.length === 0)
                    ) {
                      handleOptionChange(index);
                      setIsDropdownOpen(false);
                    }
                  }}
                  key={index}
                >
                  <div
                    className={`select-item ${
                      selectedOptionIndex === index
                        ? "title-selected"
                        : "title-unselected"
                    }`}
                  >
                    <div className="btn checkbox-button ">
                      <div
                        className={` ${
                          selectedOptionIndex === index
                            ? "checked"
                            : "checkbox-icon"
                        }`}
                      ></div>
                    </div>
                    <h6 className="primary-deep2 ">
                      {/* {skuProcessing[processingKey].processingName} */}
                      {item.label}
                    </h6>
                  </div>
                </div>
              ))}
            {couponoptions &&
              couponoptions.map((item, index) => (
                <div
                  className={`coupon-item coupon-index-${index} ${
                    selectedOptionIndex === index
                      ? "show-detail"
                      : "notshow-detail"
                  }`}
                  key={index}
                >
                  {/* 優惠代碼的顯示 */}
                  {selectedOptionIndex === 0 && selectedOptionIndex === index && (
                    <div className="coupon-box">
                      <div className="d-flex gap-1">
                        <input
                          type="text"
                          id="membercode"
                          name="membercode"
                          className="form-input"
                          pattern=".{3,}"
                          placeholder="請輸入優惠代碼"
                          value={selectedPromoCode} // Use the selectedPromoCode state
                          onChange={(e) => {
                            setSelectedPromoCode(e.target.value);
                            setPromoerrorInfo("");
                            setPromoinfo("");
                          }}
                        />
                        <div className="primary-btn" onClick={promoBlur}>
                          <h6>確認輸入</h6>
                        </div>
                      </div>
                      {selectedPromoCode && promostate && promoinfo && (
                        <p className="pt-1 primary-deep3">
                          {promoinfo.promoTitle}
                        </p>
                      )}
                      {promoerrorInfo && (
                        <p className="pt-1  danger">{promoerrorInfo}</p>
                      )}
                    </div>
                  )}
                  {/* 折價券的顯示 */}
                  {selectedOptionIndex === 1 &&
                    selectedOptionIndex === index &&
                    ecouponData &&
                    ecouponData.length !== 0 && (
                      <div className="coupon-box">
                        <div
                          className="coupon-select-container"
                          onClick={toggleDrop}
                        >
                          <div
                            className={`form-select ${
                              isDropdownOpen ? "expand-form" : "close-form"
                            }`}
                          >
                            {selectedEcoupon
                              ? ecouponData.find(
                                  (item) => item.ecouponNo === selectedEcoupon
                                ).title
                              : "--請選擇折價券--"}
                          </div>
                          <div
                            className={`coupon-select-options ${
                              isDropdownOpen ? "show-detail" : "notshow-detail"
                            }`}
                          >
                            {ecouponData.length > 0 ? (
                              ecouponData.map((item, index) => (
                                <div
                                  key={index}
                                  className="form-select-option"
                                  onClick={() => handleEcouponSelect(item)}
                                >
                                  {item.title}(可折抵{item.ecouponPrice}
                                  元，到期時間:{" "}
                                  {new Date(item.newedate).toLocaleDateString()}
                                  )
                                </div>
                              ))
                            ) : (
                              <div className="form-select-option disabled">
                                無可用的折價券
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  {/* 紅利點數的顯示 */}
                  {selectedOptionIndex === 2 &&
                    selectedOptionIndex === index &&
                    pointData.length !== 0 && (
                      <div className="coupon-box">
                        <div
                          className="coupon-select-container"
                          onClick={toggleDrop}
                        >
                          <div
                            className={`form-select ${
                              isDropdownOpen ? "expand-form" : "close-form"
                            }`}
                          >
                            {selectedPoint
                              ? `紅利${selectedPoint}點 折抵: ${selectedPoint}元`
                              : "--請選擇紅利點數--"}
                          </div>
                          <div
                            className={`coupon-select-options ${
                              isDropdownOpen ? "show-detail" : "notshow-detail"
                            }`}
                          >
                            {pointData.length > 0 ? (
                              pointData.map((item, index) => (
                                <div
                                  key={index}
                                  className="form-select-option"
                                  onClick={() => {
                                    setSelectedPoint(item); // Update selectedPoint state
                                    setSelectedPointData({
                                      memberUsePoint: item,
                                    });
                                    const discountName = `紅利${item}點 折抵: ${item}元`;
                                    setDiscountName(discountName);
                                    setDiscountPrice(item);
                                    toggleDrop(); // Close dropdown after selection
                                  }}
                                >
                                  紅利{item}點 折抵: {item}元
                                </div>
                              ))
                            ) : (
                              <div className="form-select-option disabled">
                                無可用的紅利點數
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              ))}
          </div>
        </div>
        <div className="btn-section">
          <div className="cancel-btn btn" onClick={closePopup}>
            <h6>取消</h6>
          </div>
          <div
            className="confirm-btn  btn"
            onClick={() => {
              confirmSelect();
            }}
          >
            <h6>確認選擇</h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CouponPopup;
