import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Loading from "../Loading";
import RenderHtml from "../../sharedComponents/RenderHtml";
import { Link } from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
function CheckoutStep4() {
  const [isLoading, setIsLoading] = useState(true);
  const [redirect, setIsredirect] = useState(false);
  const [orderData, setOrderData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const location = useLocation();
  //會員資料
  const { myAuth, logout } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;
  //自訂義後端redirect
  ///Login?state=F&redirected=true
  const queryParams = new URLSearchParams(window.location.search);
  //https://localhost:44420/finishorder?state=2023110300001
  const stateValue = queryParams.get("state");
  const navigate = useNavigate();
  const hasState = location.state || stateValue;
  console.log("hasState", "hasState");
  localStorage.removeItem("stepTwoStoreData");
  localStorage.removeItem("promoData");
  const getOrderData = () => {
    const requestData = { mid: mid, orderno: hasState };
    axios
      .post("/api/Order/finishOrder", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("發送Order/ToOrder的回應", "response.data");
        console.log("發送Order/ToOrder的回應", "response.data.paymentId");
        console.log("發送Order/ToOrder資訊:", "requestData");
        setOrderData(response.data.orderQuery);
        setStoreData(response.data.storeData);
      })
      .catch((error) => {
        console.error("發送Order/ToOrder的回應有錯誤：", "error");
        console.log("發送Order/ToOrder資訊有錯誤", "requestData");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      });
  };
  //初次載入先loading
  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!hasState) {
      // navigate("/");
    }
    if (hasState && mid && storedToken) {
      console.log("hasState && mid && storedToken");
      getOrderData();
    }
    if (queryParams.get("redirected") === "true") {
      setIsredirect(true);
    }
    console.log("hasState", "hasState");
    setIsLoading(true);
  }, [hasState, mid, storedToken]);

  // console.log("orderData", orderData);
  useEffect(() => {
    if (orderData) {
      setIsLoading(false);
      console.log("orderData", "orderData");
    }
  }, [orderData]);
  if (isLoading || !orderData) {
    <Loading />;
  }
  // if (isLoading || !orderData) {
  //   return<Loading />;
  // }
  return (
    <div className="page-background checkout-background">
      <div className="share-page checkout-page">
        <Breadcrumbs />
        <div className="checkout-step">
          <div className="step step1 ">
            <div className="circle">
              <p>1</p>
            </div>
            <div className="step-descript">
              <p>填寫資料</p>
            </div>
          </div>
          <div className="step step2">
            <div className="circle">
              <p>2</p>
            </div>
            <div className="step-descript">
              <p>付款和運送方式</p>
            </div>
          </div>
          <div className="step step3">
            <div className="circle">
              <p>3</p>
            </div>
            <div className="step-descript">
              <p>最後確認</p>
            </div>
          </div>
          <div className="step step4 selected">
            <div className="circle">
              <p>4</p>
            </div>
            <div className="step-descript">
              <p>購物完成</p>
            </div>
          </div>
        </div>
        {orderData && (
          <div className=" checkout4 ">
            <div className="cart">
              <div className="cart-container">
                <div className="order-check">
                  <div className="success-icon icon"></div>
                  <h6 className="primary-deep">感謝您的訂購，訂單已成立！</h6>
                </div>
                <div className="top-container ">
                  <div className="cart-title">
                    <h4 className="title">訂單編號：</h4>
                    <p>{orderData.orderNo}</p>
                  </div>
                  <div className="page-content">
                    <div className="order-detail">
                      <div className="content">
                        <div className="title">
                          <p>付款方式：</p>
                        </div>
                        <div className="descript-container">
                          <p>{orderData.paymentMethod}</p>
                          {/* <p>{orderData.paymentId}</p> */}
                          {/* {orderData.paymentId === "1"  &&(<p>信用卡付款</p>)}
                        {orderData.paymentId === "2"  &&(<p>銀行轉帳付款</p>)}
                        {orderData.paymentId === "3" &&(<p>貨到付款</p>)} */}
                        </div>
                      </div>
                    </div>

                    {orderData.paymentId === "2" && storeData ? (
                      <RenderHtml content={storeData.bankAccount} />
                    ) : (
                      <></>
                    )}

                    <div className="order-detail">
                      <div className="content">
                        <div className="title">
                          <p>總金額：</p>
                        </div>
                        <div className="descript-container">
                          <p>${orderData.orderPrice.toLocaleString()}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="popup-footer">
                <div className="footer-bottom ">
                  {orderData.paymentId === "2" && storeData && (
                    <>
                      <p className="danger">
                        ※請於三天內繳款，否則訂單將取消。
                      </p>
                      <p className="primary-deep2">轉帳完成後：</p>
                      <p>
                        透過「會員專區」{">"}
                        「訂單資料」的「回傳匯款資料」功能將「匯款日期、轉帳帳號後5碼」回傳至本公司，以便確認付款資訊，如有任何問題歡迎來電洽詢。
                      </p>
                    </>
                  )}
                  <div className="d-flex">
                    <p>服務電話：</p>
                    <p>{storeData.contactTel}</p>
                  </div>
                  <div className="d-flex">
                    <p>電子信箱：</p>
                    <p>{storeData.contactEmail}</p>
                  </div>
                </div>
              </div>
              <div className="checkout-page">
                <div className="bottom-info">
                  <div className="btn-section ">
                    <Link to="/" className="btn primary-btn-big3">
                      <h6>返回首頁</h6>
                    </Link>
                    <Link
                      to="/member/order"
                      className="btn primary-btn-big2  "
                    >
                      <h6>查詢訂單</h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <h1>state:</h1>
      <h2>{hasState}</h2>
      <h2>{redirect ? "後端拜訪" : "前端拜訪"}</h2>
      <h2>{hasState ? "有接到state資料" : "沒接到state資料"}</h2> */}
    </div>
  );
}

export default CheckoutStep4;
