//MemberDataInvoice
import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../../Context/AuthContext";
import Popup from "../../sharedComponents/Popup";
import InvoiceSelect from "../../sharedComponents/InvoiceSelect";

function MemberDataInvoice() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  //發票類型設定
  const [invoiceType, setInvoiceType] = useState("2");

  //發票是否合法
  const [isInvoiceValid, setIsInvoiceValid] = useState(false);

  const [hasInvoiceChanged, setHasInvoiceChanged] = useState(false);

  //三聯式的資料
  const [businessNumber, setBusinessNumber] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  // 會員相關資料
  const { myAuth, updateMemberData, logout } = useContext(AuthContext);
  const mid = parseInt(myAuth.sid);
  const storedToken = myAuth.token;

  //若為二聯式，則按鈕狀態為disabled

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isInvoiceValid) {
      let requestData = {
        mid: mid,
        InvoiceType: invoiceType,
      };

      if (invoiceType === "3") {
        requestData.InvoiceTitle = invoiceTitle;
        requestData.InvoiceNo = businessNumber;
      }

      axios
        .post(`/api/Member/EditMemberData`, requestData, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("EditMemberData request:", "requestData");
            console.log("EditMemberData Data:", "response.data");
            setIsPopupOpen(true);
            updateMemberData();
            setHasInvoiceChanged(false);
          } else {
            console.log("EditMemberData Request failed:", "response");
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          if (error.response && error.response.status === 401) {
            // 如果是 401，觸發 logout()
            logout();
          }
        });
    }
  };

  useEffect(() => {
    console.log("invoiceType",invoiceType)
    console.log("myAuth.invoiceType",myAuth.invoiceType)
    if (invoiceType === "2" && myAuth.invoiceType !== "2") {
      setHasInvoiceChanged(true);
      console.log("setHasInvoiceChanged")
    }else if(invoiceType === "2" && myAuth.invoiceType === "2"){
      setHasInvoiceChanged(false);
       console.log("setHasInvoiceChanged")
    } else if (
      invoiceType === "3" &&
      myAuth.invoiceType !== "3" ||
      (myAuth.invoiceTitle !== invoiceTitle ||
      myAuth.businessNo !== businessNumber)
    ) {
      setHasInvoiceChanged(true);
       console.log("setHasInvoiceChanged")
    }else{
      setHasInvoiceChanged(false);
       console.log("setHasInvoiceChanged")
    }
  }, [invoiceType, invoiceTitle, businessNumber]);
  useEffect(() => {
    console.log("hasInvoiceChanged", hasInvoiceChanged);
  }, [hasInvoiceChanged]);

  return (
    <div className="member-data-invoice">
      {isPopupOpen && (
        <Popup
          onNevigate={() => {
            setIsPopupOpen(false);
            setHasInvoiceChanged(false);

          }}
          onClose={() => {
            setIsPopupOpen(false);
          }}
          icon={"check"}
          title={"已修改成功"}
          orangebtn={"確認"}
          greenbtn={""}
        />
      )}
      <div className="member-page-title">
        <h5>發票資料</h5>
      </div>
      <div>
        <form className="member-form" onSubmit={handleSubmit}>
          <InvoiceSelect
            onInvoiceChange={(
              newInvoiceType,
              newBusinessNumber,
              newInvoiceTitle
            ) => {
              setInvoiceType(newInvoiceType);
              console.log("newInvoiceType", "newInvoiceType");
              // if (myAuth.invoiceType === "3" && newInvoiceType === "2") {
              //   setHasInvoiceChanged(true);
              // }
              if (newInvoiceType === "3") {
                console.log("newBusinessNumber", "newBusinessNumber");
                console.log("newInvoiceTitle", "newInvoiceTitle");
                setBusinessNumber(newBusinessNumber);
                setInvoiceTitle(newInvoiceTitle);
                // if (
                //   myAuth.invoiceType !== "2" &&
                //   (myAuth.invoiceTitle !== newInvoiceTitle ||
                //     myAuth.businessNo !== newBusinessNumber)
                // ) {
                //   setHasInvoiceChanged(true);
                // }
              }
            }}
            isInvoiceValid={(isValid) => {
              console.log("父層接受到的格式", isValid);
              setIsInvoiceValid(isValid);
            }}
            errornone={true}
            initInvoiceType={myAuth.invoiceType}
          />

          <div className="member-form-btn">
            <button
              className="btn member-save-btn"
              disabled={!isInvoiceValid || !hasInvoiceChanged}
            >
              <h6 className="py-1">儲存發票資料</h6>
            </button>
          </div>
        </form>
        <div
          className={`d-flex pb-0 ${
            hasInvoiceChanged ? "visible" : "invisible"
          }`}
          id="error-username"
        >
          <p className="ps-1 secondary-deep d-none d-xxl-block">
            請記得點擊右側的按鈕儲存變更
          </p>
          <p className="ps-1 secondary-deep d-block d-xxl-none">
            請記得點擊下方的按鈕儲存變更
          </p>
        </div>
      </div>
    </div>
  );
}

export default MemberDataInvoice;
