// OnePageCollection.js
import React, { useState, useMemo } from "react";
import OnePageCard from "./OnePageCard.js";
import OnePageProdDetail from "./OnePageProdDetail";
import Loading from "../Loading";

function OnePageCollection({ title, prodData }) {

  const [selectedprodId, setSelectedprodId] = useState(null);

  const openProdDetail = (prodId) => {
    setSelectedprodId(prodId);
    console.log("prodId","prodId")
  };

  const memoizedProdData = useMemo(() => prodData || [], [prodData]);

  if (!memoizedProdData) {
    return <Loading />;
  }

  return (
    <>
      {selectedprodId && (
  memoizedProdData
    .filter((info) => info.prodId === selectedprodId)
    .map((info) => (
      <OnePageProdDetail
        key={info.prodId}
        prodId={selectedprodId}
        onClose={() => setSelectedprodId(null)}
        prodImg={info.prodImage}
      />
    ))
)}

      <div className="container py-2">
        <h3 className="onepage-title">{title ? (title):("限時優惠商品")}</h3>
      </div>
      <div className="container d-flex gap-10px flex-wrap">
        {memoizedProdData.map((info) => (
          <OnePageCard
            key={info.prodId}
            info={info}
            onCardClick={openProdDetail}
          />
        ))}
      </div>
    </>
  );
}

export default OnePageCollection;
