import React from "react";

function Pagination({ currentPage, totalPages, onPageChange }) {
  const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }
    if (totalPages <= 7) {
      return pageNumbers.map((pageNum) => (
        <div
          key={pageNum}
          onClick={() => onPageChange(pageNum)}
          className={currentPage === pageNum ? "selected" : "cursor-pointer"}
        >
          <h6>{pageNum}</h6>
        </div>
      ));
    } else {
      const pageElements = [];
      const visiblePages = [1, totalPages - 1, totalPages];

      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === 2 || i === totalPages || i === totalPages - 1) {
          pageElements.push(
            <div
              key={i}
              onClick={() => onPageChange(i)}
              className={currentPage === i ? "selected" : "cursor-pointer"}
            >
              <h6>{i}</h6>
            </div>
          );
        } else if (
          (i >= currentPage - 2 && i <= currentPage + 2) ||
          visiblePages.includes(i)
        ) {
          pageElements.push(
            <div
              key={i}
              onClick={() => onPageChange(i)}
              className={currentPage === i ? "selected" : "cursor-pointer"}
            >
              <h6>{i}</h6>
            </div>
          );
        } else if (
          (i === currentPage - 3 && !visiblePages.includes(currentPage - 2)) ||
          (i === currentPage + 3 && !visiblePages.includes(currentPage + 2))
        ) {
          pageElements.push(
            <div key={i} className="dots">
              <h6>...</h6>
            </div>
          );
        }
      }
      return pageElements;
    }
  };

  const isPreviousDisabled = currentPage === 1;
  const isNextDisabled = currentPage === totalPages;

  if(totalPages > 1)

  return (
    <div className=" pagination-section ">
      <div
        onClick={() => {
          if (!isPreviousDisabled) {
            onPageChange(currentPage - 1);
          }
        }}
        className={`icon-section ${isPreviousDisabled ? "icon-disabled" : "default"} `}
      >
        <div className="icon prev-icon"></div>
      </div>
      <div className="d-flex flex-wrap justify-content-center">
        {renderPageNumbers()}
      </div>

      <div
        onClick={() => {
          if (!isNextDisabled) {
            onPageChange(currentPage + 1);
          }
        }}
        className={`icon-section ${isNextDisabled ? "icon-disabled" : "default"}`}
      >
        <div className="icon next-icon"></div>
      </div>
    </div>
  );
}

export default Pagination;
