//PopupGreen.js
import React, { useEffect } from "react";
import RenderHtml from "./RenderHtml"
function PopupGreen({ onNevigate, onClose, option, icon, title, message, msgcl, bigmsgcl, orangebtn, greenbtn, htmlMsg, bigmessage }) {
  let iconElement = null;
  useEffect(()=>{
    console.log("htmlMsg","htmlMsg")
  },[htmlMsg])
  useEffect(()=>{
    console.log("icon","icon")
  },[icon])

  if (icon === "notice") {
    iconElement = (
      <div className="icon notice-icon"></div>
    );
  } else if (icon === "check") {
    iconElement = (
      <div className="icon info-success-icon"></div>
    );
  }
  return (
    <div className="popup-full-bg">
      <div className="popup-checkbox popup-green">
        <div className="popup-content">
          {icon && <div className="popup-icon text-center">{iconElement}</div>}
          
          <div className="popup-title text-center mx-3 mx-xxl-0">
            <h5 className="primary-deep2">{title}</h5>
            
          </div>
          <h6 className={` ${msgcl}`}>{message}</h6>
          <div className={` ${bigmsgcl}`}><RenderHtml content={bigmessage}/></div>
          <RenderHtml content={htmlMsg}/>
        </div>
        <div className={`container d-flex justify-content-center justify-content-center ${orangebtn !== "" && greenbtn !== "" ? 'btn-group' : ''}`}>
          {orangebtn !== "" && (
            <button className="btn search-btn2  " onClick={onNevigate}>
              <h6>{orangebtn}</h6>
            </button>
          )}
          {greenbtn !== "" && (
            <button className="btn primary-btn  " onClick={onClose}>
              <h6>{greenbtn}</h6>
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default PopupGreen;
