//Collection.js
import React, { useEffect, useState, useContext, useMemo } from "react";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredTag } from "../redux/actions/action";
import AuthContext from "../Context/AuthContext";
import axios from "axios";
import Loading from "./Loading";
import ProductCard from "../sharedComponents/ProductCard";
import Pagination from "../sharedComponents/Pagination";
import Nodata from "../sharedComponents/Nodata";
import Breadcrumbs from "./Breadcrumbs";
function Collection() {
  //頁碼
  const [currentPage, setCurrentPage] = useState(1); //頁碼初始值
  const itemsPerPage = 16; //一頁有幾個

  //畫面載入
  const [isLoading, setIsLoading] = useState(true);

  //被選中的愛心
  const [selectedhearts, setSelectedhearts] = useState({});
  //用途/袋形/材質
  const [navCate, setNavCate] = useState([]);

  //篩選出來的全部商品
  const [products, setProducts] = useState([]);
  //下拉選單被按到的filter
  const [filterClick, setFilterClick] = useState(null);
  //來自URL的slug
  const { collectionsSlug } = useParams();

  console.log("collectionsSlug是初始的路由", collectionsSlug);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);

  //上面的標籤按鈕
  // const [filteredTag, setFilteredTag] = useState(null);
  //redux儲存的filteredTag
  const dispatch = useDispatch();
  const filteredTag = useSelector((state) => state.collection.filteredTag);

  const navigate = useNavigate();
  // const location = useLocation();
  const { myAuth } = useContext(AuthContext);

  //取得全部資料
  const getAllData = () => {
    axios
      .post("api/Product/CateByProd", [])
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setProducts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      });
  };

  //取得過濾後的資料
  const fetchData = () => {
    if (isNextDisabled) {
      return;
    }
    setNextDisabled(true);
    if (filteredTag.length === 0 || filteredTag === null) {
      return; // 如果filteredTag为空，不执行操作
    }
    console.log("fetchDatafilteredTag", filteredTag);
    axios
      .post("api/Product/CateByProd", filteredTag)
      .then((response) => {
        console.log("Fetched data after filtering:", "response.data");
        setProducts(response.data);
      })
      .catch((error) => {
        console.log("搜尋出現錯誤的fetchDatafilteredTag:", filteredTag);
        console.error("Error fetching data:", "error");
        // Log a message for when products are not found
        if (error.response && error.response.status === 404) {
          console.log("error.response");
        }
      })
      .finally(() => {
        setNextDisabled(false);
        setIsLoading(false);
      });
  };

  //下拉選單的資訊取得
  const fetchNavCate = () => {
    axios
      .get("api/Product/ProdCate")
      .then((response) => {
        setNavCate(response.data);
        console.log("setNavCate", "response.data");
      })
      .catch((error) => {
        console.error("Error fetching category data:", "error");
      });
  };

  // 分頁後的產品資料
  const paginatedProducts = useMemo(() => {
    const firstIndex = (currentPage - 1) * itemsPerPage;
    const lastIndex = currentPage * itemsPerPage;
    return products.slice(firstIndex, lastIndex);
  }, [currentPage, products]);
  //商品標籤由slug轉為名稱
  const filteredTagName = (item) =>
    navCate
      .find((category) =>
        category.children.some((child) => child.slug === item)
      )
      ?.children.find((child) => child.slug === item)?.catename;

  //下拉選單判斷是用途、袋型還是材質被點擊
  const handleFilterClick = (index) => {
    setFilterClick((prevIndex) => (prevIndex === index ? null : index));
    console.log("handleFilterClick", index);
  };

  //下拉選單中被按下的篩選
  const handleCategoryClick = (event, slug, catename) => {
    event.stopPropagation();
    console.log("按下catename", catename);
    console.log("按下slug", slug);
    const updatedTags = [...filteredTag];
    if (!updatedTags.includes(slug)) {
      updatedTags.push(slug);
      setFilteredTag(updatedTags);
    } else {
      const index = updatedTags.indexOf(slug);
      if (index !== -1) {
        updatedTags.splice(index, 1);
        setFilteredTag(updatedTags);
      }
    }

    // 更新後的標籤要存回Session Storage
    dispatch(setFilteredTag(updatedTags));

    sessionStorage.setItem("collectiontag", updatedTags.join(","));
  };

  //將標籤移除
  const handleTagRemove = (tag) => {
    const updatedTags = filteredTag.filter((item) => item !== tag);
    dispatch(setFilteredTag(updatedTags));
    if (updatedTags.length === 0) {
      setFilteredTag([]);
      navigate("/collections/全部");
      sessionStorage.removeItem("collectiontag");
    } else {
      console.log("filteredTag", "filteredTag");
      //  更新後的標籤要存回Session Storage
      sessionStorage.setItem("collectiontag", updatedTags.join(","));
    }
  };
  //初次載入時要取得下拉式選單資訊，同時設定來自url的slug
  useEffect(() => {
    console.log("初次載入");
    dispatch(setFilteredTag(null));
    if (collectionsSlug === "全部") {
      dispatch(setFilteredTag([]));
      getAllData();
      // sessionStorage.removeItem("collectiontag");
    } else if (collectionsSlug !== "全部" && collectionsSlug) {
      dispatch(setFilteredTag([collectionsSlug]));
      console.log(" dispatch(setFilteredTag([collectionsSlug]));",collectionsSlug);
      // sessionStorage.setItem("collectiontag", collectionsSlug);
    } else {
      setIsLoading(true);
    }
    fetchNavCate();
  }, []);

  //嘗試
  //   useEffect(() => {
  //     const storedCollectionTag = sessionStorage.getItem("collectiontag");
  //     if (storedCollectionTag) {
  //       const collectionTags = storedCollectionTag.split(",");
  //       if (collectionTags.includes(collectionsSlug)) {
  //         setFilteredTag(collectionTags);
  //       } else {
  //         // 如果sessionStorage中的数据不包含URL的值，将其删除
  //         sessionStorage.removeItem("collectiontag");
  //         if (collectionsSlug === "全部") {
  //           setFilteredTag([]);
  //           getAllData();
  //           console.log("初始載入判斷url為全部", collectionsSlug);
  //           sessionStorage.removeItem("collectiontag");
  //         } else if (collectionsSlug !== "全部" && collectionsSlug) {
  //           setFilteredTag([collectionsSlug]);
  //           console.log("初始載入判斷url不是全部", collectionsSlug);
  //           sessionStorage.setItem("collectiontag", collectionsSlug);
  //         } else {
  //           setIsLoading(true);
  //         }
  //       }
  //     }

  //   fetchNavCate();
  // }, []);

  //url更新的時候刷新FilteredTag
  useEffect(() => {
    if (collectionsSlug === "全部") {
      dispatch(setFilteredTag([]));
      setIsLoading(true);
    } else if (collectionsSlug !== "全部" && collectionsSlug) {
      console.log("網址的collectionsSlug", collectionsSlug);
      dispatch(setFilteredTag([collectionsSlug]));
      setIsLoading(true);
    } else {
      setIsLoading(true);
    }
  }, [collectionsSlug]);

  //被刷新的FilteredTag(由url或是使用者操作而來)
  useEffect(() => {
    console.log("現有的filteredTag", "filteredTag");
    if (filteredTag !== null) {
      if (filteredTag.length === 0) {
        getAllData();
        console.log("判斷要執行getAllData");
      } else {
        fetchData();
        console.log("判斷要執行fetchData");
      }
      setCurrentPage(1);
      console.log("filteredTag目前儲存的資訊", "filteredTag");
    }
  }, [filteredTag]);

  // 登入按下愛心之後要重新取得收藏資訊
  useEffect(() => {
    if (myAuth.authorized) {
      const newSelectedhearts = {};
      console.log("heartData", "myAuth.heartData");
      if (myAuth.heartData) {
        myAuth.heartData.forEach((item) => {
          newSelectedhearts[item.pid] = true;
        });
        setSelectedhearts(newSelectedhearts);
      }
    }
  }, [myAuth.heartData]);

  //頁碼改變的時候要回到最上面
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("currentPage", "currentPage");
  }, [currentPage]);

  if (!navCate || isLoading || !paginatedProducts) {
    return (
      <div className="container">
        <Loading height="h-100vh w-100vw position-absolute top-0" />
      </div>
    );
  }
  return (
    <div className="page-background collection-background" onClick={() => { if (filterClick !== null) handleFilterClick(null); }}>
      <div className="share-page product-page">
        <Breadcrumbs />
        <div className="productcate">
          <h3 className="title">商品</h3>
          <div className="productfilter">
            <div className="filter-select">
              {navCate &&
                navCate.map((category, index) => (
                  <div
                    key={index}
                    className={`${
                      filterClick === index ? "showmore" : "showless"
                    } filter-btn`}
                    onClick={() => handleFilterClick(index)}
                  >
                    <div className="tag-name">
                      <h6>{category.catename}</h6>
                    </div>
                    {category.children &&
                      category.children.length > 0 &&
                      filteredTag && (
                        <div className="filter-content">
                          {category.children.map((child, childIndex) => (
                            <div
                              key={childIndex}
                              className={`cate ${
                                filteredTag.includes(child.slug)
                                  ? "selected"
                                  : "unselect"
                              }`}
                              onClick={(e) =>
                                handleCategoryClick(e, child.slug, child.catename)
                              }
                            >
                              <p className="mb-0">{child.catename}</p>
                            </div>
                          ))}
                        </div>
                      )}
                  </div>
                ))}
            </div>
            <div className="filter-result">
              <h6>搜尋結果：{products && products.length}項</h6>
            </div>
          </div>
          <div className="filter-tag">
            {filteredTag &&
              filteredTag.length !== 0 &&
              filteredTag.map((item, index) => (
                <div
                  key={index}
                  className="tag cursor-pointer"
                  onClick={() => handleTagRemove(item)}
                >
                  <h6>{filteredTagName(item)}</h6>
                  <div className="btn"></div>
                </div>
              ))}
          </div>
          {paginatedProducts && paginatedProducts.length > 0 ? (
            <div className="productcate-container">
              <div className="productcate-content ">
                {paginatedProducts.map((product) => (
                  <ProductCard
                    key={product.prodId}
                    prodId={product.prodId}
                    prodImage={product.prodImage}
                    prodName={product.prodName}
                    prodSlug={product.prodSlug}
                    prodIcon={product.prodIcon}
                    selectedheart={selectedhearts[product.prodId] || false}
                  />
                ))}
              </div>
              <div className="container px-md-0">
                <Pagination
                  currentPage={currentPage} // 傳遞 currentPage
                  totalPages={Math.ceil(products.length / itemsPerPage)} // 計算 totalPages
                  onPageChange={setCurrentPage} // 傳遞頁數變化處理函式
                />
              </div>
            </div>
          ) : (
            <>
              <div className="container productcate-content px-0 px-md-auto">
                <div className="container px-0">
                  <Nodata
                    descript={"篩選的範圍沒有商品"}
                    link={"../../collections"}
                    linkDescript={"查看全部商品"}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Collection;
