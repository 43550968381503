//ProductPrice
import React, { useState, useRef, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  addToOPCart,
  setSelectedProcessingForPrice,
} from "../../redux/actions/action";
import Cartanima from "../../sharedComponents/Cartanima";
import Loading from "../Loading";
import ProductLike from "./ProductLike";

import {
  selectOptionName,
  selectOptionSecondName,
  selectProcessingName,
  selectedOptionForPrice,
} from "../../redux/selectors/selectors";

import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import { useNavigate, useLocation, Link } from "react-router-dom";
import CartPopup from "../../sharedComponents/CartPopup";
import ProductDiy from "./ProductDiy";

function ProductPrice({ productDataId, title, img, onePage, onePageClose }) {
  const [selectedProcessing, setSelectedProcessing] = useState([]); // 使用陣列來存儲選中的處理選項
  const [cartAni, setCartani] = useState(false); //購物車動畫
  // const [secondStage, setSecondStage] = useState(""); //取得第二層
  const [isLoading, setIsLoading] = useState(true);
  const [unitPrice, setUnitPrice] = useState(null);
  const [discountPrice, setDiscountPrice] = useState(0);
  const [quantity, setQuantity] = useState(1); // 初始化數量為1

  const [noProcessCart, setNoProcessCart] = useState(false); //為true的時候呼叫未加購的加入購物車
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.product.selectedOption); //redux first option
  const selectedOptionSecond = useSelector(
    (state) => state.product.selectedOptionSecond
  ); //redux second option
  const selectedOptionForPrice = useSelector(
    (state) => state.product.selectedOptionForPrice
  );
  const optionName = useSelector(selectOptionName);
  const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);

  const navigate = useNavigate();
  //判斷網址
  const location = useLocation();
  const currentPath = location.pathname;
  useEffect(() => {
    if (selectedOption !== "") {
      console.log("price第一層改變", selectedOption);
      console.log("price第二層改變", selectedOptionSecond);
      console.log("price得到的selectedOptionForPrice", selectedOptionForPrice);
    }
  }, [selectedOptionForPrice]);

  const selectedProcessingForPrice = useSelector(
    (state) => state.product.selectedProcessingForPrice || []
  );

  const firstSkuData = selectedOptionForPrice
    ? selectedOptionForPrice.firstSkuData
    : {};

  const skuProcessing = selectedOptionForPrice
    ? selectedOptionForPrice.skuProcessing
    : {};

  const reduxProcessing = selectedProcessingForPrice
    ? selectedProcessingForPrice
    : {}; //redux processing

  const discountData =
    selectedOptionForPrice && selectedOptionForPrice.discountData
      ? selectedOptionForPrice.discountData
      : {};
  // console.log("reduxProcessing",reduxProcessing)

  const inputRef = useRef(null);
  // popup
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  // const [popupPath, setPopupPath] = useState(null);
  const [waitForCartPopup, setIsWaitCartPopup] = useState(false);
  const [isCartPopup, setIsCartPopup] = useState(false);

  // 計算多個加購的單價
  const additionalOptionsTotalPrice = selectedProcessingForPrice
    ? selectedProcessingForPrice.reduce(
        (total, option) => total + option.data.unitPrice,
        0
      )
    : 0;
  // const addtionalOptionUnit = selectedOptionForPrice
  //   ? selectedOptionForPrice.firstSkuData.unitPieces
  //   : 0; //加購的數量
  const addtionalOptionUnit =
    selectedOptionForPrice &&
    selectedOptionForPrice.firstSkuData &&
    selectedOptionForPrice.firstSkuData.unitPieces
      ? selectedOptionForPrice.firstSkuData.unitPieces
      : 0;

  const addtionaltotalPrice = selectedOptionForPrice
    ? additionalOptionsTotalPrice * addtionalOptionUnit
    : {}; //加購的總價

  const { myAuth, updateCartData, updateCartAmount } = useContext(AuthContext); //登入狀態
  // console.log('商品名稱:', title);//商品名稱
  // console.log('第一個選項中文敘述', optionName);//第一個選項中文敘述
  // console.log('第二個選項中文敘述', optionSecondName);//第二個選項中文敘述
  // console.log('加購選項中文敘述', processingName);//加購選項中文敘述

  const selectedProcessingNames = selectedProcessing.map(
    (item) => item.data.processingName
  );
  //console.log("reduxProcessing", reduxProcessing);
  //console.log("reduxProcessing",reduxProcessing.data.unitPrice)// 加購的單價
  //console.log("加購的單價", addtionalOptionPrice); // 加購的單價
  //console.log("firstSkuData", firstSkuData.skuUnitPieces);//加購的數量
  //console.log("加購的數量", addtionalOptionUnit); //加購的數量
  //console.log("reduxProcessingamount",reduxProcessing.data.unitPrice)//加購的總價
  //console.log("加購的總價", addtionaltotalPrice); //加購的總價
  // console.log("firstSkuData", firstSkuData);

  // 根據數量查找適當的單價

  const findUnitPriceByQuantity = (inputquantity) => {
    const quantity = inputquantity || 1;
    console.log("quantity", "quantity");

    if (
      firstSkuData &&
      firstSkuData.unitPrices &&
      firstSkuData.unitPrices.length > 0
    ) {
      for (let i = firstSkuData.unitPrices.length - 1; i >= 0; i--) {
        if (quantity >= firstSkuData.unitPrices[i].quantity) {
          return firstSkuData.unitPrices[i].unitPrice;
        }
      }
    }
    const isUnitPriceNullOrUndefined =
      firstSkuData.unitPrice === undefined || firstSkuData.unitPrice === null;

    if (isUnitPriceNullOrUndefined) {
      return;
    }

    if (firstSkuData.unitPrices) {
      return firstSkuData.unitPrices[0].unitPrice; // 預設單價
    }
  };
  //根據數量查找適當的折價金額
  const findDiscountPrice = (unitPrice, discountValue) => {
    if (isNaN(unitPrice) || isNaN(discountValue)) {
      // 如果unitPrice或discountValue不是有效数字，则无法继续计算
      return "Invalid Input";
    }

    const discount = parseInt(discountValue);
    const discountedPrice = (1 - discount * 0.01) * unitPrice;

    // 通过toFixed将小数位数限制为两位
    return parseFloat(discountedPrice.toFixed(2));
  };

  // 函數：減少數量
  useEffect(() => {
    // 在 input 元素失去焦點時檢查數量
    const checkQuantity = () => {
      if (!inputRef.current || !inputRef.current.matches(":focus")) {
        if (quantity === "" || parseInt(quantity) < 1) {
          setQuantity(1);
        }
      }
    };

    // 訂閱焦點事件
    if (inputRef.current) {
      inputRef.current.addEventListener("blur", checkQuantity);
    }

    // 在組件卸載時取消訂閱
    return () => {
      if (inputRef.current) {
        inputRef.current.removeEventListener("blur", checkQuantity);
      }
    };
  }, [quantity]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };
  const handleQuantityChange = (event) => {
    const value = event.target.value;
    // 使用規則運算式檢查輸入是否為數位
    if (/^[0-9]*$/.test(value)) {
      // 只有輸入是數位才更新狀態
      setQuantity(value === "" ? "" : parseInt(value, 10));
    }
  };
  useEffect(() => {
    if (firstSkuData) {
      if (onePage) {
        const unitPrice = firstSkuData.unitPrice;
        console.log("設置的unitPrice", "unitPrice");
        setUnitPrice(unitPrice);
      } else {
        const unitPrice = findUnitPriceByQuantity(quantity);
        setUnitPrice(unitPrice);
        console.log("設置的unitPrice", "unitPrice");
      }
    }
  }, [dispatch, selectedOptionForPrice, quantity, onePage]);

  useEffect(() => {
    if (discountData && discountData.discountValue && unitPrice) {
      const discountPrice = findDiscountPrice(
        unitPrice,
        discountData.discountValue
      );
      console.log("discountPrice", "discountPrice");
      setDiscountPrice(discountPrice);
      setIsLoading(false);
    } else {
      setDiscountPrice(null);
      setIsLoading(false);
    }
  }, [unitPrice, discountData]);

  // console.log('productDataId',productDataId)

  // console.log("unitPrice",unitPrice)
  // console.log("reduxProcessing使用者已經勾選的情況", "reduxProcessing");
  // console.log("skuProcessing", "skuProcessing");

  const handleAddToCart = (cartpopupOpen) => {
    // 加購選項的popup
    // if (!skuProcessing) {
    //   console.log("原本就沒有加購選項不用打開popup");

    //   setIsWaitCartPopup(cartpopupOpen);
    //   postAddToCart();
    // } else {
    //   if (Object.keys(reduxProcessing).length === 0) {
    //     console.log("沒有勾選加購選項觸發打開popup");
    //     // setPopupPath(cartpopup);
    //     setIsWaitCartPopup(cartpopupOpen);

    //     setIsPopupOpen(true);
    //   } else {
    //     console.log("已經有勾選");
    //     setIsWaitCartPopup(cartpopupOpen);
    //     postAddToCart();
    //   }
    // }
    setIsWaitCartPopup(cartpopupOpen);
    postAddToCart();
  };

  //處理將資料發送後端的請求
  const addToCartWithAuthorization = (cartItem) => {
    const requestData = {
      memberid: myAuth.sid,
      orderItems: [cartItem],
    };
    const storedToken = myAuth.token;
    axios
      .post("/api/Order/AddOrderitem", requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log("已加到購物車", "response.data");
        console.log("加到購物車的資料", "requestData");
        dispatch(addToCart(productDataId));
        updateCartAmount();
        updateCartData();
        setCartani(true);
      })
      .catch((error) => {
        console.error("添加訂單到購物車時出錯：", "error");
        console.log("requestData", "requestData");
        // setCartani(true);
        // if (path) {
        //   navigate(path); // 如果傳遞了 path
        // }
      });
  };

  const addToCartNoAuthor = (cartItem) => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];
    let cartItemExists = false;

    // 檢查是否存在與新購物車項相同的項
    existingCart.forEach((item, index) => {
      if (
        item.prodid === cartItem.prodid &&
        item.skuid === cartItem.skuid &&
        item.processid === cartItem.processid
      ) {
        // 如果找到匹配的項，則將其quantity相加
        existingCart[index].quantity += cartItem.quantity;
        cartItemExists = true;
      }
    });

    // 如果沒有匹配的項，則將新的購物車項添加到陣列中
    if (!cartItemExists) {
      existingCart.push(cartItem);
    }

    // 將更新後的購物車資料保存回LocalStorage
    localStorage.setItem("cart", JSON.stringify(existingCart));

    console.log("已加到購物車", "existingCart");
    console.log("加到購物車的資料", "cartItem");

    dispatch(addToCart(existingCart));

    setCartani(true);
  };
  const addToCartOnePage = (cartItem) => {
    const existingCart = JSON.parse(localStorage.getItem("onepagecart")) || [];
    let cartItemExists = false;

    // 檢查是否存在與新購物車項相同的項
    existingCart.forEach((item, index) => {
      if (
        item.prodid === cartItem.prodid &&
        item.skuid === cartItem.skuid &&
        item.processid === cartItem.processid
      ) {
        // 如果找到匹配的項，則將其quantity相加
        existingCart[index].quantity += cartItem.quantity;
        cartItemExists = true;
      }
    });

    // 如果沒有匹配的項，則將新的購物車項添加到陣列中
    if (!cartItemExists) {
      existingCart.push(cartItem);
    }

    // 將更新後的購物車資料保存回LocalStorage
    localStorage.setItem("onepagecart", JSON.stringify(existingCart));

    console.log("onepagecart已加到購物車", "existingCart");
    console.log("onepagecart加到購物車的資料", "cartItem");

    dispatch(addToOPCart(existingCart));

    setCartani(true);
  };
  //popup的不用加購
  const popupNoProcessing = () => {
    // 返回一个 Promise
    return (
      new Promise((resolve) => {
        // 状态更新
        setSelectedProcessing([]);
        dispatch(setSelectedProcessingForPrice([]));

        // resolve 表示状态更新完成
        resolve();
      })

        // then 中获取最新状态
        .then(() => {
          setNoProcessCart(true);
          console.log("最新狀態");
        })
    );
  };
  useEffect(() => {
    if (noProcessCart) {
      console.log("準備调用的noProcessCart");
      postAddToCart();
    }
  }, [noProcessCart]);
  //加入購物車判斷是有登入還是未登入狀態
  //onepage一律是未登入狀態
  const postAddToCart = async () => {
    setNoProcessCart(false);
    console.log("productDataId", "productDataId"); //商品id
    console.log("selectedOption", "selectedOption"); //第一個選項
    console.log("selectedOptionSecond", "selectedOptionSecond"); //第二個選項也是sku

    console.log("reduxProcessing這裡", "reduxProcessing"); //加購選項
    console.log("數量", "quantity");
    console.log("title", "title");
    // 處理每個字串，將空格替換為空字串
    const titleNoSpace = title.replace(/\s/g, "");
    const optionNameNoSpace = optionName.replace(/\s/g, "");
    const optionSecondNameNoSpace = optionSecondName.replace(/\s/g, "");

    // 處理 processingName 陣列，將空格替換為空字串，然後連接為一個字串，使用逗號分隔
    const processingNamesString = Array.isArray(processingName)
      ? processingName.map((name) => name.replace(/\s/g, "")).join(", ")
      : "";

    // 將處理後的字元串連接為一個字串，使用逗號分隔
    const productDescriptString = [
      titleNoSpace,
      optionNameNoSpace,
      optionSecondNameNoSpace,
      processingNamesString,
    ]
      .filter(Boolean)
      .join(", ");
    const productDescriptStringNoSpace = productDescriptString.replace(
      /\s/g,
      ""
    );

    const processingKeysString = reduxProcessing
      .map((item) => item.data.processingid)
      .join(",");
    console.log("processingKeysString這裡", "processingKeysString"); //加購key
    setIsPopupOpen(false);
    const cartItem = {
      prodid: productDataId,
      skuid: selectedOptionSecond,
      quantity: quantity,
      itemdesc: productDescriptStringNoSpace,
    };

    if (processingKeysString) {
      cartItem.processid = processingKeysString;
    }

    const checkItemExist = {
      prodid: productDataId,
      skuid: selectedOptionSecond,
    };
    if (onePage) {
      //onepage都是加入local
      addToCartOnePage(cartItem);
      console.log("addToCartOnePage", "cartItem");
    } else {
      if (processingKeysString) {
        checkItemExist.processid = processingKeysString;
      }
      try {
        const response = await axios.post(
          "/api/Order/CKOrderitem",
          checkItemExist,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        console.log("資料庫確認回傳的資料", "response.data");
        console.log("可以加到購物車", "checkItemExist");
      } catch (error) {
        console.error("資料庫中沒有這個商品", "error");
        console.log("不可以加到購物車", "checkItemExist");
        return;
      }

      if (myAuth.authorized) {
        addToCartWithAuthorization(cartItem);
        console.log("addToCartWithAuthorization", "cartItem");
      } else {
        addToCartNoAuthor(cartItem);
        console.log("addToCartNoAuthor", "cartItem");
      }
      // 在 addToCartWithAuthorization 或 addToCartNoAuthor 完成後執行導航
    }
  };

  // console.log("reduxProcessing加購選項", reduxProcessing); //加購選項
  useEffect(() => {
    setSelectedProcessing(selectedProcessingForPrice || []); // 如果Redux中沒有資料，則初始化為空陣列
  }, [selectedProcessingForPrice]);
  // 修改 handleToggleCheckbox 函數以更新 Redux 中的狀態
  const isSelected = (processingKey) => {
    return selectedProcessingForPrice.some(
      (item) => item.key === processingKey
    );
  };
  //打開購物車和購物車動畫
  useEffect(() => {
    console.log("waitForCartPopup", "waitForCartPopup");
    console.log("isPopupOpen", "isPopupOpen");
    if (waitForCartPopup && !isPopupOpen) {
      setIsCartPopup(waitForCartPopup);
      setCartani(false);
    } else if (!waitForCartPopup && !isPopupOpen) {
      const timer = setTimeout(() => {
        setCartani(false);
      }, 800);

      return () => clearTimeout(timer);
    } else {
      const timer = setTimeout(() => {
        setCartani(false);
      }, 800);

      return () => clearTimeout(timer);
    }
  }, [waitForCartPopup, isPopupOpen, cartAni]);
  // 原先的開啟彈出視窗 改為導向/cart
  useEffect(() => {
    if (isCartPopup) {
      navigate("/cart");
    }
  }, [isCartPopup]);
  useEffect(() => {
    if (onePage && cartAni) {
      const timer = setTimeout(() => {
        onePageClose();
      }, 800);
      return () => clearTimeout(timer);
    }
  }, [cartAni, onePage]);
  const handleToggleCheckbox = (processingKey, processingData) => {
    const isSelected = selectedProcessing.some(
      (item) => item.key === processingKey
    );

    if (isSelected) {
      setSelectedProcessing((prevSelected) =>
        prevSelected.filter((item) => item.key !== processingKey)
      );

      // Remove the object with the same key from Redux
      const updatedSelectedProcessing = selectedProcessingForPrice.filter(
        (item) => item.key !== processingKey
      );
      dispatch(setSelectedProcessingForPrice(updatedSelectedProcessing));
    } else {
      setSelectedProcessing((prevSelected) => [
        ...prevSelected,
        { key: processingKey, data: processingData },
      ]);

      // Add the new object to Redux
      const updatedSelectedProcessing = [
        ...selectedProcessingForPrice,
        { key: processingKey, data: processingData },
      ];
      dispatch(setSelectedProcessingForPrice(updatedSelectedProcessing));
    }
  };

  // 清空選中的附加選項
  useEffect(() => {
    setSelectedProcessing([]);
  }, [selectedOptionForPrice]);
  //popup的不用加購處理

  //   //關閉popup的時候要清除processing
  useEffect(() => {
    if (!isPopupOpen) {
      setSelectedProcessing([]);
    }
  }, [isPopupOpen]);

  // 當選中的附加選項發生變化時，更新 Redux 狀態
  useEffect(() => {
    dispatch(setSelectedProcessingForPrice(selectedProcessing));
  }, [dispatch, selectedProcessing]);
  if (isLoading || !unitPrice) {
    <Loading height={"h-auto"} />;
    return;
  }
  return (
    <>
      {/* {isCartPopup && (
       <CartPopup
       onClose={() => {
        console.log("close")
        setIsCartPopup(false);
         if (currentPath === "/checkout"){
           window.location.assign('/checkout');
           sessionStorage.removeItem("shopid");
           sessionStorage.removeItem("areatype");
         }
         if(currentPath === "/checkoutnotauth"){
           window.location.assign('/checkout');
           sessionStorage.removeItem("shopid");
           sessionStorage.removeItem("areatype");
         }
       }}
       onNevigate={() => {
        setIsCartPopup(false);
         
         if (currentPath === "/checkout"){
           window.location.assign('/checkout');
           sessionStorage.removeItem("shopid");
           sessionStorage.removeItem("areatype");
           
         }else if(currentPath === "/checkoutnotauth"){
           window.location.assign('/checkout');
           sessionStorage.removeItem("shopid");
           sessionStorage.removeItem("areatype");
         }else{
           if(myAuth.authorized){
             navigate("/checkout");
           }else{
             navigate("/checkoutstep1");
           }
         }
       }}
     />
      )} */}
      {/* <Cartanima img={img}/> */}
      {cartAni && img && <Cartanima img={img} />}
      {/* {isPopupOpen && (
        <div>
          <div
            className="popup-full-bg"
            onClick={() => {
              setIsPopupOpen(false);

            }}
          ></div>
          <div className="popup-checkbox">
            <div className="popup-content mx-4 mx-xxl-0">
              <div className="popup-icon text-center">
                <div className="notice-icon"></div>
              </div>
              <div className="popup-title text-center">
                <h4>是否需要加購</h4>
              </div>
              <div className="d-xxl-flex gap-10px">
                {Object.keys(skuProcessing).length > 0 &&
                  Object.keys(skuProcessing).map((processingKey, index) => (
                    <div
                      className="popup-check d-flex cursor-pointer"
                      key={processingKey}
                      onClick={() =>
                        handleToggleCheckbox(
                          processingKey,
                          skuProcessing[processingKey]
                        )
                      }
                    >
                      <div className=" checkbox-button">

                        <div
                          className={`checkbox-icon ${
                            isSelected(processingKey) ? "checked" : "default"
                          }`}
                        >
                          
                        </div>
                      </div>
                      <h6 className="pt-0 ps-1">
                        {skuProcessing[processingKey].processingName}
                      </h6>
                    </div>
                  ))}
              </div>
              <div className="d-xxl-flex flex-column ">
                <h6 className="danger">*此為客制化商品，訂製後不可退換貨</h6>
                <h6 className="danger">*加購氣閥因體積蓬鬆，不可超商取貨</h6>
              </div>
            </div>
            <div className="container d-flex justify-content-center">
              <div
                className={`btn search-btn2 mx-3 ${
                  selectedProcessingForPrice.length === 0 ||
                  selectedProcessing.length === 0
                    ? "disabled"
                    : ""
                }`}
                onClick={() => {
                  postAddToCart();
                }}
              >
                <h6>確認加購</h6>
              </div>
              <div
                className="btn primary-btn mx-3"
                onClick={() => {
                  popupNoProcessing();
                }}
                disabled={selectedProcessingForPrice.length !== 0}
              >
                <h6>不用加購</h6>
              </div>
            </div>
          </div>
        </div>
      )} */}

      {onePage && (
        <div className="page-title pb-1">
          <h6>選擇數量：</h6>
        </div>
      )}
      <div className="counter-box">
        <div className="content">
          <div className={`${"amount-select"}`}>
            {!onePage && <h6 className="second-title">數量：</h6>}

            <div className="amount-box">
              <div className="block minus" onClick={decreaseQuantity}>
                <div className="minus-icon icon"></div>
              </div>
              <input
                type="text"
                value={quantity}
                className="block number"
                ref={inputRef}
                onChange={handleQuantityChange}
              />
              <div className="block plus" onClick={increaseQuantity}>
                <div className="plus-icon icon"></div>
              </div>
              {onePage && firstSkuData && (
                <p className="text-nowrap align-self-end">
                  {firstSkuData.unit}({firstSkuData.unitPieces}個)
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="total">
          <h6 className="second-title">總計：</h6>
          <div className="total-amount">
            {unitPrice !== undefined &&
            unitPrice !== null &&
            addtionaltotalPrice !== undefined &&
            addtionaltotalPrice !== null ? (
              discountPrice ? (
                <>
                  <h6 className="disable text-decoration-line-through">
                    $
                    {(quantity > 0 ? quantity : 1) *
                      (unitPrice + addtionaltotalPrice)}
                  </h6>
                  <h4 className="info">
                    $
                    {(quantity > 0 ? quantity : 1) *
                      (discountPrice + addtionaltotalPrice)}
                  </h4>
                  <h6>未稅</h6>
                </>
              ) : (
                <>
                  <h4 className="black">
                    $
                    {(quantity > 0 ? quantity : 1) *
                      (unitPrice + addtionaltotalPrice)}
                  </h4>
                  <h6>未稅</h6>
                </>
              )
            ) : (
              <h4 className="black">價格請洽客服人員</h4>
            )}
          </div>
        </div>
        {!onePage && (
          <div className="price-descript">
            {firstSkuData && (
              <p>
                基本量：1{firstSkuData.unit}({firstSkuData.unitPieces}個)
              </p>
            )}

            {unitPrice !== undefined && unitPrice !== null && (
              <>
                <p>價格：${discountPrice ? discountPrice : unitPrice}</p>
                {firstSkuData.unitPieces !== undefined &&
                firstSkuData.unitPieces !== null ? (
                  <p>
                    單價：$
                    {parseFloat(
                      (discountPrice ? discountPrice : unitPrice) /
                        firstSkuData.unitPieces
                    ).toFixed(2)}
                    ／個
                  </p>
                ) : (
                  <p>單價：請洽客服人員</p>
                )}
              </>
            )}
            {unitPrice === undefined ||
              (unitPrice === null && <p>單價：請洽客服人員</p>)}
          </div>
        )}
        {onePage ? (
          <div className="purchase-btn-group">
            <div className="back-btn" onClick={onePageClose}>
              <h6>返回</h6>
            </div>
            <div className="tocart-btn" onClick={() => handleAddToCart(false)}>
              <h6>加入購物車</h6>
            </div>
          </div>
        ) : (
          <div className={`purchase-btn-group ${firstSkuData && firstSkuData.layoutid && ("diy-btn-group")} `}>
            <ProductLike productDataId={productDataId} />
            <div className="tocart-btn" onClick={() => handleAddToCart(false)}>
              <h5>加入購物車</h5>
            </div>
            <div className="buy-btn" onClick={() => handleAddToCart(true)}>
              <h5>立即購買</h5>
            </div>
            {firstSkuData && firstSkuData.layoutid && (
              <ProductDiy newdoc={selectedOptionSecond || selectedOption}/>

            )}

            
          </div>
        )}
      </div>
    </>
  );
}

export default ProductPrice;
