import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Loading from "../Loading";
import Nodata from "../../sharedComponents/Nodata";
import Checkoutshipping from "../../sharedComponents/Checkoutshipping";
import InvoiceSelect from "../../sharedComponents/InvoiceSelect";
import NamePhoneLabel from "../../sharedComponents/NamePhoneLabel";
import axios from "axios";
import Popup from "../../sharedComponents/Popup";
function OnePageCheckout({ cartDataUpdate, onepageSlug, onepagePreviewSlug }) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedshipOption, setSelectedShipOption] = useState(null);
  //紀錄可選擇的選項
  const [enabledShippingOptions, setEnabledShippingOptions] = useState([]);
  const [paymentOption, setPaymentOption] = useState(null);
  // 購物車資料
  const [cartTotalData, setCartTotalData] = useState(null);
  const localStorageCartData = localStorage.getItem("onepagecart");
  const selectedOption = useSelector((state) => state.product.productId);
  const [cartTotalPrice, setCartTotalPrice] = useState(null);
  const [cartTotalWeight, setCartTotalWeight] = useState(null);
  const [shippingFee, setShippingFee] = useState(null);
  //運送選項

  const [shippingOptions, setShippingOptions] = useState(null);
  const [selectedShipArea, setSelectedShipArea] = useState(null);
  const [selectedAreaType, setSelectedAreaType] = useState(null);

  //email表單選項
  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);

  //發送表單的檢查和相關欄位

  const [invoiceData, setInvoiceData] = useState({});
  const [namePhoneValid, setNamePhoneValid] = useState(false);
  const [reciptName, setReciptName] = useState("");
  const [reciptPhone, setReciptPhone] = useState("");
  const [reciptTele, setReciptTele] = useState("");
  const [reciptePostcode, setReciptePostcode] = useState("");
  const [recipteCountry, setRecipteCountry] = useState("");
  const [recipteTownship, setRecipteTownship] = useState("");
  const [recipteAddress, setRecipteAddress] = useState("");
  const [isInvoiceValid, setIsInvoiceValid] = useState(false);

  //只有在有selectedShipArea的時候才判斷
  const [addressValid, setAddressValid] = useState(true);

  //付款方式的預設值
  const paymentoptions = [{ label: "信用卡付款", value: "1" }];

  //發票類型設定
  const [invoiceType, setInvoiceType] = useState("");
  //三聯式的資料
  const [businessNumber, setBusinessNumber] = useState("");
  const [invoiceTitle, setInvoiceTitle] = useState("");
  //備註
  const [orderMemo, setOrderMemo] = useState("");
  //付款不成功的popup
  const [noPage, setNoPage] = useState(false);

  // 下一步的disable
  const [isNextDisabled, setNextDisabled] = useState(false);
  // 預覽畫面不可結帳的popup
  const [preventCheckout, setPreventCheckout] =useState(false);

  const navigate = useNavigate();
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  console.log("cartDataUpdate", "cartDataUpdate");
  //檢查格式合法
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };
  //撈取資料
  const getOpCart = () => {
    if (localStorageCartData) {
      try {
        const cartItems = JSON.parse(localStorageCartData);
        const requestData = {
          orderItems: cartItems, // 将解析后的数据放入数组
        };
        axios
          .post(`/api/Onepage/GetOPshoppingCart`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const localCart = response.data;
              console.log("未登入的onepagecartCart:", "localCart");
              console.log("未登入的onepagecartcartItems:", "cartItems");
              console.log("未登入的onepagecartloginCart在這:", "localCart");
              setCartTotalData(localCart);
            } else {
              console.log("Request failed:", "response");
              // Token is invalid, log out the user
            }
          })
          .catch((error) => {
            console.error("Error:", "error");
            console.error("requestData:", "requestData");
          })
          .finally(() => {
            setIsLoading(false); // Mark loading as finished regardless of success or error
          });
      } catch (error) {
        console.error("Error parsing localStorageCartData:", "error");
        // 不加401
      }

      setIsLoading(false);
    }
  };

  const getOpShipping = (cartTotalPrice, cartTotalWeight) => {
    if (cartTotalPrice && cartTotalWeight) {
      try {
        // const requestData = {
        //   "OPorderTotal": cartTotalPrice,
        //   "OPorderWeight": cartTotalWeight,
        // };
        const requestData = {
          orderWeight: cartTotalWeight,
        };
        axios
          .post(`/api/Onepage/GetOPShipping`, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response.status === 200) {
              console.log("GetOPShipping response:", "response.data");
              console.log("GetOPShipping requestData:", "requestData");
              setShippingOptions(response.data.shippinglist);
            } else {
              console.log("Request failed:", "response");
              // Token is invalid, log out the user
            }
          })
          .catch((error) => {
            console.error("Error:", "error");
            console.error("requestData:", "requestData");
          })
          .finally(() => {
            setIsLoading(false); // Mark loading as finished regardless of success or error
          });
      } catch (error) {
        console.error("Error parsing localStorageCartData:", "error");
        // 不加401
      }
    }

    setIsLoading(false);
  };
  const getShippingFee = () => {
    try {
      let requestData = {};
      if (onepageSlug) {
        requestData = {
          OPSlug: onepageSlug,
          shippingid: selectedshipOption.toLocaleString(),
          orderTotal: cartTotalPrice,
        };
      } else if (onepagePreviewSlug) {
        requestData = {
          OPSlug: onepagePreviewSlug,
          shippingid: selectedshipOption.toLocaleString(),
          orderTotal: cartTotalPrice,
        };
      } else {
        // Handle the case where neither onepageSlug nor onepagePreviewSlug is available
        console.error("Both onepageSlug and onepagePreviewSlug are missing");
        return;
      }
      axios
        .post(`/api/Onepage/GetOPShippingCost`, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("GetOPShippingCost response:", "response.data");
            console.log("GetOPShippingCost requestData:", "requestData");
            setShippingFee(response.data);
          } else {
            console.log("Request failed:", "response");
            // Token is invalid, log out the user
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          console.error("requestData:", "requestData");
        })
        .finally(() => {
          setIsLoading(false); // Mark loading as finished regardless of success or error
        });
    } catch (error) {
      console.error("Error parsing localStorageCartData:", "error");
      // 不加401
    }
  };
  const toOrder = () => {
    console.log("getOpShipping");
    if (isNextDisabled) {
      console.log("連點禁止");
      return;
    }
    if (onepagePreviewSlug) {
      console.log("預覽無法結帳");
      setPreventCheckout(true);
      return;

    }
    setNextDisabled(true);
    const cartItems = JSON.parse(localStorageCartData);
    const orderTax = Math.ceil((cartTotalPrice + shippingFee) * 0.05);
    const orderTotal = Math.ceil(
      (cartTotalPrice + shippingFee) * 1.05
    ).toString();
    try {
      const baseRequestData = {
        OPSlug: onepageSlug,
        shippingId: selectedshipOption.toString(),
        orderTotal: orderTotal,
        shippingFee: shippingFee,
        orderTax: orderTax,
        paymentId: paymentOption.toString(),
        recipientName: reciptName,
        recipientMobile: reciptPhone,
        recipientEmail: email,
        recipientAddr: `${reciptePostcode}///${recipteCountry}///${recipteTownship}///${recipteAddress}`,
        orderItems: cartItems,
        orderRemark: orderMemo,
        recipientTel: reciptTele,
      };
      const requestData = { ...baseRequestData, ...invoiceData };
      axios
        .post(`/api/Onepage/OPToOrder `, requestData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          if (response.status === 200) {
            console.log("OPToOrder response:", "response.data");
            console.log("OPToOrder requestData:", "requestData");
            if (
              response.data.paymentid === "1" &&
              response.data.b2CLink !== null
            ) {
              console.log("b2CLink", "response.data.b2CLink");
              window.location.href = response.data.b2CLink;
              localStorage.removeItem("onepagecart");
            } else {
              console.log("例外情況");
              setNoPage(true);
            }
          } else {
            console.log("Request failed:", "response");
            // Token is invalid, log out the user
          }
        })
        .catch((error) => {
          console.error("Error:", "error");
          console.error("requestData:", "requestData");
        })
        .finally(() => {
          setIsLoading(false); // Mark loading as finished regardless of success or error
          setNextDisabled(false);
        });
    } catch (error) {
      console.error("Error parsing localStorageCartData:", "error");
      // 不加401
    }
  };
  const handleOrderMemoChange = (e) => {
    const inputValue = e.target.value;
    // 使用正则表达式验证输入，只允许字母、数字、空格和常见标点符号
    // const sanitizedValue = inputValue.replace(
    //   /[^A-Za-z0-9\u4e00-\u9fa5\u3105-\u3129\-_]/g,
    //   ""
    // );

    setOrderMemo(inputValue);
  };
  const handleNextButtonClick = () => {
    //最後檢查
    if (
      selectedshipOption &&
      isValidEmail(email) &&
      namePhoneValid &&
      paymentOption &&
      isInvoiceValid &&
      addressValid
    ) {
      console.log("進行結帳");
      toOrder();
    } else {
      console.log("不合法的格式");
    }
  };
  //根據使用者選擇的運送方式
  const handleShipOptionChange = (
    option,
    areaType,
    shipArea,
    cashondelivery
  ) => {
    setSelectedShipOption(option);
    setSelectedShipArea(shipArea);
    setSelectedAreaType(areaType);

    console.log("按下的selectedshipOption", "option");
    console.log("按下的selectedShipArea", "shipArea");
    console.log("按下的selectedAreaType", "areaType");

    // console.log("newcashondelivery", newcashondelivery);
    // 轉譯字串
    const newcashondelivery = JSON.parse(cashondelivery);
    // setNewcashondelivery(newcashondelivery);
  };
  const clearShipOption = () => {
    setSelectedShipOption(null);
    setSelectedShipArea(null);
    setSelectedAreaType(null);
  };
  // 使用者選擇的付款方式

  const handlePaymentOptionChange = (option) => {
    setPaymentOption(option);
    console.log("*付款方式", "option");
  };

  useEffect(() => {
    if (invoiceType === "2") {
      setInvoiceData({ invoiceType: invoiceType });
    }
    if (invoiceType === "3") {
      setInvoiceData({
        invoiceType: invoiceType,
        invoiceTitle: invoiceTitle,
        invoiceNo: businessNumber,
      });
    }
  }, [invoiceType, businessNumber, invoiceTitle]);
  //未登入的初始資料
  useEffect(() => {
    getOpCart();
  }, [localStorageCartData, selectedOption, cartDataUpdate]);
  useEffect(() => {
    if (cartTotalData) {
      const totalWeight = cartTotalData.reduce((total, item) => {
        return total + item.quantity * item.prodWeight;
      }, 0);

      const totalPrice = cartTotalData.reduce((total, item) => {
        return total + item.quantity * item.skuData.unitPrice;
      }, 0);
      const caculaterTotalWeight = parseFloat(totalWeight.toFixed(2));
      console.log("cartTotalData", "cartTotalData");
      console.log("cartTotalData totalWeight", "caculaterTotalWeight");
      console.log("cartTotalData totalPrice", "totalPrice");
      setCartTotalPrice(totalPrice);
      setCartTotalWeight(caculaterTotalWeight);
      getOpShipping(totalPrice, caculaterTotalWeight);
    }
  }, [cartTotalData]);

  useEffect(() => {
    if (shippingOptions) {
      // 先設置為空数组
      let enabledOptions = [];

      shippingOptions.forEach((option) => {
        if (option.disable) {
          // 將 disable 為 false 的 id push 進数组
          enabledOptions.push(option.id);
        }
      });
      // 最後設置状态
      setEnabledShippingOptions(enabledOptions);
    }
  }, [shippingOptions]);

  useEffect(() => {
    if (selectedshipOption) {
      if (enabledShippingOptions.includes(selectedshipOption)) {
        console.log("選擇的選項可用");
        getShippingFee();
      } else {
        console.log("選擇的選項 不可用，要清空資料");

        clearShipOption();
      }
    }
  }, [selectedshipOption, enabledShippingOptions]);

  useEffect(() => {
    console.log("shippingFee", "shippingFee");
    console.log("selectedshipOption", "selectedshipOption");
    console.log("cartTotalPrice", "cartTotalPrice");
  }, [shippingFee, selectedshipOption, cartTotalPrice]);

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );
  if (cartDataUpdate && cartDataUpdate.length === 0) {
    return (
      <div className="container pt-3 px-0 pb-5">
        <Nodata blockdescript={"請先選擇訂購商品。"} />
      </div>
    );
  }
  console.log("shippingOptions",shippingOptions)
  // if (isLoading || !shippingOptions) {
  //   return <Loading />;
  // }
  return (
    <div>
      {noPage && (
        <div className="onepage">
          <div className="container-fluid px-0">
            <Popup
              onNevigate={() => {
                setNoPage(false);
                navigate("/");
              }}
              onClose={() => {
                setNoPage(false);
              }}
              icon={"notice"}
              title={"活動已結束"}
              orangebtn={"回到首頁"}
              greenbtn={""}
            />
          </div>
        </div>
      )}
      {preventCheckout && (
        <div className="onepage">
          <div className="container-fluid px-0">
            <Popup
              onNevigate={() => {
                setPreventCheckout(false);
              }}
              onClose={() => {
                setPreventCheckout(false);
              }}
              icon={"notice"}
              title={"預覽畫面不可結帳"}
              orangebtn={"好的"}
              greenbtn={""}
            />
          </div>
        </div>
      )}
      <form action="">
        <div className="content-section">
          <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
            <h5>運送方式</h5>
          </div>
          <div className="d-flex checkbox-container">
            {shippingOptions &&
              shippingOptions.map((option) => (
                <div
                  key={option.id}
                  className={`d-flex checkbox-group primary-deep2 gap-10px align-items-end mb-1 ${
                    option.disable ? "cursor-pointer" : "disabled"
                  }`}
                  onClick={() =>
                    option.disable &&
                    handleShipOptionChange(
                      option.id,
                      option.areaType,
                      option.shipArea,
                      option.cashondelivery,
                      option.shipCost,
                      option.shipName
                    )
                  }
                >
                  <div
                    className={`btn p-0 checkbox-${
                      selectedshipOption === option.id ? "selected" : "button"
                    }`}
                  >
                    <div className="checkbox-icon default">
                      
                    </div>
                    <div className="checkbox-icon check">
                      
                    </div>
                  </div>
                  <h6 className="pt-0 ">{option.shipName}</h6>
                </div>
              ))}
              {!shippingOptions &&(
                <div

                className={`d-flex checkbox-group primary-deep2 gap-10px align-items-end mb-1 
                  cursor-pointer
                }`}
                
              >
                <div
                  className={`btn p-0 checkbox-button
                  }`}
                >
                  <div className="checkbox-icon default">
                    
                  </div>
                  <div className="checkbox-icon check">
                    
                  </div>
                </div>
                <h6 className="pt-0 ">本島</h6>
              </div>
              )
                
              }

            {/* {cartTotalData && cartTotalData.totalOrdetItemWeight > 20 && (
            <Nodata descript={"超過20公斤不可選擇宅配(外島)"} />
          )} */}
          </div>
          <div className="d-flex flex-column gap-10px">
            {shippingOptions &&
              shippingOptions.map((option) => (
                <div key={option.id}>
                  {option.message && (
                    <div className="pb-2">
                      <Nodata
                        icon={"noicon"}
                        blockdescript={`${option.message}超過20公斤不可選擇宅配(外島)。`}
                      />
                    </div>
                  )}
                </div>
              ))}
          </div>

          {selectedShipArea && selectedAreaType && (
            <>
              {/* selectedAreaType可以過濾本島還是外島 */}
              {
                <NamePhoneLabel
                  onNamePhoneChange={(name, phone, telephone) => {
                    console.log("改變的子層", "name, phone, telephone");
                    setReciptName(name);
                    setReciptPhone(phone);
                    setReciptTele(telephone);
                  }}
                  isNamePhoneValid={(isValid) => {
                    console.log("父層接受到的格式", "isValid");
                    setNamePhoneValid(isValid);
                    //TODO:if isvalid won't send form data
                  }}
                  errornone={true}
                />
              }
              {
                <>
                  <label htmlFor="email" className="form-label d-block pt-3">
                    <h6 className="primary-deep2">信箱(必填)</h6>
                  </label>
                  {renderInputField(
                    "email",
                    "email",
                    "email",
                    "form-input",
                    "請輸入常用信箱",
                    email,
                    handleEmailChange,
                    () => {
                      setEmailonBlur(true);
                    },
                    () => {
                      setEmailonBlur(false);
                    },
                    5,
                    254
                  )}

                  {errorInput(
                    emailOnblur,
                    isValidEmail(email),
                    "same-email",
                    "請輸入正確的信箱",
                    "d-block",
                    "d-none"
                  )}
                </>
              }
              <div className="form-label d-block pt-3">
                <h6 className="primary-deep2">收件地址(必填)</h6>
              </div>
              {
                <Checkoutshipping
                  initPostcode={""}
                  onPostcodeChange={(country, township, postcode) => {
                    console.log("country, township, postcode");
                    setReciptePostcode(postcode);
                    setRecipteCountry(country);
                    setRecipteTownship(township);
                  }}
                  itemAddress={""}
                  onAddressChange={(address) => {
                    console.log("address", "address");
                    setRecipteAddress(address);
                  }}
                  selectedAreaType={selectedAreaType}
                  isTWZipCodeValid={(isValid) => {
                    console.log("父層接受到的地址格式", "isValid");
                    setAddressValid(isValid);
                  }}
                />
              }
            </>
          )}
          <div className="content-section">
            <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
              <h5>付款方式(必填)</h5>
            </div>
            <div className="checkbox-container">
              {paymentoptions.map((option) => (
                <div key={option.value}>
                  <div
                    className="d-flex cursor-pointer primary-deep2 gap-10px align-items-end mb-1"
                    onClick={() => handlePaymentOptionChange(option.value)}
                  >
                    <div
                      className={`btn p-0 checkbox-${
                        paymentOption === option.value ? "selected" : "button"
                      } `}
                    >
                      <div className="checkbox-icon default">
                        
                      </div>
                      <div className="checkbox-icon check">
                       
                      </div>
                    </div>
                    <h6 className="pt-0 pt-xxl-1">{option.label}</h6>
                  </div>
                  {option.danger ? (
                    <h6 className="p danger ps-0 ps-xxl-4">{option.danger}</h6>
                  ) : (
                    ""
                  )}
                  {option.dangerSecond ? (
                    <p className="danger ps-0 ps-xxl-4 pe-xxl-5">
                      {option.dangerSecond}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className="container px-0">
            <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
              <h5>發票資料</h5>
            </div>
            <InvoiceSelect
                onInvoiceChange={(
                  newInvoiceType,
                  newBusinessNumber,
                  newInvoiceTitle
                ) => {
                  setInvoiceType(newInvoiceType);
                  // console.log("newInvoiceType", newInvoiceType);
                  if (newInvoiceType == "3") {
                    // console.log("newBusinessNumber", newBusinessNumber);
                    // console.log("newInvoiceTitle", newInvoiceTitle);
                    setBusinessNumber(newBusinessNumber);
                    setInvoiceTitle(newInvoiceTitle);
                  }
                }}
                isInvoiceValid={(isValid) => {
                  console.log("父層接受到的格式isInvoiceValid", "isValid");
                  setIsInvoiceValid(isValid);
                }}
                noChangeOk={true}
              />
            <div className="login-title pt-3 pb-2 pt-xxl-3 pb-xxl-2">
              <h5>備註</h5>
            </div>

            <textarea
              name="form-ordermemo"
              id="ordermemo"
              placeholder="請輸入備註訊息"
              className="form-input"
              cols="30"
              rows="5"
              value={orderMemo}
              onChange={handleOrderMemoChange}
            ></textarea>
          </div>

          <div className="container px-0 pt-3">
            <div className="primary-light3-bg primary-deep2 d-flex flex-column p-3 gap-0 gap-xxl-2">
              <div className="d-xxl-flex gap-5 justify-content-end">
                {cartTotalPrice && (
                  <div className="d-flex">
                    <h6>訂單內合計金額：</h6>
                    <h6>${cartTotalPrice.toLocaleString()}</h6>
                  </div>
                )}
                {cartTotalPrice && shippingFee !== null && (
                  <div className="d-flex">
                    <h6>運費：</h6>
                    <h6>${shippingFee}</h6>
                  </div>
                )}
                {cartTotalPrice && shippingFee !== null && (
                  <div className="d-flex">
                    <h6>稅金：</h6>
                    <h6>
                      $
                      {Math.ceil(
                        (cartTotalPrice + shippingFee) * 0.05
                      ).toLocaleString()}
                    </h6>
                  </div>
                )}
              </div>
              {cartTotalPrice && shippingFee !== null && (
                <div className="d-xxl-flex gap-5 justify-content-end">
                  <div className="d-flex pt-2 pt-xxl-0 font-weight-bold">
                    <h6 className="font-weight-bold ">總金額(含稅)：</h6>
                    <h6 className="font-weight-bold ">
                      $
                      {Math.ceil(
                        (cartTotalPrice + shippingFee) * 1.05
                      ).toLocaleString()}
                    </h6>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="container px-0 py-5">
          <div
            className={`btn danger-btn w-100 ${
              !selectedshipOption ||
              !isValidEmail(email) ||
              !namePhoneValid ||
              !paymentOption ||
              paymentOption === null ||
              !isInvoiceValid ||
              !addressValid
                ? "disabled"
                : ""
            }`}
            onClick={handleNextButtonClick}
          >
            <h6 className="p">確認結帳</h6>
          </div>
          <div
            className={`pt-2 ${
              !selectedshipOption ||
              !isValidEmail(email) ||
              !namePhoneValid ||
              !paymentOption ||
              paymentOption === null ||
              !isInvoiceValid ||
              !addressValid
                ? "d-block"
                : "d-none"
            }`}
          >
            <Nodata
              icon={"dangernotice"}
              dangerclass={"ps-1"}
              dangerdescript={`尚未填寫${
                selectedAreaType !== null
                  ? ""
                  : !selectedAreaType === null &&
                    reciptName &&
                    reciptPhone &&
                    email &&
                    addressValid &&
                    paymentOption &&
                    isInvoiceValid
                  ? "運送方式"
                  : "運送方式、"
              }${
                reciptName
                  ? ""
                  : !reciptName &&
                    reciptPhone &&
                    email &&
                    addressValid &&
                    paymentOption &&
                    isInvoiceValid
                  ? "姓名"
                  : "姓名、"
              }
                ${
                  reciptPhone
                    ? ""
                    : !reciptPhone &&
                      email &&
                      addressValid &&
                      paymentOption &&
                      isInvoiceValid
                    ? "手機"
                    : "手機、"
                }
                ${
                  email
                    ? ""
                    : !email && addressValid && paymentOption && isInvoiceValid
                    ? "信箱"
                    : "信箱、"
                }
                ${
                  addressValid
                    ? ""
                    : !addressValid && paymentOption && isInvoiceValid
                    ? "地址"
                    : "地址、"
                }
                ${
                  paymentOption
                    ? ""
                    : !paymentOption && isInvoiceValid
                    ? "付款方式"
                    : "付款方式、"
                }
                ${
                  isInvoiceValid || businessNumber
                    ? ""
                    : !businessNumber && invoiceTitle
                    ? "統一編號"
                    : "統一編號、"
                }
                
                ${
                  isInvoiceValid || invoiceTitle
                    ? ""
                    : !invoiceTitle
                    ? "發票抬頭"
                    : ""
                }`}
              bgclass={"bg-white"}
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default OnePageCheckout;
