import React from "react";
import axios from "axios";

function ThirdPartyLogin() {
  const thirdPartyLogin = (method) => {
    const requestData = {
      type: method,
    };
    axios
      .post(`/api/User/SSOLogin`, requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("login link:", "response.data");
          window.location.href = response.data;
        } else {
          console.log("Captcha failed:", response);
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("發送SSOLogin的回應有錯誤：", "error");
        console.log("發送SSOLogin資訊有錯誤", "requestData");
      })
      .finally(() => {
        // setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  return (
    <div className="thirdparty-section">
      <div
        className="thirdparty-box fb-box"
        onClick={() => {
          thirdPartyLogin("Facebook");
        }}
      >
        <div className="icon fb-icon"></div>
      </div>
      <div
        className="thirdparty-box google-box cursor-pointer"
        onClick={() => {
          thirdPartyLogin("Google");
        }}
      >
        <div className="icon google-icon"></div>
      </div>
      <div
        className="thirdparty-box line-box cursor-pointer"
        onClick={() => {
          thirdPartyLogin("Line");
        }}
      >
        <div className="icon line-icon"></div>
      </div>
    </div>
  );
}

export default ThirdPartyLogin;
