//NamePhoneLabel.js
import { useEffect, useState } from "react";

export default function NamePhoneLabel({
  itemName = "",
  itemPhone = "",
  itemTelePhone = "",
  onNamePhoneChange = (name, phone, telephone) => {},
  isNamePhoneValid = (isValid) => {},
  noChangeOk = null,
  phonestyle = "",
  nametitle = "",
  phonetitle = "",
  telephonetitle = "",
  musthavephone = false,
  errornone = null,
}) {
  // 郵遞區號使用字串(數字字串)
  //表單格式
  const [name, setName] = useState("");
  const [hasNameChanged, setHasNameChanged] = useState(false); // 追蹤名稱是否已經修改
  const [phone, setPhone] = useState("");
  const [hasPhoneChanged, setHasPhoneChanged] = useState(false);
  const [telephone, setTelePhone] = useState("");
  const [hasTelePhoneChanged, setHasTelePhoneChanged] = useState(false); //名稱
  const [nameOnBlur, setNameBlur] = useState(false);
  const [phoneOnBlur, setphoneBlur] = useState(false);
  const [teleOnBlur, setTeleBlur] = useState(false);
  const handleNameChange = (e) => {
    const newValue = e.target.value;
    setName(newValue);
    console.log("changename");

    if (newValue !== itemName) {
      console.log("已經修改姓名請記得儲存");
      setHasNameChanged(true); // 名稱已經修改
      // onNamePhoneChange(name, phone, telephone);
    } else {
      setHasNameChanged(false); // 名稱還原成原始值
    }
  };

  const handleQuickFillName = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasNameChanged) {
      setName(itemName || "");
    }
  };
  //手機
  const handlePhoneChange = (e) => {
    const newValue = e.target.value;
    setPhone(newValue);
    console.log("handlePhoneChange");
    if (newValue !== itemPhone) {
      console.log("已經修改手機號碼請記得儲存");
      setHasPhoneChanged(true); // 名稱已經修改
    } else {
      setHasPhoneChanged(false); // 名稱還原成原始值
    }
  };

  const handleQuickFillPhone = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasPhoneChanged) {
      setPhone(itemPhone || "");
    }
  };

  //電話
  const handleTelePhoneChange = (e) => {
    const newValue = e.target.value;
    setTelePhone(newValue);
    console.log("handlePhoneChange");
    if (newValue !== itemTelePhone) {
      console.log("已經修改電話號碼請記得儲存");
      setHasTelePhoneChanged(true); // 名稱已經修改
    } else {
      setHasTelePhoneChanged(false); // 名稱還原成原始值
    }
  };
  const handleQuickFillTelePhone = () => {
    // 只有在名稱已經修改的情況下才填充名稱
    if (!hasTelePhoneChanged) {
      setTelePhone(itemTelePhone || "");
    }
  };

  //格式檢查
  const isValidName = (name) => {
    const nameRegex = /^[\u4e00-\u9fa5]{2,5}$|^[A-Za-z]{1,10}$/;
    return name.trim() !== "" && nameRegex.test(name);
  };
  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09[0-9]{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidTelephone = (telephone) => {
    const regex = /^[0-9\(\)\-#]*$/;

    if (musthavephone) {
      //電話為必填
      if (telephone !== "") {
        return regex.test(telephone);
      } else {
        return false;
      }
    } else {
      //電話不為必填
      if (telephone === "") {
        return true;
      } else {
        return regex.test(telephone);
      }
    }
  };
// useEffect(()=>{
//   console.log("電話格式",isValidTelephone(telephone))
// },[telephone])
  //回傳驗證的格式給父層
  useEffect(() => {
    if (phonestyle === "d-none") {
      if (!telephone || !name) {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      } else if (
        telephone &&
        name &&
        isValidName(name) &&
        isValidTelephone(telephone)
      ) {
        isNamePhoneValid(true);
        console.log("回傳驗證的格式給父層isNamePhoneValid", true);
      } else {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
    } else {
      if (
        !isValidName(name) ||
        !isValidPhoneNumber(phone) ||
        !isValidTelephone(telephone)
      ) {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
      if (
        isValidName(name) &&
        isValidPhoneNumber(phone) &&
        isValidTelephone(telephone) &&
        (hasNameChanged || hasPhoneChanged || hasTelePhoneChanged || noChangeOk)
      ) {
        isNamePhoneValid(true);
        console.log("回傳驗證的格式給父層isNamePhoneValid", true);
      } else {
        isNamePhoneValid(false);
        console.log("回傳驗證的格式給父層isNamePhoneValid", false);
      }
    }
  }, [
    isValidName,
    isValidPhoneNumber,
    isValidTelephone,
    itemName,
    itemPhone,
    itemTelePhone,
  ]);

  //重複的表單

  const renderInputField = (
    id,
    name,
    placeholder,
    value,
    onChange,
    pattern = "",
    onBlur = null,
    onClick = null,
    minLength,
    maxLength
  ) => (
    <input
      type="text"
      id={id}
      name={name}
      className={`form-input ${value ? "prev-input-data" : ""} `}
      pattern={pattern}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onClick={onClick}
      onBlur={onBlur}
      minLength={minLength}
      maxLength={maxLength}
    />
  );
  const errorInput = (hasChanged, isValid, id, errormessage) => (
    errornone === true ? (
      <div
      className={`error-message ${
        hasChanged && !isValid ? "d-block" : "d-none"
      } d-flex pb-0`}
      id={id}
    >
      <div className="icon danger-notice-icon"></div>
      <p className={` danger`}>{errormessage}</p>
    </div>
    ):(
      <div
      className={`error-message ${
        hasChanged && !isValid ? "d-block" : "d-none"
      } d-flex pb-0`}
      id={id}
    >
      <div className="icon danger-notice-icon"></div>
      <p className={` danger`}>{errormessage}</p>
    </div>
    )
    
  );

  //改變的資訊傳回父層
  useEffect(() => {
    onNamePhoneChange(name, phone, telephone);
  }, [name, phone, telephone]);

  //初始填入，預設值
  useEffect(() => {
    if (!hasNameChanged) {
      setName(itemName);
    }
    if (!hasPhoneChanged) {
      setPhone(itemPhone);
    }
    if (!hasTelePhoneChanged) {
      // const isNumeric = /^\d+$/.test(itemTelePhone);
      // if (isNumeric) {
      //   setTelePhone(itemTelePhone);
      // }
      setTelePhone(itemTelePhone);
    }
  }, [itemName, itemPhone, itemTelePhone]);
  return (
    <>
      <label htmlFor="username" className="form-label">
        <h6 className="primary-deep2">
          {nametitle ? nametitle : "收件人姓名"}
        </h6>
        <div className="required-icon"></div>
      </label>

      <div className="try">
        {renderInputField(
          "username",
          "username",
          itemName || `請輸入${nametitle ? nametitle : "收件人"}`,
          name,
          handleNameChange,
          "^[\u4e00-\u9fa5]{2,5}$|^[A-Za-z]{1,10}$",
          () => {
            setNameBlur(true);
          },
          () => {
            setNameBlur(false);
          },
          2,
          50
        )}
      </div>

      {errorInput(
        nameOnBlur,
        isValidName(name),
        "error-username",
        `${nametitle ? nametitle : "收件人"}不得為空，中文請輸入2~5字，英文請輸入4~10字，不可輸入數字或特殊符號`
      )}
      {phonestyle !== "d-none" ? (
        <>
          <label htmlFor="phone-number" className={`form-label`}>
            <h6 className="primary-deep2">
              {phonetitle ? phonetitle : "聯絡手機"}
            </h6>
            <div className="required-icon"></div>
          </label>

          {renderInputField(
            "phone-number",
            "phone-number",
            itemPhone || "輸入常用手機號碼(0912345678)",
            phone,
            handlePhoneChange,
            "[0-9]{10}",
            () => {
              setphoneBlur(true);
            },
            () => {
              setphoneBlur(false);
            },
            10,
            10
          )}


          {errorInput(
            phoneOnBlur,
            isValidPhoneNumber(phone),
            "error-phone-number",
            "請輸入正確的格式(例如：0912345678)"
          )}
        </>
      ) : (
        <></>
      )}

      <label htmlFor="telephone-number" className={`form-label `}>
        <h6 className="primary-deep2">
          {telephonetitle ? telephonetitle : "聯絡市話"}
        </h6>
      </label>
      {renderInputField(
        "telephone-number",
        "telephone-number",
        itemTelePhone || "輸入常用聯絡市話(0212345678)",
        telephone,
        handleTelePhoneChange,
        null,
        () => {
          setTeleBlur(true);
        },
        () => {
          setTeleBlur(false);
        },
        0,
        20
      )}
      {errorInput(
        teleOnBlur,
        isValidTelephone(telephone),
        "error-telephone-number",
        "最多不得超過20字，特殊符號僅允許()-#"
      )}
    </>
  );
}
