import React, { useEffect, useState } from "react";
import axios from "axios";
import Loading from "./Loading";
import CollectionCard from "../sharedComponents/CollectionCard";
import Breadcrumbs from "./Breadcrumbs";

function AllCollections() {
  const [apiSection, setApiSection] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategory = async () => {
    try {
      const response = await axios.get(`api/Home/Section`);
      if (response.status === 200) {
        const data = response.data;
        const filteredData = data.filter((item) => item.code === "collection");
        console.log("collection", "filteredData");
        setApiSection(filteredData);
      }
    } catch (error) {
      console.error("Error fetching section data from API:", "error");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="page-background collection-background">
      <div className="allcollection ">
        <Breadcrumbs />
        <div className="share-page allcollection-page">
          <div className="title-box">
            <h3 className="title">產品分類</h3>
          </div>
          {apiSection.map((item, index) => (
            <div className="category-container" key={`collection-${index}`}>
              <div className="category-content">
                <div className="category-row">
                  {item.content &&
                    item.content.map((value, valueIndex) => (
                      <div
                        key={`collection-${index}-${valueIndex}`}
                        className="collection-card"
                      >
                        <CollectionCard content={value} />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AllCollections;
