// ProductProcessing.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector} from "react-redux";
import {  selectOptionName, selectOptionSecondName, selectProcessingName } from "../../redux/selectors/selectors";

import {
  setSelectedProcessingForPrice,
  setAdditionalOptionSelected,
  setSelectedOptionName 
} from "../../redux/actions/action";
import Nodata from "../../sharedComponents/Nodata";
function ProductProcessing() {
  const [selectedProcessing, setSelectedProcessing] = useState([]);
  const [loading, setLoading] = useState(true); // 新增 loading 状态
  const dispatch = useDispatch();
  const selectedOptionForPrice = useSelector(
    (state) => state.product.selectedOptionForPrice
  );
  const selectedProcessingForPrice  = useSelector(
    (state) => state.product.selectedProcessingForPrice
  );
  const optionName = useSelector(selectOptionName);
  const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);

  const skuProcessing = selectedOptionForPrice ? selectedOptionForPrice.skuProcessing : {};
console.log("skuProcessing","skuProcessing")
  //   const skuProcessing = {
//     0
//     :   {"processingName": "單向排氣閥",
//     "unit": "片",
//     "unitPrice": 1.5},
//     1
//     :   {"processingName": "選項2",
//     "unit": "片",
//     "unitPrice": 2},
// } //多個勾選的資料情況
const handleToggleCheckbox = (processingKey, processingData) => {
  if (isSelected(processingKey)) {
    setSelectedProcessing((prevSelected) =>
      prevSelected.filter((item) => item.key !== processingKey)
    );
    dispatch(setSelectedProcessingForPrice(selectedProcessingForPrice.filter((item) => item.key !== processingKey)));
  } else {
    setSelectedProcessing((prevSelected) => [
      ...prevSelected,
      { key: processingKey, data: processingData },
    ]);
    dispatch(setSelectedProcessingForPrice([...selectedProcessingForPrice, { key: processingKey, data: processingData }]));
  }
};

const isSelected = (processingKey) => {
  return selectedProcessingForPrice.some((item) => item.key === processingKey);
};

useEffect(() => {
  setSelectedProcessing([]);
}, [selectedOptionForPrice]);

useEffect(() => {
  dispatch(setSelectedProcessingForPrice(selectedProcessing));
  const processingNames = selectedProcessing.map((item) => item.data.processingName);
  dispatch(setSelectedOptionName(optionName, optionSecondName, processingNames));
}, [dispatch, selectedProcessing]);

useEffect(() => {
  if (skuProcessing) {
    setLoading(false); // 数据加载完成后设置 loading 为 false
  }
}, [skuProcessing]);

  return (
    <>
{!loading && skuProcessing && Object.keys(skuProcessing).length > 0 && (
  <div className="processing-section">
    <div className="select-result">
      <div className="result-title">
        <h5 >加購：</h5>
       
      </div>
     {processingName &&  <p className="search">{processingName || ''}</p>}
    </div>
    <div className="processing-container">
      {Object.keys(skuProcessing).map((processingKey, index) => (
        <div
        className={`processing-box ${
          isSelected(processingKey) ? "box-checked" : "box-default"
        }`}

          // className="d-flex primary-deep2 align-items-end align-items-xxl-center mb-1 mb-xxl-5"
          key={processingKey}
          onClick={() =>
            handleToggleCheckbox(
              processingKey,
              skuProcessing[processingKey]
            )
          }
        >
          <div
            className={`btn checkbox-button ${
              isSelected(processingKey) ? "button-checked" : "button-default"
            }`}

          >
            <div
              className={`checkbox-icon ${
                isSelected(processingKey) ? "checked" : "default"
              }`}
            >
              
            </div>
           
          </div>
          <p className="processing-name">
            {skuProcessing[processingKey]?.processingName || ''}
          </p>
         
        </div>
      ))}
      
    </div>
    <div className="processing-notice">
    <Nodata dangerdescript={"加購商品無法貨到付款、超商取貨"} icon={"dangernotice"} />

    </div>
  </div>
)}

    </>
  );
}

export default ProductProcessing;