import React, { useEffect, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import NavMenu from "./NavMenu";
import Breadcrumbs from "./Breadcrumbs";
import Footer from "./Footer";
import SideButton from "./SideButton";
import LineButton from "./LineButton"

import AuthContext from "../Context/AuthContext";
import axios from "axios";

const Layout = (props) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const isOnePage = location.pathname.includes("/onepage");
  const isInvoice = location.pathname.includes("/invoice");
  const isProduct = location.pathname.includes("/product");
  // console.log("isInvoice", isInvoice)
  const { myAuth } = useContext(AuthContext);

  const excludedPaths = ["/"];
  const showBreadcrumbs = !excludedPaths.includes(currentPath);

  //更改metadata
  const [metaData, setMetaData] = useState({
    description: "",
    storeName: "",
    ogUrl: "",
    urltitle: "",
    favicon: "",
  });
  const [lineLink, setLineLink] = useState("");

  useEffect(() => {
    axios
      .get("api/Home/StoreData")
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          console.log("撈取商家資料", data);
          setMetaData({
            keywords: data.metakeyword || "",
            description: data.metadesc || "",
            storeName: data.storename || "",
            ogUrl: data.selfdomain || "",
            urltitle: data.urltitle || "",
            favicon: data.favicon || "",
          });
          setLineLink(data.socialLine)
         
        }
      })
      .catch(() => {
        console.error("Error fetching data from API");
      });
  }, []);

  // 更改metadata
  useEffect(() => {
    const titleElement = document.querySelector("title");

    if (titleElement) {
      switch (location.pathname) {
        case "/":
          titleElement.textContent = metaData.urltitle;
          break;
        case "/collections":
          titleElement.textContent = "商品分類" + " | " + metaData.urltitle;
          break;
        case "/blogs":
          titleElement.textContent = "最新消息" + " | " + metaData.urltitle;
          break;
        case "/faqs":
          titleElement.textContent = "常見問題" + " | " + metaData.urltitle;
          break;
        case "/contactus":
          titleElement.textContent = "聯絡我們" + " | " + metaData.urltitle;
          break;
        case "/sample":
          titleElement.textContent = "索取樣袋" + " | " + metaData.urltitle;
          break;
        default:
          if (location.pathname.startsWith("/collections/")) {
            const collectionslug = decodeURIComponent(
              location.pathname.split("/").pop()
            );
            titleElement.textContent =
              collectionslug + " | " + metaData.urltitle;
          } else if (location.pathname.startsWith("/product/")) {
            const collectionslug = decodeURIComponent(
              location.pathname.split("/").pop()
            );
            titleElement.textContent =
              collectionslug + " | " + metaData.urltitle;
          } else if (location.pathname.startsWith("/blogs/")) {
            const collectionslug = decodeURIComponent(
              location.pathname.split("/").pop()
            );
            titleElement.textContent =
              collectionslug + " | " + metaData.urltitle;
          } else if (location.pathname.startsWith("/pages/")) {
            const collectionslug = decodeURIComponent(
              location.pathname.split("/").pop()
            );
            titleElement.textContent =
              collectionslug + " | " + metaData.urltitle;
          } else {
            titleElement.textContent = metaData.urltitle;
          }
      }
    }
    if (!isInvoice) {
      const metaAuthor = document.querySelector('meta[name="Author"]');
      const metaDescript = document.querySelector('meta[name="description"]');
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      const faviconLink = document.querySelector('link[rel="shortcut icon"]');
      const appleicon = document.querySelector('link[rel="apple-touch-icon"]');

      const ogUrl = document.querySelector('meta[property="og:url"]');
      const ogSiteName = document.querySelector(
        'meta[property="og:site_name"]'
      );
      const ogTitleElement = document.querySelector(
        'meta[property="og:title"]'
      );
      const ogDescriptionElement = document.querySelector(
        'meta[property="og:description"]'
      );

      // if (faviconLink) {
      //   faviconLink.setAttribute("href", metaData.favicon);
      // }
      // if (appleicon) {
      //   appleicon.setAttribute("href", metaData.favicon);
      // }



      if (ogTitleElement) {
        ogTitleElement.setAttribute("content", titleElement.textContent);
      }


      if (metaAuthor) {
        metaAuthor.setAttribute("content", metaData.storeName);
      }
      //一頁式商店的metaDescript寫在OnePage.js
      //產品資訊頁面的description和og:description寫在productData.js
            
      if (!isProduct &&!isProduct && ogDescriptionElement) {
        ogDescriptionElement.setAttribute("content", titleElement.textContent);
      }
      if (!isOnePage && metaDescript) {
        // metaDescript.setAttribute("content", metaData.description);
        switch (location.pathname) {
          case "/":
            metaDescript.setAttribute("content", metaData.description);
            break;
          case "/collections":
            metaDescript.setAttribute(
              "content",
              "商品分類" + " | " + metaData.description
            );
            break;
          case "/blogs":
            metaDescript.setAttribute(
              "content",
              "最新消息" + " | " + metaData.description
            );
            break;
          case "/faqs":
            metaDescript.setAttribute(
              "content",
              "常見問題" + " | " + metaData.description
            );
            break;
          case "/contactus":
            metaDescript.setAttribute(
              "content",
              "聯絡我們" + " | " + metaData.description
            );
            break;
          case "/sample":
            metaDescript.setAttribute(
              "content",
              "索取樣袋" + " | " + metaData.description
            );
            break;
          default:
            if (location.pathname.startsWith("/collections/")) {
              const collectionslug = decodeURIComponent(
                location.pathname.split("/").pop()
              );
              metaDescript.setAttribute(
                "content",
                collectionslug + " | " + metaData.description
              );
            } else if (location.pathname.startsWith("/product/")) {
              const collectionslug = decodeURIComponent(
                location.pathname.split("/").pop()
              );
              metaDescript.setAttribute(
                "content",
                collectionslug + " | " + metaData.description
              );
            } else if (location.pathname.startsWith("/blogs/")) {
              const collectionslug = decodeURIComponent(
                location.pathname.split("/").pop()
              );
              metaDescript.setAttribute(
                "content",
                collectionslug + " | " + metaData.description
              );
            } else if (location.pathname.startsWith("/pages/")) {
              const collectionslug = decodeURIComponent(
                location.pathname.split("/").pop()
              );
              metaDescript.setAttribute(
                "content",
                collectionslug + " | " + metaData.description
              );
            } else {
              metaDescript.setAttribute("content", metaData.description);
            }
        }
      }
      if (metaKeywords) {
        metaKeywords.setAttribute("content", metaData.keywords);
      }
      if (ogSiteName) {
        ogSiteName.setAttribute("content", metaData.storeName);
      }
      if (ogUrl) {
        ogUrl.setAttribute("content", "https://" + metaData.ogUrl + currentPath);
      }
    }
  }, [metaData, location.pathname]);

  return (
    <>
      {isOnePage || isInvoice ? (
        <>{props.children}</>
      ) : (
        <>
          <NavMenu />
          <div className="h-100vh ">
            <div className="layout-content">
            {/* {showBreadcrumbs && <Breadcrumbs />} */}
            {props.children}
            </div>

          </div>
          <SideButton islogin={myAuth.authorized} />
         {lineLink && <LineButton link={lineLink}/>} 
          <Footer />
        </>
      )}
      
    </>
  );
};

export default Layout;
