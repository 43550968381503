import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Loading from "../Loading";
import Nodata from "../../sharedComponents/Nodata";
import { Link } from "react-router-dom";

function MemberCoupon() {
  const { myAuth, logout } = useContext(AuthContext);
  const [coupondata, setCouponData] = useState(null);
  const [rendercoupondata, setRenderCouponData] = useState("available");
  const [isloading, setIsLoading] = useState(true);
  // const CouponTag = [
  //   {name: "未使用", value:"available"},{name: "已使用", value:"used"}
  // ]
  const CouponTag = [{ name: "未使用", value: "available" }];

  const getCouponData = () => {
    const mid = parseInt(myAuth.sid);
    const storedToken = myAuth.token;
    const requestData = {
      mid: mid,
    };
    axios
      .post(`/api/Member/GetMemberECoupon`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          setCouponData(response.data);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  useEffect(() => {
    console.log("初次進來可以獲得GetMemberECoupon資料");
    getCouponData();
  }, []);
  if (isloading) {
    <Loading />;
  }
  return (
    <div className="member-coupon">
      <div className="member-page-title">
        <h5>折價券</h5>
      </div>
      <div className="title-switch ">
        {CouponTag.map((tag) => (
          <div
            key={tag.value}
            className={`switch-title ${
              tag.value === rendercoupondata ? "selected" : ""
            }`}
            onClick={() => setRenderCouponData(tag.value)}
          >
            <h6>{tag.name}</h6>
          </div>
        ))}
      </div>
      <div className="coupon-section">
        {coupondata &&
          (rendercoupondata === "available" &&
          coupondata.available.length === null ? (
            <Nodata descript={"目前無可使用折價券"} />
          ) : rendercoupondata === "used" && coupondata.used.length === 0 ? (
            <Nodata descript={"目前沒有已使用的折價券資訊"} />
          ) : (
            coupondata[rendercoupondata].map((coupon) => (
              <div key={coupon.id} className="coupon">
                <div className="coupon-left">
                  <div className="svg-wrapper">
                    <div className="coupon-image"></div>
                    <div className="coupon-image-2"></div>
                  </div>
                  <div className="coupon-title">
                    <h6 className="price-title">
                      NT${parseInt(coupon.ecouponPrice).toLocaleString()}
                    </h6>
                    
                  </div>
                </div>
                <div className="coupon-right">
                  <div className="svg-wrapper">
                    <div className="coupon-image"></div>
                    <div className="coupon-image-2"></div>
                  </div>
                  <h4 className="coupon-title">{coupon.ecouponTitle}</h4>
                  <p className="secondary">
                  滿NT${parseInt(coupon.priceLimit).toLocaleString()}以上適用
                    </p>
                  <div className="expiry-day">
                    <div className="expire-title">
                      <p>有效日期：</p>
                    </div>
                    <div className="expire-cate">
                      <p>{new Date(coupon.sDate).toLocaleDateString()}</p>
                      <p>~</p>
                      <p>{new Date(coupon.edate).toLocaleDateString()}</p>
                    </div>
                  </div>
                  {coupon.setitem === "0" ? (
                    <div className="coupon-restrict d-flex">
                      <div className="coupon-product pe-1 pe-xxl-3">
                        <p>*</p>
                        <p>全商品適用</p>
                      </div>
                    </div>
                  ) : (
                    <div className="coupon-restrict d-flex">
                      <div className="coupon-product pe-1 pe-xxl-3">
                        <p>*</p>
                        <p>部分商品適用</p>
                      </div>
                      <Link
                        to={`/activity/ecoupon/${coupon.ecouponSlug}`}
                        className="btn secondary-btn-4 px-0 px-1 py-0"
                      >
                        <p className="m-0">查看折價商品</p>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            ))
          ))}
      </div>
    </div>
  );
}

export default MemberCoupon;
