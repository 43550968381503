import React, { useState, useEffect } from "react";
import MemberDataInfo from "./MemberDataInfo";
import MemberDataInvoice from "./MemberDataInvoice";
import MemberDataShipping from "./MemberDataShipping";
import MemberPw from "./MemberPw";

function MemberData() {
  return (
    <div className="member-data-edit">
      <div className="info-container">
        <MemberDataInfo />
        <MemberPw/>
        
      </div>
      <div className="shipping-container">
      <MemberDataInvoice />
        <MemberDataShipping />

      

      </div>
      
    </div>
  );
}

export default MemberData;
