import axios from "axios";
import React, { useState, useContext, useEffect } from "react";
import Loading from "../components/Loading";
import AuthContext from "../Context/AuthContext";
import Breadcrumbs from "../components/Breadcrumbs";
function ThirdPartyRegister() {
  //手機
  const [phone, setPhone] = useState("");
  const [phoneOnBlur, setPhoneBlur] = useState(false);
  const [phoneNotFound, setPhoneNotFound] = useState(false);

  //若為黑名單等情況SSOMobileCK會有錯誤訊息
  const [phoneError, setPhoneError] = useState(null);
  //captcha
  const [captcha, setCaptcha] = useState("");
  const [captchaOnblur, setCaptchaBlur] = useState(false);
  const [captchaData, setCaptchaData] = useState(null);
  const [isSameCaptcha, setIsSameCaptcha] = useState(true);
  const [verifycaptcha, setVerifyCaptcha] = useState(false);
  //寄送SendVerificationCode出現的錯誤訊息
  const [captchaError, setCaptchaError] = useState(null);
  const [isConnect, setIsConnect] = useState(false); // State to track if the form is submitted

  //驗證
  const [verifysent, setVerifysent] = useState(false);
  const [countdown, setCountdown] = useState(60);
  //舊資料連接
  const [OldData, setOldData] = useState(null);

  //第二步的格式驗證如下:
  //姓名
  const [name, setName] = useState("");
  const [nameOnBlur, setNameBlur] = useState(false);
  //信箱
  const [email, setEmail] = useState("");
  const [emailOnblur, setEmailonBlur] = useState(false);
  //密碼
  const [password, setPassword] = useState("");
  const [passwordOnblur, setPasswordBlur] = useState(false);
  //查看密碼的password
  const [showPassword, setShowPassword] = useState(false);
  //再次確認密碼
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordOnBlur, setConfirmPasswordBlur] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(true);

  //導入authcontext
  const {cartDataTologin} =useContext(AuthContext)
  //檢查格式是否合法
  const isValidPhoneNumber = (phone) => {
    const phoneRegex = /^09\d{8}$/;
    return phoneRegex.test(phone);
  };
  const isValidName = (name) => {
    if (typeof name === "string" || name instanceof String) {
      const nameRegex = /^[A-Za-z0-9\u4e00-\u9fa5]+$/;
      // 确保字符串不为空
      return name.trim() !== "" && nameRegex.test(name);
    }
    return false; // 如果 name 不是字符串，返回 false
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    return emailRegex.test(email);
  };

  const isValidPassword = (password) => {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,20}$/;
    return passwordRegex.test(password);
  };
  //監聽input value change
  
  const handlePhoneNumberChange = (e) => {
    setPhone(e.target.value);
    setPhoneError(null);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  //查看password
  const togglePasswordVisibility = (e) => {
    e.preventDefault(); // 阻止默認行為
    setShowPassword(!showPassword);
  };
  //再次輸入密碼
  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  //避免惡意輸入
  const escapeHTML = (unsafeText) => {
    return unsafeText.replace(/</g, "&lt;").replace(/>/g, "&gt;");
  };

  //確認格式是否正常

  const handlePhoneCheck = async (e) => {
    e.preventDefault();
    if (!phone || !isValidPhoneNumber(phone) || verifysent) {
      console.log("handlePhoneCheck return");
      return;
    }
    sendPhoneNumber();
    setVerifysent(true);
    // handleCaptchaSend();
  };
  const handleCaptchaChange = (e) => {
    setCaptcha(e.target.value);
    setVerifyCaptcha(false);
    setIsSameCaptcha(true);
  };

  //確認手機號碼是否可以發送(不為黑名單)
  const sendPhoneNumber = () => {
    const requestData = { mobile: phone };
    axios
      .post("api/User/SSOMobileCK", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        handleCaptchaSend();
        console.log("Form Register Check submitted:", "response.data");
      })
      .catch((error) => {
        console.log("Error checking availability");
        if (error) {
          console.error("寄送SSOMobileCK出現的錯誤訊息", "error");
          console.error(
            "寄送SSOMobileCK出現的錯誤訊息",
            "error.response.request.response"
          );
          setPhoneError("手機無法使用");
          console.log("出現錯誤");
        }
      });
  };

  //發送驗證碼
  const handleCaptchaSend = () => {
    const requestData = { mobile: phone.toString() };

    axios
      .post("/api/User/SendVerificationCode", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SendVerificationCode", "responseData");
        console.log("傳過去的資料SendVerificationCode", "requestData");
        console.log("verificationCode", "responseData.verificationCode");
        setCaptchaData(responseData.verificationCode);
        setVerifysent(true);
      })
      .catch((error) => {
        console.error("SendVerificationCode有錯誤：", "error");
        console.log("傳過去的SendVerificationCode有錯誤", "requestData");
        console.error(
          "寄送SendVerificationCode出現的錯誤訊息",
         " error.response.data.message"
        );
        setPhoneError("簡訊驗證碼已達每日發送上限。");
      });
  };
  //發送步驟一
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (phoneError) {
      console.log("phoneError", "phoneError");
      return;
    }

    //驗證碼是否一樣?
    if (captcha !== captchaData.toString()) {
      console.log("captcha", "captcha");
      console.log("captchaData", "captchaData");
      setIsSameCaptcha(false);
      setVerifyCaptcha(true);
      return;
    }
    setIsConnect(true);
    console.log("check");
    connectOriginData();
  };
  //尋找資料庫中是否有持有此手機號碼的會員相關資料
  const connectOriginData = () => {
    const requestData = { mobile: phone.toString() };

    axios
      .post("/api/User/SSOUserData", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const responseData = response.data;
        console.log("回傳的SSOUserData", "responseData");
        console.log("傳過去的資料SSOUserData", "requestData");
        setOldData(responseData);
      })
      .catch((error) => {
        console.error("SSOUserData有錯誤：", "error");
        console.log("傳過去的SSOUserData有錯誤", "requestData");
        console.error(
          "寄送SSOUserData出現的錯誤訊息",
          "error.response.data.message"
        );
      });
  };

//將第三方發送到後端
const PostSubmit =() =>{
  const requestData = {
    mobile: escapeHTML(phone.toString()),
    email: escapeHTML(email),
    membername: escapeHTML(name),
    MemberType: "L",
   }

  axios
    .post("/api/User/SSORegister", requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      const responseData = response.data;
      console.log("回傳的SSORegister", "responseData");
      console.log("傳過去的資料SSORegister", "requestData");
      cartDataTologin(responseData)
    })
    .catch((error) => {
      console.error("SSORegister有錯誤：", "error");
      console.log("傳過去的SSORegister有錯誤", "requestData");
      console.error(
        "寄送SSORegister出現的錯誤訊息",
        "error.response.data.message"
      );
    });
}

  //完成註冊
  const handleFinishRegister = (e) => {
    e.preventDefault();
    console.log("handleFinishRegister");
    PostSubmit();
  };
  //阻擋重複發送請求
  useEffect(() => {
    if (verifysent) {
      const countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 1) {
            return prevCountdown - 1;
          } else {
            clearInterval(countdownInterval);
            setVerifysent(false); // 倒數計時結束
            return 60; // 重置為60
          }
        });
      }, 1000);

      return () => {
        clearInterval(countdownInterval);
      };
    } else {
      setCountdown(60);
    }
  }, [verifysent]);
      //檢驗取得的OldData
useEffect(()=>{
if(OldData){
setEmail(OldData.email);
setName(OldData.memberName)
}
},[OldData])

  //重複的表單

  const renderInputField = (
    id,
    type,
    name,
    classname,
    placeholder,
    value,
    onChange,
    onBlur = null,
    onClick = null,
    minLength,
    maxLength,
    readonly
  ) => (
    <input
      type={type}
      id={id}
      name={name}
      className={classname}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      onBlur={onBlur}
      onClick={onClick}
      minLength={minLength}
      maxLength={maxLength}
      readOnly={readonly}
    />
  );
  const errorInput = (hasData, isValid, id, errormessage, show, notshow) => (
    <div
      className={`error-message ${
        hasData && !isValid ? show || "visible" : notshow || "invisible"
      } d-flex pb-0`}
      id={id}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="19"
        viewBox="0 0 21 19"
        fill="none"
        className="pt-1"
      >
        <path
          d="M0.98903 18.5C0.805697 18.5 0.63903 18.454 0.48903 18.362C0.33903 18.27 0.222364 18.1493 0.139031 18C0.0556972 17.85 0.00969715 17.6873 0.00103049 17.512C-0.00763618 17.3367 0.0383638 17.166 0.139031 17L9.38903 1C9.48903 0.833333 9.61836 0.708333 9.77703 0.625C9.9357 0.541667 10.098 0.5 10.264 0.5C10.4307 0.5 10.5934 0.541667 10.752 0.625C10.9107 0.708333 11.0397 0.833333 11.139 1L20.389 17C20.489 17.1667 20.535 17.3377 20.527 17.513C20.519 17.6883 20.473 17.8507 20.389 18C20.3057 18.15 20.189 18.271 20.039 18.363C19.889 18.455 19.7224 18.5007 19.539 18.5H0.98903ZM2.71403 16.5H17.814L10.264 3.5L2.71403 16.5ZM10.264 15.5C10.5474 15.5 10.785 15.404 10.977 15.212C11.169 15.02 11.2647 14.7827 11.264 14.5C11.264 14.2167 11.168 13.979 10.976 13.787C10.784 13.595 10.5467 13.4993 10.264 13.5C9.9807 13.5 9.74303 13.596 9.55103 13.788C9.35903 13.98 9.26336 14.2173 9.26403 14.5C9.26403 14.7833 9.36003 15.021 9.55203 15.213C9.74403 15.405 9.98136 15.5007 10.264 15.5ZM10.264 12.5C10.5474 12.5 10.785 12.404 10.977 12.212C11.169 12.02 11.2647 11.7827 11.264 11.5V8.5C11.264 8.21667 11.168 7.979 10.976 7.787C10.784 7.595 10.5467 7.49933 10.264 7.5C9.9807 7.5 9.74303 7.596 9.55103 7.788C9.35903 7.98 9.26336 8.21733 9.26403 8.5V11.5C9.26403 11.7833 9.36003 12.021 9.55203 12.213C9.74403 12.405 9.98136 12.5007 10.264 12.5Z"
          fill="#DC3545"
        />
      </svg>
      <p className={`ps-1 danger`}>{errormessage}</p>
    </div>
  );

  return (
    <div className="container thirdpartyregister">
      <Breadcrumbs/>
      <div className=" row px-xxl-0 d-flex justify-content-center">
        <div className="col col-lg-6 col-xxl-5 section-card p-4 d-flex flex-column gap-4">
          <div className="section-title text-center black border-bottom-light pb-2">
            <h5>繼續完成註冊</h5>
          </div>
          <div className="d-block">
            <form
              className="d-flex flex-column gap-4"
              onSubmit={handleFormSubmit}
            >
              <div>
                <label htmlFor="phone-number" className="form-label d-block">
                  <h6 className="primary-deep2">手機號碼</h6>
                </label>
                {renderInputField(
                  "phone-number",
                  "text",
                  "tel",
                  "form-input",
                  "輸入手機號碼(0912345678)",
                  phone,
                  handlePhoneNumberChange,
                  () => {
                    setPhoneBlur(true);
                  },
                  () => {
                    setPhoneBlur(false);
                    setPhoneNotFound(false);
                  },
                  10,
                  10,
                  isConnect
                )}
                {errorInput(
                  phoneOnBlur,
                  isValidPhoneNumber(phone),
                  "error-phonenumber",
                  "請輸入正確的格式(例如：0912345678)",
                  " d-block",
                  "d-none"
                )}
                {errorInput(
                  isValidPhoneNumber(phone) && phoneError,
                  !phoneOnBlur,
                  "error-phone-avaliable",
                  phoneError,
                  "d-block",
                  "d-none"
                )}
              </div>
              <div className={`${isConnect ? "d-none" : "d-block"}`}>
                <div className="capcha-box">
                  <div className="capcha-foriput">
                    <label
                      htmlFor="phonecaptcha"
                      className="form-label d-block"
                    >
                      <h6 className="primary-deep2">驗證碼(必填)</h6>
                    </label>
                    {renderInputField(
                      "phonecaptcha",
                      "phonecaptcha",
                      "text",
                      "form-input",
                      "請輸入手機驗證碼",
                      captcha,
                      handleCaptchaChange,
                      () => {
                        setCaptchaBlur(true);
                      },
                      () => {
                        setCaptchaBlur(false);
                      }
                    )}
                  </div>

                  <div className="capcha-photo pt-2">
                    <button
                      onClick={handlePhoneCheck}
                      className="btn primary-btn"
                      disabled={
                        !phone ||
                        !isValidPhoneNumber(phone) ||
                        verifysent ||
                        phoneNotFound
                      }
                    >
                      <p className="p2">
                        {verifysent ? `發送驗證碼(${countdown})` : "發送驗證碼"}
                      </p>
                    </button>
                  </div>
                </div>
                {errorInput(
                  captchaOnblur && verifycaptcha,
                  isSameCaptcha,
                  "error-pwd",
                  "請輸入正確的驗證碼"
                )}
              </div>

              <button
                className={`${
                  isConnect ? "d-none" : "d-block"
                } btn primary-btn2 w-100`}
                type="submit"
                disabled={
                  !phone ||
                  !isValidPhoneNumber(phone) ||
                  phoneNotFound ||
                  !captcha ||
                  !captchaData ||
                  !isSameCaptcha
                }
              >
                下一步
              </button>
            </form>
            {/* <button onClick={handleNext}>下一步</button> */}
          </div>
          <div className={`${
                  isConnect ? "d-block " : "d-none"
                } `}>
            <form
              className="d-flex flex-column gap-4"
              onSubmit={handleFinishRegister}
            ><div>
              
                <label htmlFor="email" className="form-label d-block">
                  <h6 className="primary-deep2">信箱(必填)</h6>
                </label>
                {renderInputField(
                  "email",
                  "email",
                  "email",
                  "form-input",
                  "請輸入常用信箱",
                  (OldData && OldData.email) || email,
                  handleEmailChange,
                  () => {
                    setEmailonBlur(true);
                  },
                  () => {
                    setEmailonBlur(false);
                  },
                  3,
                  254,
                  (OldData && OldData.email) 
                  
                )}
                {errorInput(
                  emailOnblur,
                  isValidEmail(email),
                  "same-email",
                  "請輸入正確的信箱",
                  "d-block",
                  "d-none"
                )}
            </div>
              <div>
                <label htmlFor="username" className="form-label d-block">
                  <h6 className="primary-deep2">姓名(必填)</h6>
                </label>
                {renderInputField(
                  "username",
                  "text",
                  "username",
                  "form-input",
                  "輸入姓名",
                  (OldData && OldData.memberName
                    ) || name,
                  handleNameChange,
                  () => {
                    setNameBlur(true);
                  },
                  () => {
                    setNameBlur(false);
                  },
                  1,
                  50,
                 (OldData && OldData.memberName) 
                )}
                {errorInput(
                  nameOnBlur,
                  isValidName(name),
                  "error-username",
                  "請輸入正確的名稱，不得為空且不得包含特殊符號",
                  "d-block",
                  "d-none"
                )}
              </div>

              <button
                className={`btn primary-btn2 w-100`}
                type="submit"
                disabled={
                  
                  !isValidEmail(email) ||
                  !isValidName(name) 
                }
              >
                完成註冊
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdPartyRegister;
