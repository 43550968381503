// CollectionCard.js
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredTag } from "../redux/actions/action";
function getTagByCollectionIcon(prodIcon) {
  switch (prodIcon) {
    case 1:
      return (
        // New
        <div className="product-tag product-new"></div>
      );
    case 2:
      // Hot
      return <div className="product-tag product-hot"></div>;
    case 3:
      return (
        // Sale
        <div className="product-tag product-sale"></div>
      );
    default:
      return  null; // 當 prodIcon 不是 1、2、3 時 回傳 null
  }
}

function CollectionCard(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //console.log('content', "props");
  const parsedProdIcon = parseInt(props.content.cateIcon) || 0;
  const tag = getTagByCollectionIcon(parsedProdIcon);
  //console.log("parsedProdIcon","parsedProdIcon");// 渲染對應的行銷tag

  const handleChildClick = (navurl) => {
    if (navurl.includes("collections")) {
      const sessionValue = navurl.replace("collections/", "").replace("/", "");

      sessionStorage.setItem("collectiontag", sessionValue);

      dispatch(setFilteredTag(null));
      console.log("sessionValue", "sessionValue");
      navigate(navurl);
    }
  };
  // console.log('content', props.content.title);
  //   console.log('content', props.content.image);

  return (
    <div
      className="cursor-pointer"
      onClick={() => handleChildClick(`/collections/${props.content.slug}`)}
    >
      <div className="content">
        <div className="img-box">
        <img src={props.content.image} alt="" />
        </div>
        
        <div className="title">
          <div className="category-tag-svg">{tag}</div>   
          <h5 className="category-title">{props.content.title}</h5>
        </div>
      </div>
    </div>
  );
}

export default CollectionCard;
