//ProductFirstSelect
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  setSelectedOption,
  setSelectedOptionSecond,
  setSelectedOptionForPrice,
  setSelectedOptionName,
} from "../../redux/actions/action";
import {
  selectOptionName,
  selectOptionSecondName,
  selectProcessingName,
} from "../../redux/selectors/selectors";
import ProductProcessing from "./ProductProcessing";
import axios from "axios";
import Loading from "../Loading";

function ProductFirstSelect({ specname, option, tosetprice, onePage }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [firstStage, setFirstStage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [openMore, setOpenmore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [renderOption, setRenderOption] = useState(null);
  //快速點擊禁止
  const [clickDisabled, setClickDisabled] = useState(false);

  const pageSize = 10;
  const dispatch = useDispatch();
  const selectedOption = useSelector((state) => state.product.selectedOption);
  const selectedOptionSecond = useSelector(
    (state) => state.product.selectedOptionSecond
  );
  // const selectedOptionData = useSelector((state) => ({
  //   optionName: state.product.optionName,
  //   optionSecondName: state.product.optionSecondName,
  //   processingName: state.product.processingName,
  // }), shallowEqual);
  const optionName = useSelector(selectOptionName);
  const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);
  console.log("onePage", "onePage");
  const firstStageData = async () => {
    setIsLoading(true);
    if (onePage) {
      try {
        const response = await axios.post(
          "api/Onepage/GetOnepageSkuPrice",
          {
            OSkuid: parseInt(firstStage),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setRenderOption(response.data);
        console.log("取得的資料", "response.data");
        console.log("單層");
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
      } finally {
      }
    } else {
      try {
        const response = await axios.post(
          "api/Product/ProductDetail/SkuPrice",
          {
            skuid: parseInt(firstStage),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        setRenderOption(response.data);
        console.log("***firstStage",firstStage)
        console.log("取得的資料", "response.data");
        console.log("單層");
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
      } finally {
      }
    }
  };

  //單層初次載入，會取得redux儲存的selectedOption和父層傳入的tosetprice
  useEffect(() => {
    if (selectedOption && tosetprice) {
      setFirstStage(selectedOption);
      console.log("selectedOption", "selectedOption");
      //只有單層才會觸發
      dispatch(setSelectedOptionSecond(selectedOption));
    }
  }, [selectedOption, tosetprice]);

  //接收到單層資料，由此元件撈取資料庫的sku資料，然後設置在renderoption之中
  useEffect(() => {
    if (firstStage) {
      firstStageData();
    }
  }, [firstStage]);
  //只有單層會接收到renderOption
  useEffect(() => {
    if (renderOption) {
      const { firstSkuData, skuProcessing, discountData } = renderOption;
      dispatch(
        setSelectedOptionForPrice({
          firstSkuData,
          skuProcessing,
          discountData,
        })
      );
    }
    setIsLoading(false);
    // console.log("單層", renderOption.discountData);
  }, [renderOption]);

  const handleOptionClick = (index, firstoptionName, viewall) => {
    console.log("按下", "firstoptionName");

    setIsLoading(true);

    let absoluteIndex;
    if (!viewall) {
      absoluteIndex = (currentPage - 1) * pageSize + index;
    } else {
      absoluteIndex = index;
    }
    setSelectedOptionIndex(absoluteIndex);
    const selectedOptionId = option[index].specOptionId;

    dispatch(setSelectedOption(selectedOptionId));
    dispatch(
      setSelectedOptionName(firstoptionName, optionSecondName, processingName)
    );

    // if (renderOption) {
    //   const { firstSkuData, skuProcessing } = renderOption;
    //   dispatch(setSelectedOptionForPrice({ firstSkuData, skuProcessing }));
    // }
    setOpenmore(false);
    if (selectedOptionId) {
      setIsLoading(false);
    }
  };

  const handlePrevPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const maxPage = Math.ceil(option.length / pageSize);
    if (currentPage < maxPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const toggleMoreOption = (e) => {
    e.preventDefault();
    setOpenmore((prevOpenMore) => !prevOpenMore);
  };

  const displayedOptions = option.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const showPagination = option.length > pageSize;
  const showPhonePage = option.length > 6;
  const isPrevDisabled = currentPage === 1;
  const isNextDisabled = currentPage === Math.ceil(option.length / pageSize);
  console.log("showPagination", "showPagination");
  //useEffect表示預設值
  useEffect(() => {
    if (option.length > 0 && !isLoading) {
      const initialSelectedOptionIndex =
        option[selectedOptionIndex].specOptionId;
      const initialSelectedOptionName =
        option[selectedOptionIndex].specOptionName;

      dispatch(
        setSelectedOptionName(
          initialSelectedOptionName,
          optionSecondName,
          processingName
        )
      );
      dispatch(setSelectedOption(initialSelectedOptionIndex));
      console.log("預設值");
    }
  }, [dispatch, selectedOptionIndex, selectedOption, option, isLoading]);
  if (isLoading) {
    return <Loading height={"h-auto"} />;
  }
  return (
    <>
      {openMore && (
        <div
          className="popup-size"
          
        >
          <div className="popup-page">
            {Array.from({ length: Math.ceil(option.length / pageSize) }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`point ${
                    currentPage === index + 1 ? "select" : ""
                  }`}
                  onClick={() => setCurrentPage(index + 1)}
                />
              )
            )}
          </div>
          <div
            className={`popup-select ${
              showPagination ? "" : "justify-content-center"
            }`}
            onClick={() => {
              setOpenmore(false);
            }}
          >
            {showPagination && (
              <div
                className={`page-left ${
                  isPrevDisabled ? "page-disabled" : "page-control"
                }`}
                onClick={handlePrevPage}
              >
                <div className="prev-icon"></div>
              </div>
            )}

            <div className={`pc-content page-content`}>
              <div className="overflow-wrap">
                <div className="overflow">
                  {option.map((item, index) => (
                    <div
                      key={item.specOptionId}
                      className={`option ${
                        selectedOptionIndex === index ? "select" : ""
                      }`}
                      onClick={() =>
                        clickDisabled
                          ? null
                          : handleOptionClick(index, item.specOptionName, true)
                      }
                    >
                      <h6>{item.specOptionName}</h6>
                    </div>
                  ))}
                </div>
                <div className="page-title">
                  <h6>選擇尺寸：</h6>
                  <p className="primary-deep2">
                    {option[selectedOptionIndex].specOptionName}
                  </p>
                </div>
              </div>
            </div>
            <div className="phone-content">
              <div className="overflow-wrap">
                <div className="overflow">
                  {displayedOptions.map((item, index) => (
                    <div
                      key={item.specOptionId}
                      className={`option ${
                        selectedOptionIndex ===
                        (currentPage - 1) * pageSize + index
                          ? "select"
                          : ""
                      }`}
                      onClick={() =>
                        clickDisabled
                          ? null
                          : handleOptionClick(index, item.specOptionName, false)
                      }
                    >
                      <p>{item.specOptionName}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {showPagination && (
              <div
                className={`page-right ${
                  isNextDisabled ? "page-disabled" : "page-control"
                }`}
                onClick={handleNextPage}
              >
                <div className="next-icon"></div>
              </div>
            )}
          </div>
          <div className="btn-group">
            <div className="close-pop" onClick={toggleMoreOption}>
              <h6>關閉視窗</h6>
            </div>
          </div>
          <div className="cross-icon-box">
            <div className="cross-icon icon"></div>
          </div>

          {/* <button className="btn primary-btn2" onClick={toggleMoreOption}>
            <h6 className="d-xxl-none">選擇</h6>
            <h6>確認</h6>
          </button> */}
        </div>
      )}
      <div className={`first-select-container`}>
        <div className={`select-result`}>
          <div className="d-flex text-nowrap">
            {/* <h6 className="pe-0 d-none d-xxl-block ">選擇</h6> */}
            <h6>選擇</h6>
            <h6>{specname}</h6>
            <h6>：</h6>
            {/* <h6 ></h6> */}
          </div>
          {option && option[selectedOptionIndex].specOptionName && (
            <p className="search">
              {option[selectedOptionIndex].specOptionName}
            </p>
          )}
        </div>
        {showPagination && onePage && (
          <div className="popup-page align-items-center w-auto">
            {Array.from({ length: Math.ceil(option.length / pageSize) }).map(
              (_, index) => (
                <div
                  key={index}
                  className={`point ${
                    currentPage === index + 1 ? "select" : ""
                  }`}
                  onClick={() => setCurrentPage(index + 1)}
                />
              )
            )}
          </div>
        )}
        <div className="product-page">
          {showPagination && (
            <div
              className={`page-left ${
                isPrevDisabled ? "page-disabled" : "page-control"
              }`}
              onClick={handlePrevPage}
            >
              <div className="prev-icon"></div>
            </div>
          )}
          <div className={`product-size-select  pc-size-select`}>
            {displayedOptions.map((item, index) => (
              <div
                key={item.specOptionId}
                className={`option ${
                  selectedOptionIndex === (currentPage - 1) * pageSize + index
                    ? "select"
                    : ""
                }`}
                onClick={() =>
                  clickDisabled
                    ? null
                    : handleOptionClick(index, item.specOptionName, false)
                }
              >
                <p>{item.specOptionName}</p>
              </div>
            ))}
          </div>
          <div className={`product-size-select mb-size-select `}>
            {option.map((item, index) => (
              <div
                key={item.specOptionId}
                className={`option ${
                  selectedOptionIndex === index ? "select" : ""
                }`}
                onClick={() =>
                  clickDisabled
                    ? null
                    : handleOptionClick(index, item.specOptionName, true)
                }
              >
                <p>{item.specOptionName}</p>
              </div>
            ))}
          </div>
          {showPagination && (
            <div
              className={`page-right ${
                isNextDisabled ? "page-disabled" : "page-control"
              }`}
              onClick={handleNextPage}
            >
              <div className="next-icon"></div>
            </div>
          )}
        </div>
      </div>

      {showPhonePage && !onePage && (
        <div className="phone-more ">
          <div className="more-btn" onClick={toggleMoreOption}>
            <h6>點開選擇更多尺寸</h6>
          </div>
        </div>
      )}

      {showPagination && !onePage && (
        <div className="pc-more">
          <div className="more-btn" onClick={toggleMoreOption}>
            <h6>點開選擇更多尺寸</h6>
          </div>
        </div>
      )}
      {tosetprice &&
        renderOption &&
        renderOption.skuProcessing !== null &&
        renderOption.skuProcessing !== undefined && <ProductProcessing />}
    </>
  );
}

export default ProductFirstSelect;
