import React, { useState } from 'react';
import DOMPurify from 'dompurify';

function ProductDescript({ productData }) {
  const [selectedTab, setSelectedTab] = useState('產品說明'); // 初始选中的标签为 '產品說明'

  // 根据选中的标签确定要显示的内容
  const getContentForTab = () => {
    if (selectedTab === '產品說明' && productData.proddesc) {
      return productData.proddesc;
    } else if (selectedTab === '適用範圍' && productData.prodinfo) {
      return productData.prodinfo;
    }
    return '';
  };

  const sanitizedHTML = DOMPurify.sanitize(getContentForTab());

  return (
    <div className="product-descript">
      <div className="title"><h5>商品資訊</h5></div>
      <div className="switch-container">
        <div className="descript-switch">
          {productData.proddesc !== '' && productData.proddesc !== null && (
            <div
              className={`switch ${
                selectedTab === '產品說明' ? 'select' : ''
              }`}
              onClick={() => setSelectedTab('產品說明')}
            >
              <h6>產品說明</h6>
            </div>
          )}
          {productData.prodinfo !== '' && productData.prodinfo !== null && (
            <div
              className={`switch ${
                selectedTab === '適用範圍' ? 'select' : ''
              }`}
              onClick={() => setSelectedTab('適用範圍')}
            >
              <h6>適用範圍</h6>
            </div>
          )}
        </div>
      </div>
      <div className="descript-content ">
        {sanitizedHTML && (
          <div dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />
        )}
      </div>
    </div>
  );
}

export default ProductDescript;
