import React, { useEffect, useState, useContext } from "react";
import AuthContext from "../../Context/AuthContext";
import axios from "axios";
import Loading from "../Loading";
import Nodata from "../../sharedComponents/Nodata";

function MemberPoint() {
  const { myAuth, logout } = useContext(AuthContext);
  const [pointData, setPointData] = useState(null);
  const [isloading, setIsLoading] = useState(true);
  const getpointDara = () => {
    const mid = parseInt(myAuth.sid);
    const storedToken = myAuth.token;
    const requestData = {
      mid: mid,
    };
    axios
      .post(`/api/Member/GetMemberPoint`, requestData, {
        headers: {
          Authorization: `Bearer ${storedToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("response Data:", "response.data");
          setPointData(response.data);
        } else {
          console.log("Request failed:", "response");
          // Token is invalid, log out the user
        }
      })
      .catch((error) => {
        console.error("Error:", "error");
        if (error.response && error.response.status === 401) {
          // 如果是 401，觸發 logout()
          logout();
        }
      })
      .finally(() => {
        setIsLoading(false); // Mark loading as finished regardless of success or error
      });
  };
  useEffect(() => {
    //初次進來取得紅利點數資料
    getpointDara();
  }, [myAuth]);

  if (isloading) {
    <Loading />;
  }
  return (
    <div className="member-point ">
      <div className="member-page-title">
        <h5>紅利點數</h5>
      </div>
      <div className="point-top-info">
        <div className="second-title">
          <h5>紅利點數</h5>
        </div>
        {pointData && (
          <div className="member-point-total">
            <div className="point-col">
              <h6>待生效</h6>
              <h6>紅利點數</h6>
              <h6 className="point-number">{pointData.unactivatedBonus}點</h6>
            </div>
            <div className="point-col">
              <h6>已生效</h6>
              <h6>紅利點數</h6>
              <h6 className="point-number">{pointData.realBonus}點</h6>
            </div>
            <div className="point-col">
              <h5>(每月最高折抵1000點，本月已折抵</h5>
              <h5 className="point-number">
                {1000 - pointData.monthLimitBonus}點
              </h5>
              <h5>)</h5>
            </div>
          </div>
        )}
      </div>
      <div className="table-container">
          
          {pointData && pointData.bonusData.length > 0 && (
            <div className="color-col title-col">
              <div className="col-content col-title">
                <h6>日期</h6>
              </div>
              <div className="col-content col-title">
                <h6>說明</h6>
              </div>
              <div className="col-content col-title">
                <h6>待生效</h6>
              </div>
              <div className="col-content col-title">
                <h6>已生效</h6>
              </div>
              <div className="col-content col-title">
                <h6>已使用</h6>
              </div>
              <div className="col-content col-title">
                <h6>生效日期</h6>
              </div>
            </div>
          )}
          {pointData && pointData.bonusData.length > 0 ? (
            <div className="member-point-detail ">
              <div className="member-table member-point">
                {pointData.bonusData.map((bonus) => (
                  <div className="color-col" key={bonus}>
                    <div className="col-content">
                      <div className="col-title">
                        <p>日期：</p>
                      </div>
                      <div className="col-descript">
                        <p>{new Date(bonus.createdAt).toLocaleDateString()}</p>
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p>說明：</p>
                      </div>
                      <div className="col-descript">
                        <p className="text-bold">{bonus.title}</p>
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p>待生效點數：</p>
                      </div>
                      <div className="col-descript">
                        <p>
                          {bonus.unactivatedBonus ? bonus.unactivatedBonus : "-"}
                        </p>{" "}
                        {/* 待生效點數若有值則填若沒有則補"-" */}
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p>已生效點數：</p>
                      </div>
                      <div className="col-descript">
                        <p>
                          {bonus.bonusIn - bonus.unactivatedBonus
                            ? bonus.bonusIn - bonus.unactivatedBonus
                            : "-"}
                        </p>
                        {/* 可使用-待生效 有值則填若沒有則補"-" */}
                      </div>
                    </div>
                    <div className="col-content">
                      <div className="col-title">
                        <p>已使用點數：</p>
                      </div>
                      <div className="col-descript">
                        <p>{bonus.bonusOut ? bonus.bonusOut : "-"}</p>
                        {/* 已使用點數若有值則填若沒有則補"-" */}
                      </div>
                    </div>
                    <div className="col-content align-items-center">
                      <div className="col-title">
                        <p>生效日期：</p>
                      </div>
                      <div className="col-descript">
                        <p>
                          {bonus.sdate
                            ? new Date(bonus.sdate).toLocaleDateString()
                            : ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
                
              </div>
            </div>
          ) : (
            <Nodata descript={"目前沒有紅利點數資訊"} />
          )}
      </div>
    </div>
  );
}

export default MemberPoint;
