//ProductSecondSelect
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedOptionSecond,
  setSelectedOptionForPrice,
  setSelectedOptionName,
} from "../../redux/actions/action";
import {
  selectOptionName,
  selectOptionSecondName,
  selectProcessingName,
} from "../../redux/selectors/selectors";
import ProductProcessing from "./ProductProcessing";
import Loading from "../Loading";
import axios from "axios";

function ProductSecondSelect({ specname, onePage }) {
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(0);
  const [firstStage, setFirstStage] = useState("");
  const [renderOption, setRenderOption] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [secondOptionPrice, setSecondOptionPrice]= useState(null)

    //快速點擊禁止
    const [clickDisabled, setClickDisabled] = useState(false);

  const selectProduct = (state) => state.product;

  const dispatch = useDispatch();

  const selectedOption = useSelector((state) => state.product.selectedOption);
  const selectedOptionSecond = useSelector(
    (state) => state.product.selectedOptionSecond
  );
  const optionName = useSelector(selectOptionName);
  // const optionSecondName = useSelector(selectOptionSecondName);
  const processingName = useSelector(selectProcessingName);

  
  const fetchData = async () => {
    let controller = null; 
  if (controller) {
    controller.abort(); 
  }

  controller = new AbortController();
  if(onePage){
    console.log("firstStage","firstStage")
    try {
      setIsLoading(true);
      const response = await axios.post(
        "api/Onepage/GetOnepageTwoOption",
        {
          optionid: parseInt(firstStage),
        },
        {
          headers: {
            "Content-Type": "application/json",
          }, signal: controller.signal
        });
        setRenderOption(response.data);
        console.log("api取得的renderOption", "response.data");
      
    } catch (error) {
      console.error("POST 請求錯誤:", "error");
      if(error.name === 'AbortError') {
        console.log('Request aborted'); 
      } else {
        console.log('Other error:', "error");
      }
    } finally {
      setIsLoading(false);
     
    }
  }else{
    try {
      setIsLoading(true);
      const response = await axios.post(
        "api/Product/ProductDetail/TwoOption",
        {
          optionid: parseInt(firstStage),
        },
        {
          headers: {
            "Content-Type": "application/json",
          }, signal: controller.signal
        });

      setRenderOption(response.data);
      console.log("api取得的renderOption", "response.data");
      
    } catch (error) {
      console.error("POST 請求錯誤:", "error");
      if(error.name === 'AbortError') {
        console.log('Request aborted'); 
      } else {
        console.log('Other error:', "error");
      }
    } finally {
      setIsLoading(false);
     
    }
  }
    

  };
  const fetchSecondData = async () => {
    let controller = null; 
    if (controller) {
      controller.abort(); 
    }
  
    controller = new AbortController();
    if(onePage){
      if(!firstStage){
        return
      }
      console.log("firstStage","firstStage")
      try {
        setIsLoading(true);
        const response = await axios.post(
          "api/Onepage/GetOnepageSkuPrice",
          {
            OSkuid: parseInt(firstStage),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        setSecondOptionPrice(response.data);
        console.log("api取得的GetOnepageSkuPrice", "response.data");
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
      } finally {
      
        setIsLoading(false);
       
      }
    }else{
      try {
        // setIsLoading(true);
        const response = await axios.post(
          "api/Product/ProductDetail/SkuPrice",
          {
            skuid: parseInt(selectedOptionSecond),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
  
        console.log("第二層取得的資料", "response.data");
        setSecondOptionPrice(response.data);
      } catch (error) {
        console.error("POST 請求錯誤:", "error");
        if(error.name === 'AbortError') {
          console.log('Request aborted'); 
        } else {
          console.log('Other error:', "error");
        }
      } finally {
        // setIsLoading(false);
      }
    }
    
  };

  useEffect(() => {
    if (selectedOption !== "") {
      console.log("第一層改變", "selectedOption");
      setFirstStage(selectedOption);
      setSelectedOptionIndex(0);
      setIsLoading(true)
    }
  }, [selectedOption, specname]);

  useEffect(() => {
    if (firstStage !== "" && firstStage !== null ) {

     console.log("firstStage","firstStage")
      const controller = new AbortController();
      fetchData();
      return () => {
        controller && controller.abort();
      }
    }
  }, [firstStage]);

  useEffect(() => {
    console.log("第二層", "selectedOptionSecond");
    if (selectedOptionSecond && firstStage !== null) {
      const controller = new AbortController();
      

      fetchSecondData();
      return () => {
        controller && controller.abort();
      }
    }
  }, [selectedOptionSecond]);
  useEffect(()=>{
    if (secondOptionPrice) {
      const { firstSkuData, skuProcessing, discountData } = secondOptionPrice;
      console.log("第二層要給價格的資料", "secondOptionPrice")
       dispatch(setSelectedOptionForPrice({ firstSkuData, skuProcessing, discountData }));
    }
  }, [secondOptionPrice])

  const handleOptionClick = (index, skuid, secondoptionname) => {
    // if (clickDisabled) return;

    // setClickDisabled(true);
    // setTimeout(() => {
    //   setClickDisabled(false);
    // }, 300);
    if (isLoading) {
      return; // 如果正在載入就不允許點擊
    }
    if(index===selectedOptionIndex){
      // 如果點擊一樣的就返回
      console.log("index")
      console.log(index)
      console.log(selectedOptionIndex)
      return;
    }

    setIsLoading(true);
    setSelectedOptionIndex(index);
    console.log("選中的第二層", "secondoptionname");
    dispatch(setSelectedOptionSecond(skuid)); // 修改此處
    dispatch(
      setSelectedOptionName(optionName, secondoptionname, processingName)
    );
    if (renderOption) {
      const { firstSkuData, skuProcessing } = renderOption;
      
      dispatch(setSelectedOptionForPrice({ firstSkuData, skuProcessing }));
      setIsLoading(false);
    }
  };

  //useEffect表示預設值
  useEffect(() => {
    if (
      firstStage !== "" &&
      !isLoading &&
      renderOption &&
      renderOption.skuSpecOption
    ) {
      console.log("設置renderOption", "renderOption");
      console.log("renderOption.skuSpecOption", "renderOption.skuSpecOption");
      let initialSelectedOptionIndex;
      let initialSelectedOptionName;
      if (renderOption.skuSpecOption[selectedOptionIndex]) {
        initialSelectedOptionIndex =
          renderOption.skuSpecOption[selectedOptionIndex].skuid;
        initialSelectedOptionName =
          renderOption.skuSpecOption[selectedOptionIndex].optionname;

        dispatch(setSelectedOptionSecond(initialSelectedOptionIndex));
        const { firstSkuData, skuProcessing } = renderOption;
        dispatch(setSelectedOptionForPrice({ firstSkuData, skuProcessing })); // 修改此處
        dispatch(
          setSelectedOptionName(
            optionName,
            initialSelectedOptionName,
            processingName
          )
        );
      }
      console.log(
        "沒問題renderOption.skuSpecOption",
        "initialSelectedOptionIndex"
      );
    }
  }, [
    dispatch,
    selectedOptionIndex,
    firstStage,
    isLoading,
    renderOption,
    specname,
  ]);


  if (isLoading) {
    return  <Loading height={"h-auto justify-content-center"}/>
  }
  return (
    <>
      <div className="select-result">
        <div className="d-flex text-nowrap">
          <h6>選擇</h6>
          <h6>{specname}： </h6>
        </div>
        {renderOption && renderOption.skuSpecOption && (
          <p className="search">{renderOption.skuSpecOption[selectedOptionIndex].optionname}</p>
        )}
      </div>
      <div className="product-color-select ">
        {renderOption && renderOption.skuSpecOption &&
          renderOption.skuSpecOption.map((item, index) => (
            <div
              key={item.skuid}
              className={`option ${
                selectedOptionIndex === index ? "select" : ""
              }`}
              onClick={() =>
                clickDisabled
                ? null
                : handleOptionClick(index, item.specOptionName)
              }
            >
              {/* 修改此處 */}
              <p>{item.optionname}</p>
            </div>
          ))}
      </div>
      {renderOption &&
        renderOption.skuProcessing !== null &&
        renderOption.skuProcessing !== undefined && <ProductProcessing />}
    </>
  );
}

export default ProductSecondSelect;
