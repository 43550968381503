import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function useSetPath() {
    const location = useLocation();
    const currentPath = location.pathname;

    useEffect(() => {
        sessionStorage.setItem("prevPath", currentPath);
    }, [currentPath]);
}

export default useSetPath;