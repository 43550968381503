//member.js
import React, { useState, useContext, useEffect } from "react";
import { Link, Routes, Route, useLocation, Navigate } from "react-router-dom";
import Breadcrumbs from "./Breadcrumbs";
import AuthContext from "../Context/AuthContext";
import MemberOrder from "./member/MemberOrder"; //訂單資料
import MemberCollection from "./member/MemberCollection"; //我的收藏
import MemberCoupon from "./member/MemberCoupon"; //折價券
import MemberPoint from "./member/MemberPoint"; //紅利點數
import MemberData from "./member/MemberData"; //會員資料
import MemberOld from "./member/MemberOld"; //登入舊會員
import MemberArt from "./member/MemberArt"; //會員作品

import Loading from "./Loading";
function Member({ state }) {
  // const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const activeTab = state.split("/").pop();
  const { myAuth, logout } = useContext(AuthContext);

  useEffect(() => {
    if (myAuth.authorized && myAuth.connectOld !== null) {
      setIsLoading(false);
    }
  }, [myAuth.authorized]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="page-background member-background">
      <div className="share-page member-page">
        <Breadcrumbs />
        <div className="member-filter-container">
          <div className="title-box">
            <h3 className="title">會員專區</h3>
            <div
             className="back-btn logout-btn" onClick={logout}>
              <h6>登出</h6>
            </div>
          </div>

          <div className="filter-group">
            <div className="filter-title">
              <h5>購物相關</h5>
            </div>
            <Link
              to="/member/order"
              className={`btn filter-btn ${
                activeTab === "order" ? "filter-btn-select" : ""
              }`}
            >
              <p>訂單資料</p>
            </Link>
            <Link
              to="/member/collection"
              className={`btn filter-btn  ${
                activeTab === "collection" ? "filter-btn-select" : ""
              }`}
            >
              <p>我的收藏</p>
            </Link>
            <Link
              to="/member/coupon"
              className={`btn filter-btn  ${
                activeTab === "coupon" ? "filter-btn-select" : ""
              }`}
            >
              <p>折價券</p>
            </Link>
            <Link
              to="/member/point"
              className={`btn filter-btn  ${
                activeTab === "point" ? "filter-btn-select" : ""
              }`}
            >
              <p>紅利點數</p>
            </Link>
            <div className="filter-title">
              <h5>會員帳戶</h5>
            </div>
            <Link
              to="/member/data"
              className={`btn filter-btn  ${
                activeTab === "data" ? "filter-btn-select" : ""
              }`}
            >
              <p>會員資料</p>
            </Link>
            <Link
              to="/member/art"
              className={`btn filter-btn  ${
                activeTab === "art" ? "filter-btn-select" : ""
              }`}
            >
              <p>我的作品</p>
            </Link>
            {/* {myAuth.connectOld === false && (
              <Link
                to="/member/oldmember"
                className={`btn filter-btn  ${
                  activeTab === "oldmember" ? "filter-btn-select" : ""
                }`}
              >
                <p>登入舊會員</p>
              </Link>
            )} */}

            
          </div>
        </div>

        <div className="member-content">
          <div className="member-detail-page">
            
            <Routes>
              <Route path="/order" element={<MemberOrder />} />
              <Route path="/collection" element={<MemberCollection />} />
              <Route path="/coupon" element={<MemberCoupon />} />
              <Route path="/point" element={<MemberPoint />} />
              <Route path="/data" element={<MemberData />} />
              <Route path="/art" element={<MemberArt />} />
              {myAuth.connectOld === false && (
                <Route path="/oldmember" element={<MemberOld />} />
              )}
              {/* Redirect root path to /order */}
              <Route path="/" element={<Navigate to="/member/order" />} />
              <Route path="/*" element={<Navigate to="/member/order" />} />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Member;
