import React from 'react';
import { useLocation } from 'react-router-dom';

function TryState() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const stateParam = searchParams.get('state');
  
  let passedState = "No state passed";
  if (stateParam) {
    try {
      passedState = JSON.parse(decodeURIComponent(stateParam));
    } catch (error) {
      console.error("Error parsing state:", error);
    }
  }

  return (
    <div className="container">
      <h1>TryState Component</h1>
      <p>Received State: {JSON.stringify(passedState)}</p>
    </div>
  );
}

export default TryState;